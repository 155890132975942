import { BaseService } from "./BaseService";

export class SetService extends BaseService {
  constructor() {
    super('Sets')
  }

  async getByCategory(id) {
    return this.httpService.get(`GetByCategoryId/${id}`);
  }

  async getById(id) {
    return this.httpService.get(`${id}`);
  }

  async getBySlug(id, params) {
    return this.httpService.get(`GetBySlug/${id}?${params}`);
  }

  getFrontImage = (set) => {
    return `${this.httpService.baseUrl}${set?.frontImage}`;
  }

  async getSubscriptionPlans(id) {
    return this.httpService.get(`GetSubscriptionPlans/${id}`);
  }

  async getRandomBooks(count) {
    // return this.httpService.get(`GetRandomBooks/${count}`);
    return this.httpService.get(``);
  }

  async getRelatedBooks(slug, count) {
    return this.httpService.get(`GetRelatedBooks/${slug}/${count}`);
  }

  async getSetBooks(slug) {
    return this.httpService.get(`GetSetBooks/${slug}`);
  }
}