import SubscriptionOption from './SubscriptionOption';
import BookBuyOption from './BookBuyOption';

function BookSubscriptionPlans({ subscriptionPlans, book, id, showBookBuyOption }) {
  return (
    <div className="widget">
      <div className='shipping-addresses'>
        {subscriptionPlans.map((plan, i) => {
          const data = { ...plan, frontImage: book?.frontImage }
          return (<SubscriptionOption key={i} plan={data} />)
        })}
        {(showBookBuyOption) && <BookBuyOption book={book} id={id} type={1} />}
      </div>
    </div>
  );
}

export default BookSubscriptionPlans;