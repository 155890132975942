import React from 'react';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import placeholder from '../../assets/images/profile-placeholder.png';
import { UserContext } from '../../context/UserContext';

function TopNavUser() {
  const { auth, setAuth } = React.useContext(UserContext)

  return (
    <Dropdown as="li" className="nav-item dropdown profile-dropdown  ms-4">
      <Dropdown.Toggle as="div" className="nav-link i-false">
        <img src={placeholder} alt="/" />
        <div className="profile-info">
          <h6 className="title"></h6>
          <span></span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu py-0 dropdown-menu-end">
        <div className="dropdown-header">
          <h6 className="m-0">{auth?.user?.fullName}</h6>
          <span>{auth?.user?.email}</span>
        </div>
        <div className="dropdown-body">
          <Link to={"dashboard"} className="dropdown-item d-flex justify-content-between align-items-center ai-icon">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" /></svg>
              <span className="ms-2">Profile</span>
            </div>
          </Link>
          <Link to={"subscriptions"} className="dropdown-item d-flex justify-content-between align-items-center ai-icon">
            <div>
            <i className ="fa fa-book"></i>
              <span className="ms-2">My Subscription</span>
            </div>
          </Link>
        </div>
        <div className="dropdown-footer">
          <Link to={"/login"} className="btn btn-primary w-100 btnhover btn-sm">Log Out</Link>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default TopNavUser;