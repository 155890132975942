import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from '../context/LocationContext';
import { CategoryService } from '../services/CategoryService';
import { SetService } from '../services/SetService';
import { formatCurrency } from '../utilities/formatCurrency';
import SetDetailButton from './books/SetDetailsButton';

const setService = new SetService();
const categoryService = new CategoryService();

function SetGridView(props) {
  const [category, setCategory] = useState();
  const [sets, setSets] = useState([]);
  const { getUrlParams } = useLocation()

  const getSets = useCallback(async () => {
    var response = await setService.getByCategory(props.id);
    setSets(response.data);
  }, [props.id]);

  const getCategory = useCallback(async () => {
    var response = await categoryService.getCategoryBySlug(props.id, getUrlParams());
    setCategory(response.data);
  }, [getUrlParams, props.id]);

  useEffect(() => {
    getSets();
    getCategory();
  }, [getSets, getCategory, props.id]);

  return (
    <div className="page-content bg-grey">
      <section className="content-inner-1 border-bottom padding-top-20">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="title">{category?.name}</h4>
          </div>
          {sets.length === 0
            ? <></>
            : <div className="row book-grid-row">
              {sets.map((set, i) => (
                <div className="col-book style-1" key={i}>
                  <div className="dz-shop-card style-1">
                    <div className="dz-media">
                      <Link to={`/set/${set.slug}`}>
                        <img src={`${setService.httpService.baseUrl}${set.frontImage}`} alt={set.name} />
                      </Link>
                    </div>
                    <div className="bookmark-btn style-2">
                     
                    </div>
                    <div className="dz-content">
                      <h5 className="title">
                        <Link to={`/set/${set.slug}`}>
                          {set.name}
                        </Link>
                      </h5>
                      <ul className="dz-tags">
                        <li><Link to={"#"}>{set?.code}</Link></li>
                      </ul>
                      <h6 className="title">
                        <span className="price-num">{formatCurrency(set.price * 1.15)}</span>
                      </h6>
                      <SetDetailButton set={set} />
                    </div>
                  </div>
                </div>
              ))}
            </div>}
        </div>
      </section>
    </div>
  )
}
export default SetGridView;