function SingleInput({ title, ChangeClass }) {
  return (
    <>
      <div className={`form-group ${ChangeClass}`}>
        <input type="text" className="form-control" placeholder={title} />
      </div>
    </>
  )
}

export default SingleInput;