import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";
import { BookService } from '../../services/BookService';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import SliderBook from '../../pages/books/SliderBook';

const bookService = new BookService();

export default function RecomendedSlider() {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    getBooks();
  }, []);

  const getBooks = async () => {
    var response = await bookService.getRandomBooks(8);
    setBooks(response.data);
  };

  return (
    <>
      <Swiper className="swiper-container swiper-two"
        speed={1500}
        //parallax= {true}
        slidesPerView={5}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2500,
        }}
        modules={[Autoplay]}
        breakpoints={{
          1200: {
            slidesPerView: 5,
          },
          1024: {
            slidesPerView: 4,
          },
          991: {
            slidesPerView: 3,
          },
          767: {
            slidesPerView: 3,
            centeredSlides: true,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 15,
            centeredSlides: true,
          },
        }}
      >
        {books.map((book, i) => (
          <SwiperSlide key={i}>
            <SliderBook book={book} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}