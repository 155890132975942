import { useRef, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import PageTitle from '../../layouts/PageTitle';
import { AuthService } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from "sweetalert2";
import { useLocation } from '../../context/LocationContext';
import Loading from 'react-fullscreen-loading';
import Constants from "../../Constants/Contants";

const EMAIL_REGEX = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const auth = new AuthService()

function Registration() {
  const emailRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const { getUrlParams } = useLocation()
  const [isCaptchaTicked, setIsCaptchaTicked] = useState(null);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userType, setUserType] = useState(1);
  const [schoolName, setSchoolName] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  useEffect(() => {
    emailRef.current.focus();
  }, [])

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email])

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
  }, [pwd])

  useEffect(() => {
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd])

  const handleCaptchaChange = (value) => {
    setIsCaptchaTicked(value !== null);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setShowLoader(true);
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      
      const response = await auth.register(email, firstName, lastName, phoneNumber, token, pwd, +userType, schoolName,promoCode, getUrlParams())

      if (response){
        setShowLoader(false);
         navigate(`/verify-email/${email}`)}
    } catch (error) {
      setShowLoader(false);
     const errorMessage ="Email address already registered. Please log in instead, or reset your password if you have lost it";
      Swal.fire({
        title: 'Error Registering',
        text: '' + errorMessage,
        icon: 'error',
      });
      setErrMsg(errorMessage);
    }
  }

  return (
    <>
      <div className="page-content">
      {showLoader? ( <Loading loading  className="loader" loaderColor="#3498db" />):(<></>)}           
          
        <PageTitle parentPage="Shop" childPage="Registration" />
        <section className="content-inner shop-account">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="login-area">
                  <form onSubmit={handleSubmit}>
                    <h4 className="text-secondary">Registration</h4>
                    <p className="font-weight-600">If you don't have an account with us, please register here.</p>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <div className="mb-4">
                      <label className="label-title">Email address *</label>
                      <input className="form-control" placeholder="Your Email address" type="email"
                        id="email" autoComplete="off" onChange={(e) => setEmail(e.target.value)}
                        ref={emailRef} value={email} required onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)} />
                      <p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                        4 to 24 characters.<br />
                        Must begin with a letter.<br />
                        Letters, numbers, underscores, hyphens allowed.
                      </p>
                    </div>
                    <div className="mb-4">
                      <label className="label-title">First Name *</label>
                      <input className="form-control" placeholder="Your First Name" type="text"
                        id="firstName" onChange={(e) => setFirstName(e.target.value)} value={firstName}
                        required />
                    </div>
                    <div className="mb-4">
                      <label className="label-title">Surname *</label>
                      <input className="form-control" placeholder="Your Surname" type="text"
                        id="lastName" onChange={(e) => setLastName(e.target.value)} value={lastName}
                        required />
                    </div>
                    <div className="mb-4">
                      <label className="label-title">Phone Number *</label>
                      <input className="form-control" placeholder="Phone Number" type="number"
                        id="phoneNumber" onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber}
                        required />
                    </div>
                    <div className="mb-4">
                      <label className="label-title">Promo Code</label>
                      <input className="form-control" type="text" name="promoCode" 
                        id="promoCode"  placeholder="Promo Code"  onChange={(e) => setPromoCode(e.target.value)} value={promoCode}
                         />
                    </div>
                    <div className="mb-4">
                      <label className="label-title">Account Type *</label>
                      <select className="form-control" onChange={(e) => setUserType(e.target.value)} value={userType}>
                        <option value="1">Individual (your personal account)</option>
                        <option value="2">School (an educational institution's account)</option>
                      </select>
                    </div>
                    {(userType === '2') && 
                      <div className="mb-4">
                        <label className="label-title">Name of the School *</label>
                        <input className="form-control" placeholder="Name of the School" type="text"
                          id="schoolName" onChange={(e) => setSchoolName(e.target.value)} value={schoolName}/>
                      </div>
                    }

                    <div className="mb-4">
                      <label className="label-title">Password *</label>
                      <input className="form-control " placeholder="Type Password" type="password"
                        id="password" onChange={(e) => setPwd(e.target.value)} value={pwd}
                        required onFocus={() => setPwdFocus(true)} onBlur={() => setPwdFocus(false)} />
                      <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                        8 to 24 characters.<br />
                        Must include uppercase and lowercase letters, a number and a special character.<br />
                        Allowed special characters:
                        <span aria-label="exclamation mark">!</span>
                        <span aria-label="at symbol">@</span>
                        <span aria-label="hashtag">#</span>
                        <span aria-label="dollar sign">$</span>
                        <span aria-label="percent">%</span>
                      </p>
                    </div>

                    <div className="mb-4">
                      <label className="label-title">Confirm Password *</label>
                      <input className="form-control " placeholder="Type Password" type="password"
                        id="confirm_pwd" onChange={(e) => setMatchPwd(e.target.value)}
                        value={matchPwd} required onFocus={() => setMatchFocus(true)}
                        onBlur={() => setMatchFocus(false)} />
                      <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                        Must match the first password input field.
                      </p>
                    </div>

                    <div className="mb-5">
                      <small>Your personal data will be used to support your experience throughout this website,
                        to manage access to your account, and for other purposes described in our{' '}
                        <Link to={"privacy-policy"}>privacy policy</Link>.
                      </small>

                      <ReCAPTCHA
                        sitekey={Constants.ReCAPTCHA_SiteKey}
                        onChange={handleCaptchaChange}
                        ref={captchaRef}
                      />
                    </div>

                    <div className="text-left">
                      <button type="submit" className="btn btn-primary btnhover w-100 me-2" disabled={!isCaptchaTicked}>
                        Register
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default Registration;