import HttpService from "./HttpService";

export class BaseService {
    httpService: HttpService;

    constructor(url: string) {
        this.httpService = new HttpService(url);
    }

    async create(body: any) {
        return await this.httpService.post(``, body);
    }

    async delete(id: string) {
        return await this.httpService.delete(`${id}`);
    }

    async edit(body: string) {
        return await this.httpService.patch(``, body);
    }

    async get(id: string) {
        return await this.httpService.get(`${id}`);
    }

    async getAll() {
        return await this.httpService.get(``);
    }

    async update(body: string) {
        return await this.httpService.put(``, body);
    }
}