function BackToMainPage() {
  return ( 
    <div className="dz-header">
      <a href='/'>
        <p className="title">Back to Main Page</p>
      </a>
    </div>
  );
}

export default BackToMainPage;