import { Link } from "react-router-dom";

function BookDetailButton({ book }) {
  return (
    <Link to={`/book/${book.slug}`} className="btn btn-secondary btnhover btnhover2">
      <i className ="fa fa-book m-r10"></i>
      Product Details
    </Link>
  );
}

export default BookDetailButton;