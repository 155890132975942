import React, { useEffect, useState, useCallback } from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import useIsMounted from '../../hooks/useIsMounted';
import { DashboardService } from '../../services/DashboardService';

const dashboardService = new DashboardService();

const DashboardTiles = ({user}) => {
  const isMounted = useIsMounted();
  const [tiles, setTiles] = useState([]);

  const getDashboardTiles = useCallback(async () => {
    const response = await dashboardService.getUserDashboardTiles(user.id)
    isMounted && setTiles(response.data)
  }, [isMounted, user.id])

  useEffect(() => {
    getDashboardTiles();
  }, [getDashboardTiles])

  return (
    <>
      {tiles.map((data, i) => (
        <div className="col-lg-3 col-md-6 col-sm-6 col-6" key={i} >
          <Link to={data.path ?? '/dashboard'}>
            <div className="icon-bx-wraper style-2 m-b30 text-center">
              <div className="icon-bx-lg">
                <i className ={`fa-solid icon-cell ${data.iconClass}`}></i>
              </div>
              <div className="icon-content">
                <h2 className="dz-title counter m-b0"> <CountUp end={data.number} separator="," /></h2>
                <p className="font-20">{data.title}</p>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </>
  )
}
export default DashboardTiles;