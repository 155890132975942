import { formatCurrency } from "../../utilities/formatCurrency";
import CartItemQuantity from "../cart/CartItemQuantity";

function SubscriptionOption({ plan }) {
  return ( 
    <div className="subscription-plan">
      <label htmlFor='control_01'>
        <div className='row'>
          <div className="col-md-6">
            <div className="buy-info">
              <h5>{formatCurrency(plan?.price * 1.15)}</h5>
              <p><u>Subscribe</u> to this {plan?.setId>0?(<span>set</span>):(<span>book</span>)} for <em>{plan.duration} months</em> for <em>{plan.maximumUsers>1?(<span>multiple users</span>): (<span>single user</span>)} </em>.</p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className="buy-option">
              <CartItemQuantity id={plan.id} type={3} book={plan} />
            </div>
          </div>
        </div>
      </label>
    </div>
  );
}

export default SubscriptionOption;