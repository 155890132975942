import { createContext, useState } from "react";

const WishlistContext = createContext({});

export const WishlistProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState({});

  return (
    <WishlistContext.Provider value={{ wishlist, setWishlist }}>
      {children}
    </WishlistContext.Provider>
  )
}

export default WishlistContext;