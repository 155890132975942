import { useEffect, useState } from 'react';
import FormattedDate from '../../../components/FormattedDate';
import { BookService } from '../../../services/BookService';

const bookService = new BookService();

const SubscriptionDetails = ({ subscription, baseUrl, addedUsers }) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (subscription.subscriptionPlan.set) {
      getBookImage(subscription.subscriptionPlan.set)
    } else {
      getBookImage(subscription.subscriptionPlan.book)
    }
  }, [subscription])

  const getBookImage = async(resource) => {
    var bookImage = bookService.getFrontImage(resource);
    setImageUrl(bookImage);
  }

  const getTypeDescription = (type) => {
    switch (type) {
      case 1:
        return 'Individual'
      case 2:
        return 'Group'
      case 3:
        return 'Free Subscription'
      case 4:
        return 'Direct Invite'
      default:
        return 'Unknown'
    }
  };

  return (
    <>
      <div className="dz-blog style-1 bg-white m-b30 blog-half">
        <div className="dz-media dz-img-effect zoom">
          
        </div>
        <div className="dz-info">
          <h4 className="dz-title">
            <i className ="far fa-user fa-fw m-r10"></i>
            By {subscription.owner.firstName} {subscription.owner.lastName}
          </h4>
          <p className="m-b0">{subscription.ownerAddress}</p>
          <p className="m-b0">{subscription.ownerPhone}</p>
          <div className="dz-meta meta-bottom">
            <ul className="border-0 pt-0">
              <li className="post-author">
                <i className ="far fa-book fa-fw m-r10"></i>
                {getTypeDescription(subscription.type)}
              </li>
              <li className="post-date">
                <i className ="far fa-calendar fa-fw m-r10"></i>
                {(subscription.balance > 0) 
                  ? <>No yet started</> 
                  : <><FormattedDate date={subscription.startDate} /> - <FormattedDate date={subscription.endDate} /></>}
              </li>
              {subscription.subscription?.package && (
                <li className="post-comment">
                  <i className ="far fa-comment-alt fa-fw"></i>
                  <span>{addedUsers.length}/{subscription.subscriptionPlan.maximumUsers}</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubscriptionDetails;