import React,{ useRef } from 'react';
import emailjs from '@emailjs/browser';

import bg1 from './../assets/images/background/Focus-Areas.jpg';

function NewsLetter({subscribeChange}){
    const form = useRef();
	const sendEmail = (e) => {
		e.preventDefault();
		//emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
		emailjs.sendForm('gmail', 'YOUR_TEMPLATE_ID', e.target, 'd9b2e0f5fc72cb94792110e8ff2028f3-us16')
		  .then((result) => {
		  }, (error) => {
		  });
		  e.target.reset()
	};	
    return(
        <>
            <section className={`py-5 newsletter-wrapper ${subscribeChange}`} style={{backgroundImage: 'url('+ bg1 +')', backgroundSize: 'cover' }}>
                <div className="container">
                    <div className="subscride-inner">
                        <div className="row style-1 justify-content-xl-between justify-content-lg-center align-items-center text-xl-start text-center">
                            <div className="col-xl-12 col-lg-12 ">
                                <div className="section-head mb-0">
                                    <h2 className="title text-white my-lg-3 mt-0">
                                        THRASS has really opened up my eyes and given me a sense of purpose and direction in my teaching – and has inspired 
                                        and motivated me to take it all into the classroom.
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default NewsLetter;