import { BaseService } from "./BaseService";

export class AddressService extends BaseService {
  constructor() {
    super('DeliveryAddresses')
  }
  
  async getUserAddresses(userId) {
    return await this.get(`GetUserDeliveryAddresses/${userId}`);
  }
}