import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

function Search({categories}) {
  const [selectBtn, setSelectBtn] = useState('Any Category');
  const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      navigate(`/search/${keyword}/20/0`)
    } catch (error) {
    }
  }

  return (
    <div className="header-search-nav">
      <form className="header-item-search" onSubmit={handleSubmit}>
        <div className="input-group search-input">
          {/* {
            categories ? (
              <Dropdown className="dropdown bootstrap-select default-select drop-head">
                <Dropdown.Toggle as="div" className="i-false">
                  {selectBtn}
                  <i className ="ms-4 font-10 fa-solid fa-chevron-down"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {categories?.map((category) => {
                    <Dropdown.Item onClick={() => setSelectBtn(category.description)} key={category.categoryId}>
                      category.description
                    </Dropdown.Item>
                  })}
                </Dropdown.Menu>
              </Dropdown>)
              : null
          } */}
          <input type="text" className="form-control" aria-label="Text input with dropdown button" placeholder="Search Books Here" 
            onChange={(e) => setKeyword(e.target.value)}/>
            
          <button className="btn" type="button" onClick={handleSubmit}><i className ="flaticon-loupe"></i></button>
        </div>
      </form>
    </div>

  );
}

export default Search;