import { useRef, useState, useEffect } from 'react';
import Swal from "sweetalert2";
import PageTitle from '../../layouts/PageTitle';
import NewsLetter from '../../components/NewsLetter';
import bg2 from '../../assets/images/background/bg2.jpg';
import ReCAPTCHA from 'react-google-recaptcha';
import { ContactFormService } from '../../services/ContactFormService';
import { useLocation } from '../../context/LocationContext';
import Constants from '../../Constants/Contants';

const EMAIL_REGEX = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
const formService = new ContactFormService();

const ContactUs = () => {
  const form = useRef();
  const captchaRef = useRef(null);
  const { getUrlParams } = useLocation()
  const [email, setEmail] = useState('')
  const [fullName, setFullName] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [validEmail, setValidEmail] = useState(false);
  const [isCaptchaTicked, setIsCaptchaTicked] = useState(null);
  const [location, setLocation] = useState('')
  const [organisation, setOrganisation] = useState('')
  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email])

  const handleCaptchaChange = (value) => {
    setIsCaptchaTicked(value !== null);
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      captchaRef.current.getValue();
      captchaRef.current.reset();

      const response = await formService.sendContactFormEmail(fullName, email, phone,location, organisation,message, getUrlParams());

      if (response) {
        Swal.fire({
          title: 'Contact Us',
          text: 'Thank you for contacting us. We will get back to you soon 😊',
          icon: 'success',
        });

        setFullName('')
        setPhone('')
        setEmail('')
        setMessage('')
        setLocation('')
        setOrganisation('')
        
        return;
      }
    } catch (error) {
    }

    Swal.fire({
      title: 'Error',
      text: 'Email was not sent successfully',
      icon: 'error',
    });
  }

  return (
    <>
      <div className="page-content">
        <PageTitle parentPage="Home" childPage="Contact Us" />
        <div className="content-inner-2 pt-0">
          <div className="map-iframe">
            <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=6%20Pencil%20Park,%20Croxley%20Close,%20Heriotdale%20X15,%20Johannesburg,%20South%20Africa+(THRASS%20Africa)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" style={{ border: '0', width: '100%', minHeight: '100%', marginBottom: '-8px' }} allowFullScreen title='Map'></iframe>
          </div>
        </div>
        <section className="contact-wraper1" style={{ backgroundImage: 'url(' + bg2 + ')' }}>
          <div className="container" >
            <div className="row">
              <div className="col-lg-5">
                <div className="contact-info">
                  <div className="section-head text-white style-1">
                    <h3 className="title text-white">Get In Touch</h3>
                    <p class="text-white">For more information or any queries, please contact:</p>
                  </div>
                  <ul className="no-margin">
                    <li className="icon-bx-wraper text-white left m-b30">
                      <div className="icon-md">
                        <span className="icon-cell text-white">
                          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                        </span>
                      </div>
                      <div className="icon-content">
                        <h5 className=" dz-tilte text-white">Our Address</h5>
                        <p class="text-white">6 Pencil Park, Croxley Close, Heriotdale X15, Johannesburg, South Africa</p>
                      </div>
                    </li>
                    <li className="icon-bx-wraper text-white left m-b30">
                      <div className="icon-md">
                        <span className="icon-cell text-white">
                          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                        </span>
                      </div>
                      <div className="icon-content">
                        <h5 className="dz-tilte text-white">Our Email</h5>
                        <p class="text-white">info@thrass-sa.co.za</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-7 m-b40">
                <div className="contact-area1 m-r20 m-md-r0">
                  <div className="section-head style-1">
                    <h6 className="sub-title text-primary">CONTACT US</h6>
                    <h3 className="title m-b20">Get In Touch With Us</h3>
                  </div>
                  <form className="dz-form dzForm" ref={form} onSubmit={sendEmail}>
                    <input type="hidden" className="form-control" name="dzToDo" defaultValue="Contact" />
                    <div className="dzFormMsg"></div>
                    <div className="input-group">
                      <input required type="text" className="form-control" name="dzName" placeholder="Full Name" 
                        value={fullName} onChange={(e) => setFullName(e.target.value)}/>
                    </div>
                    <div className="input-group">
                      <input required type="email" className="form-control" name="dzEmail" placeholder="Email Address" 
                        value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="input-group">
                      <input required type="number" className="form-control" name="dzPhoneNumber" placeholder="Phone No." 
                        value={phone} onChange={(e) => setPhone(e.target.value)}/>
                    </div>
                    <div className="input-group">
                    <input required type="text"  name="organisation" rows="5" className="form-control" placeholder="Organisation"
                        value={organisation} onChange={(e) => setOrganisation(e.target.value)}/>
                    </div>
                    <div className="input-group">
                    <input required type="text"  name="location" rows="5" className="form-control" placeholder="Location"
                        value={location} onChange={(e) => setLocation(e.target.value)}/>
                    </div>
                    <div className="input-group">
                      <textarea required name="dzMessage" rows="5" className="form-control" placeholder="Message"
                        value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                    </div>
                    <div className='mb-3'>
                      <ReCAPTCHA
                        sitekey={Constants.ReCAPTCHA_SiteKey}
                        onChange={handleCaptchaChange}
                        ref={captchaRef}
                      />
                    </div>
                    <div>
                      <button name="submit" type="submit" value="submit" className="btn w-100 btn-primary btnhover"
                        disabled={!validEmail || !isCaptchaTicked}>SUBMIT</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner">
          <div className="container">
            <div className="row sp15 text-center">
              <h4 className="title">GIVE US A CALL</h4>
              <h2 className="title">T: +27 (0)81 870 8369 </h2>
            </div>
          </div>
        </section>
        <NewsLetter />
      </div>
    </>
  )
}
export default ContactUs;