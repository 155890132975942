import PageTitle from '../../layouts/PageTitle';
import NewsLetter from '../../components/NewsLetter';
import thrassimage26 from '../../assets/images/thrassimage26.jpg'
import thrassimage27 from '../../assets/images/thrassimage27.jpg'
import thrassimage28 from '../../assets/images/thrassimage28.jpg'
import thrassimage29 from '../../assets/images/thrassimage29.jpg'

function LearnersPhonicsWorkbooks() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="My Word Power Phonics Books" />
        <section className="content-inner overlay-white-middle">
          <div className="container">
            <div className="row about-style1 align-items-center">
              <div className="col-lg-12 m-b30 aos-item">
                <div className="about-content px-lg-4">
                  <h6 className="title">
                    My Word Power Phonics Books
                  </h6>

                  <p>
                    My Word Power Phonics Books (homework books) are available for the different grades for English as Home
                    Language.
                  </p>

                  <img src={thrassimage26} alt="Thrass Chart" className="center" />
                  <img src={thrassimage27} alt="Thrass Chart" className="center" />
                  <img src={thrassimage28} alt="Thrass Chart" className="center" />

                  <p>
                    The My Word Power Phonics Books have the same colour cover as the Teacher's Guides and the Learner’s Phonics
                    Workbook (classroom workbook) for ease-of-reference, but have a different Sing-A-Long picture on the cover so
                    that the children can easily differentiate between the two workbooks.
                  </p>

                  <img src={thrassimage29} alt="Thrass Chart" className="center" />

                  <p> My Word Power Phonics Books include: </p>
                  <ul className="list-check primary">
                    <li> Simple fun homework activities for practice and consolidation that are linked to the weekly plans </li>
                    <li> Spelling lists with sight words (Whizz Words) </li>
                    <li> A coloured English Phonics Chart</li>
                    <li> A Yellow THRASS® Chart </li>
                    <li> Teacher’s Resource Pack with photocopiable activities, games and worksheets </li>
                    <li> A Grapheme Chart and Keyword Chart (not for Grade 1) </li>
                  </ul>

                  <p>
                    <a href="https://thrassafrica.co.za/index.php/home-language/my-word-power-phonics-books">
                      Shop My Word Power Phonics Books
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section >
        <NewsLetter />
      </div >
    </>
  )
}
export default LearnersPhonicsWorkbooks;