import React from 'react';
import {Link} from 'react-router-dom';
//Components 
import PageTitle from './../layouts/PageTitle';
import NewsLetter from '../components/NewsLetter';

const missionBlog = [
    {iconClass:'flaticon-open-book-1', title:'Best Bookstore' },
    {iconClass:'flaticon-exclusive', title:'Trusted Seller' },
    {iconClass:'flaticon-store', title:'Expand Store' },
];

function HowToOrder(){
    return(
        <>
            <div className="page-content bg-white">
               <PageTitle  parentPage="Home" childPage="THRASS Resources" />
               <section className="content-inner overlay-white-middle">
                    <div className="container">
                        <div className="row about-style1 align-items-center">
                            <div className="col-lg-12 m-b30 aos-item">
                                <div className="about-content px-lg-4">
                                    <div className="section-head style-1">
                                        <h6 className="title">
                                            Ordering is easy! Simply browse through the online catalogue, add the required items and 
                                            quantities to your cart, proceed to checkout and make a credit card payment using our secure payment gateway.
                                        </h6>
                                    </div>
                                    <p>
                                        The prices displayed in our online shop include VAT at the ruling rate. At checkout, the VAT portion will be 
                                        deducted from orders placed from Swaziland, Lesotho, Botswana and Namibia.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <NewsLetter />             
            </div>
        </>
    )
}
export default HowToOrder;