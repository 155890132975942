import { BaseService } from "./BaseService";

export class DirectInviteService extends BaseService {
    constructor() {
        super('DirectInvites')
    }

    async accept(id) {
        return this.httpService.get(`${id}/Accept`);
    }

    async getBooks(uid) {
        return this.httpService.get(`GetBooks/${uid}`);
    }
   
}