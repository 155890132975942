function ShippingAddress({setAddressOption, address}) {
  return (
    <div onClick={() => setAddressOption(address.id)}>
      <input type="radio" id={'address-' + address.id} name="select" value={address.id} />
      <label htmlFor={'address-' + address.id}>
        <h5>{address.firstName} {address.lastName}</h5>
        <p>{address.streetAddress}, {address.city}, {address.province}, {address.postalCode}, {address.country?.name}</p>
      </label>
    </div>
  );
}

export default ShippingAddress;