import { useState, useContext, useCallback, useEffect } from 'react';
import { Collapse, Form } from 'react-bootstrap';
import PageTitle from '../../layouts/PageTitle';
import OrderTotalTable from './OrderTotalTable';
import PaymentMethod from './PaymentMethod';
import SingleInput from '../../components/SingleInput';
import { UserContext } from '../../context/UserContext';
import AddShippingAddress from './AddShippingAddress';
import { AddressService } from '../../services/AddressService';
import useIsMounted from '../../hooks/useIsMounted';
import ShippingAddress from './ShippingAddress';
import { OrderService } from '../../services/OrderService';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const inputData = [
  { name1: 'Apartment, suite, unit etc.', name2: 'Town / City' },
  { name1: 'State / County', name2: 'Postcode / Zip' },
  { name1: 'Email', name2: 'Phone' },
];

const addressService = new AddressService();
const orderService = new OrderService();

function ShopCheckout() {
  const [ accordBtn, setAccordBtn ] = useState(false);
  const [ addressOption, setAddressOption ] = useState(-1);
  const [ addresses, setAddresses ] = useState([]);
  const { auth } = useContext(UserContext)
  const navigate = useNavigate()
  const cart = useShoppingCart()
  const isMounted = useIsMounted()

  const getUserAddresses = useCallback(async () => {
    if (!auth.user) return;

    const response = await addressService.getUserAddresses(auth.user?.id)
    isMounted() && setAddresses(response.data)
  }, [isMounted, auth])

  const checkout = useCallback(async () => {
    const payload = {
      userId: auth.user.id,
      addressId: addressOption,
      cart
    }

    const response = await orderService.create(payload)

    if (response?.data?.uid) {
      cart.clear();
      navigate(`/view-order/${response.data.uid}`)
    } else {
      Swal.fire({
        title: 'Error',
        text: 'We could not check you out.',
        icon: 'error',
      });
    }
  }, [cart, auth, addressOption, navigate])

  useEffect(() => {
    getUserAddresses()
  }, [getUserAddresses])

  return (
    <>
      <div className="page-content">
        <PageTitle parentPage="Shop" childPage="Checkout" />
        <section className="content-inner-1">
          {/* <!-- Product --> */}
          <div className="container">
            <div className="shop-form">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="widget">
                    <h4 className="widget-title">Billing & Shipping Address</h4>
                    <p>Select one of the options below to proceed with checkout.</p>
                    <div className='shipping-addresses'>
                      <div onClick={() => setAddressOption(0)}>
                        <input type="radio" id="control_01" name="select" value="0" />
                        <label htmlFor='control_01'>
                          <h5>Add New Address</h5>
                          <p>Add a new billing/shipping address to your profile.</p>
                        </label>
                      </div>
                      {addresses.map((address, i) => {return(<ShippingAddress key={i} setAddressOption={setAddressOption} address={address} />)})}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  {addressOption === 0 && (<AddShippingAddress getUserAddresses={getUserAddresses} />)}
                  <Collapse in={accordBtn} >
                    <div>
                      <div className="form-group">
                        <Form.Select aria-label="Åland Islands">
                          <option>Åland Islands</option>
                          <option value="1">Afghanistan</option>
                          <option value="2">Albania</option>
                          <option value="3">Algeria</option>
                          <option value="4">Andorra</option>
                          <option value="5">Angola</option>
                          <option value="6">Anguilla</option>
                          <option value="7">Antarctica</option>
                          <option value="8">Antigua and Barbuda</option>
                          <option value="9">Argentina</option>
                          <option value="10">Armenia</option>
                          <option value="11">Aruba</option>
                          <option value="12">Australia</option>
                        </Form.Select>
                      </div>
                      <div className="row">
                        <SingleInput ChangeclassName="col-md-6" title="First Name" />
                        <SingleInput ChangeclassName="col-md-6" title="Last Name" />
                      </div>
                      <SingleInput title="Company Name" />
                      <SingleInput title="Address" />
                      {inputData.map((data, index) => (
                        <div className="row" key={index}>
                          <div className="form-group col-md-6">
                            <input type="text" className="form-control" placeholder={data.name1} />
                          </div>
                          <div className="form-group col-md-6">
                            <input type="text" className="form-control" placeholder={data.name2} />
                          </div>
                        </div>
                      ))}
                      <button className="btn btn-primary btnhover mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#create-an-account">Create an account <i className ="fa fa-arrow-circle-o-down"></i></button>
                      <div id="create-an-account" className="collapse">
                        <p>Create an account by entering the information below. If you are a returning customer please login at the top of the page.</p>
                        <div className="form-group">
                          <input type="password" className="form-control" placeholder="Password" />
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
            {addressOption > 0 && (
              <>
                <div className="dz-divider bg-gray-dark text-gray-dark icon-center  my-5"><i className ="fa fa-circle bg-white text-gray-dark"></i></div>
                <div className="row">
                  <div className="col-lg-6">
                    <form className="shop-form widget">
                      <OrderTotalTable />
                    </form>
                  </div>
                  <div className="col-lg-6">
                    <form className="shop-form widget">
                      <PaymentMethod checkout={checkout}/>
                    </form>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </>
  )
}
export default ShopCheckout;