import PageTitle from '../../layouts/PageTitle';
import NewsLetter from '../../components/NewsLetter';

function PrivacyPolicy() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="Privacy Policy" />
        <section className="content-inner overlay-white-middle">
          <div className="container">
            <div className="row about-style1 align-items-center">
              <div className="col-lg-12 m-b30 aos-item">
                <div className="about-content px-lg-4">
                  <h6 className="title">
                    Privacy Policy
                  </h6>

                  <p>
                    THRASSAfrica is committed to protecting your privacy. This privacy policy explains what information we collect about users of our website and describes how we will use it.
                  </p>

                  <p>
                    Our website may contain links to other sites which are outside our control and are not covered by this privacy policy. If you access other sites using the links provided, your information may be used by the operators of those sites in accordance with their privacy policies.
                  </p>

                  <h6 className="title">
                    What information do we collect?
                  </h6>

                  <p>
                    THRASSAfrica collects information we learn about you from your use of our service and your visits to the website. When you forward personal information to us, THRASSAfrica will prevent such information from unauthorised access and use. We may collect additional information in connection with your participation in any promotions or competitions offered by our website and information you provide when giving feedback about the website.
                  </p>

                  <h6 className="title">
                    What do we do with the information?
                  </h6>

                  <p>
                    We will use your information for processing orders, answering product or service-related questions and to contact you regarding orders, special offers or new product ranges. We will monitor your use of our website to identify customer preferences and track the volume of traffic on particular pages.
                  </p>
                  
                  <p>
                    We may from time to time use your information to tell you about changes to the functionality of our website and new THRASSAfrica products. We may also send you offers or information in relation to products or services offered by third parties which we think you may be interested in.
                  </p>

                  <p>
                    We like to hear your views, to help us improve our service to you. From time to time, we may contact you to ask your opinion. We may contact you by post, fax, email or telephone. If at any time you decide you no longer want to be contacted by us or to receive offers and information, please contact us and we will remove you from our mailing list.
                  </p>

                  <p>
                    Your data will be stored on our secure server database in South Africa. We will not sell, rent or trade your information to others without your permission.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section >
        <NewsLetter />
      </div >
    </>
  )
}
export default PrivacyPolicy;