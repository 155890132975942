import React, { useEffect, useState, useCallback } from 'react';
import LeftMenu from '../LeftMenu';
import { Link, useParams } from 'react-router-dom';
import { OrderService } from '../../../services/OrderService';
import { formatCurrency } from '../../../utilities/formatCurrency';
import { UserContext } from '../../../context/UserContext';
import FormattedDate from '../../../components/FormattedDate';
import OrderBookImage from "./OrderBookImage";

const orderService = new OrderService();

function ViewOrder(props) {
  const [order, setOrder] = useState(null);
  const [orderItems, setOrderItems] = useState([]);
  const { auth } = React.useContext(UserContext)
  const { id } = useParams();
  const [disableAddressButton, setDisabledAddress] = useState(true);
  const getOrder = useCallback(async () => {
    var response = await orderService.getByUid(id);
    debugger
    setOrder(response.data);
    getSelectedCountry(response.data);
  }, [id])

  const getOrderItems = useCallback(async () => {
    var response = await orderService.getOrderItemsByUid(id);
    setOrderItems(response.data);
  
  }, [id])

  useEffect(() => {
    getOrder();
    getOrderItems();
  }, [getOrder, getOrderItems])

  
  function getSelectedCountry(order)
   
   {
 
     var country =order.deliveryAddress.country.name;
     var allowedCountries =process.env.REACT_APP_ALLOWED_COUNTRIES;
     setDisabledAddress(true);
  
     if( allowedCountries.indexOf(country)>=0){
       setDisabledAddress(false);
     }

   
   
   };

  return (
    <>
      <div className="page-content bg-white">
        <div className="content-block">
          <section className="content-inner bg-white">
            <div className="container">
              <div className="row">
                <LeftMenu />
                {order && (
                  <div className="col-xl-9 col-lg-8 m-b30">
                    <div className="shop-bx shop-profile">
                      <div className="shop-bx-title clearfix">
                        <h5 className="text-uppercase">
                          {`${order.reference}: `}
                          Order Details
                          {(order.balance > 0 && order.status === 0) && (
                            <a href={orderService.getPayFastUrl(order, auth.user.email)} 
                              rel="noopener noreferrer" 
                              className='float-end btn btn-primary btn-sm btnhover'    hidden={disableAddressButton} >
                              Click here to pay {formatCurrency(order.balance)}
                           
                            </a>
                          )}
                        </h5>
                        { disableAddressButton && <div className='backgroundWarning' >
                      <h6>Unable to deliver/subscribe</h6>
                      <p>Only Allowed countries are : {process.env.REACT_APP_ALLOWED_COUNTRIES}  </p>
                    </div>
                    }

                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <b>{`${order.deliveryAddress.firstName} ${order.deliveryAddress.lastName}`}</b><br/>
                          {order.deliveryAddress.streetAddress}, {order.deliveryAddress.apartment}, <br />
                          {order.deliveryAddress.city}, <br />
                          {order.deliveryAddress.province}, <br />
                          {order.deliveryAddress.postalCode} <br />
                          {order.deliveryAddress.country.name} <br />
                          {order.deliveryAddress.email} <br />
                        </div>
                        <div className='col-md-6'>
                          <b>Reference: </b> {order.reference}<br />
                          <b>Order Date: </b> <FormattedDate date={order.orderDate} /><br />
                          <b>Order Status: </b> {orderService.getStatus(order)}<br />
                          <b>Total: </b> {formatCurrency(order.total)}<br />
                          <b>VAT: </b> {formatCurrency(order.vat)}<br />
                          <b>Balance: </b> {formatCurrency(order.balance)}<br />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className='col-md-12'>
                          <div className="table-responsive">
                            <table className="table-bordered check-tbl m-b25">
                              <thead>
                                <tr>
                                  <th colSpan={2}>Item</th>
                                  <th className="currency-view">Unit Price</th>
                                  <th className="currency-view">Quantity</th>
                                  <th className="currency-view">Sub Total</th>
                                  <th className="currency-view">VAT</th>
                                  <th className="currency-view">Line Total (incl.)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orderItems.map((orderItem, i) => (
                                  <tr key={i}>
                                    <td>
                                      <OrderBookImage orderItem={orderItem} />
                                    </td>
                                    <td>
                                      {orderItem.book?.title ?? orderItem.set?.name ?? orderItem.subscriptionPlan?.name} {' '}
                                      {(orderItem.userSubscription !== null) 
                                      ? <Link to={`/view-subscription/${orderItem.userSubscription.slug}`} >
                                          <i className ='fa fa-play'></i>
                                        </Link>
                                      : null}
                                    </td>
                                    <td className="currency-view">{formatCurrency(orderItem.unitPrice)}</td>
                                    <td className="currency-view">{orderItem.quantity}</td>
                                    <td className="currency-view">{formatCurrency(orderItem.subTotal)}</td>
                                    <td className="currency-view">{formatCurrency(orderItem.vat)}</td>
                                    <td className="currency-view">{formatCurrency(orderItem.lineTotal)}</td>
                                  </tr>
                                ))}
                                <tr>
                                  <td colSpan={4}>Delivery Fee</td>
                                  <td className="currency-view">{formatCurrency(order.shippingAmount)}</td>
                                  <td className="currency-view">{formatCurrency(order.shippingAmount * order.vatPercentage / 100)}</td>
                                  <td className="currency-view">{formatCurrency(order.shippingAmount * (100 + order.vatPercentage) / 100)}</td>
                                </tr>
                              </tbody>
                              <thead>
                                <tr>
                                  <th colSpan={4}>GRAND TOTAL</th>
                                  <th className="currency-view">{formatCurrency(order.total - order.vat)}</th>
                                  <th className="currency-view">{formatCurrency(order.vat)}</th>
                                  <th className="currency-view">{formatCurrency(order.total)}</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
export default ViewOrder;