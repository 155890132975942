import React, { useCallback, useEffect, useState } from 'react';
import { UserContext } from '../../../context/UserContext';
import { UserSubscriptionService } from '../../../services/UserSubscriptionService';
import LeftMenu from '../LeftMenu';
import UserSubscriptionPreview from './UserSubscriptionPreview';
import Swal from "sweetalert2";
const userSubscriptionService = new UserSubscriptionService();

function Subscriptions() {
  const { auth } = React.useContext(UserContext)
  const [userSubs, setUserSubs] = useState([]);
  const [promoCode, setPromoCode] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const getUserSubscriptions = useCallback(async () => {
    const response = await userSubscriptionService.getByUserId(auth.user.id);
    setUserSubs(response.data);
  }, [auth.user.id])

  useEffect(() => {
    getUserSubscriptions()
  }, [getUserSubscriptions])

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await userSubscriptionService.redeemPromoCode(auth.user.id,promoCode)
        const data = response.data
        getUserSubscriptions();
        
        Swal.fire({
          title: data.includes("Error")?"Error": 'Success',
          text: response.data,
          icon: data.includes("Error")?"error": 'success' ,
        });
     
    } 
    catch (error) {
      const message = "Error in reedimg coupon: " + error.message
      setErrMsg(message);
      Swal.fire({
        title: 'Error',
        text: '' + message,
        icon: 'error',
      });
    }
  }


  return (
    <>
      <div className="page-content bg-white">
        <div className="content-block">
          <section className="content-inner bg-white">
            <div className="container">
              <div className="row">
                <LeftMenu />
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="shop-bx shop-profile ">
                    <div className="shop-bx-title clearfix">
                      
                      <div className="row">
                      <div className="col-lg-3 col-md-3">
                      <h5 className="text-uppercase  margin-center">
                        Subscriptions
                      </h5>
                      </div>
                              <div className="col-lg-6 col-md-6">
                                    <label className="label-title">Redeem Promo Code</label>
                                    <input className="form-control" type="text" name="promoCode" 
                                      id="promoCode"  placeholder="Promo Code"  onChange={(e) => setPromoCode(e.target.value)} value={promoCode} required
                                      />
                              </div>  
                              <div className="col-lg-3 col-md-3">
                            
                                  <button type="submit" className="btn btn-primary btnhover  margin-center" onClick={handleSubmit
                                  } >Submit</button>
                                  </div>
                      </div>   
                    

                    </div>

                    
                    <div className="row">
                      {userSubs.map((subscription, i) => (
                        <div className="col-lg-4 col-md-4 mb-3" key={i}>
                          <UserSubscriptionPreview subscription={subscription} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
export default Subscriptions;