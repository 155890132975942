import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop2 from "react-scroll-to-top";

//layouts
import Header from './../layouts/Header';
import Footer from './../layouts/Footer';
import ScrollToTop from './../layouts/ScrollToTop';
import Main from '../layouts/Main';

//Pages
import Home from './Home';
import AboutUs from './web/AboutUs';
import Services from './Services';
import Faq from './Faq';
import HelpDesk from './HelpDesk';
import Pricing from './Pricing';
import CategoryBooks from './books/CategoryBooks';
import SetGridView from './SetGridView';
import ShopList from './ShopList';
import BooksGridViewSidebar from './BooksGridViewSidebar';
import BooksListViewSidebar from './BooksListViewSidebar';
import ShopCart from './cart/ShopCart';
import Login from './auth/Login';
import ShopCheckout from './cart/ShopCheckout';
import BookDetail from './books/BookDetail';
import ContactUs from './web/ContactUs';
import WhatIsThrass from './WhatIsThrass';
import UsingThrass from './web/UsingThrass';
import TeachingThrass from './TeachingThrass';
import ThrassResources from './ThrassResources';
import ThrassInSa from './ThrassInSa';
import HowToOrder from './HowToOrder';
import ErrorPage from './ErrorPage';
import UnderConstruction from './UnderConstruction';
import ComingSoon from './ComingSoon';
import Dashboard from './user/Dashboard';
import logo from './../assets/images/logo.png';
import VerifyEmail from './auth/VerifyEmail';
import ChangePassword from './auth/ChangePassword';
import Subscriptions from './user/subscriptions/Subscriptions';
import ViewSubscription from './user/subscriptions/ViewSubscription';
import AccepGrouptInvitation from './AccepGrouptInvitation';
import AccepDirecttInvitation from './AccepDirecttInvitation';
import UserRoutes from '../utilities/UserRoutes';
import ViewOrder from './user/orders/ViewOrder';
import SetDetail from './books/SetDetail';
import TheGraphemeChart from './web/TheGraphemeChart';
import FreeGuides from './web/FreeGuides';
import Definitions from './web/Definitions';
import FreeSoftware from './web/FreeSoftware';
import TheKeyStages from './web/TheKeyStages';
import TheEnglishPhonicsChart from './web/TheEnglishPhonicsChart';
import FocusAreas from './web/FocusAreas';
import TeachersGuides from './web/TeachersGuides';
import TermsConditions from './web/TermsConditions';
import PrivacyPolicy from './web/PrivacyPolicy';
import LearnersPhonicsWorkbooks from './web/LearnersPhonicsWorkbooks';
import HomeLanguage from './web/HomeLanguage';
import EnglishPhonicsReaders from './web/EnglishPhonicsReaders';
import EnglishPhonicsReaders2 from './web/EnglishPhonicsReaders2';
import FirstAdditionalLanguage from './web/FirstAdditionalLanguage';
import Orders from './user/orders/Orders';
import MyProfile from './user/MyProfile';
import Registration from './auth/Registration';
import SearchResults from './web/SearchResults';
import Addresses from './user/Addresses';

function Index(props) {
  return (
    <BrowserRouter>
      <div className="page-wraper">
        <Header />
        <Main>
          <Routes>
            <Route path='/error-404' element={<ErrorPage />} />
            <Route path='/under-construction' element={<UnderConstruction />} />
            <Route path='/coming-soon' element={<ComingSoon />} />

            <Route path='/' exact element={<Home />} />
           
            <Route path='/home-language/hl-teachers-guides' exact element={<CategoryBooks id="teacher-guides1" />} />
            <Route path='/home-language/hl-learners-phonics-workbooks' exact element={<CategoryBooks id="learner-phonics-workbooks" />} />
            <Route path='/home-language/my-word-power-phonics-books' exact element={<CategoryBooks id="my-word-power-phonics-books" />} />
           
            <Route path='/first-additional-language/fal-teachers-guides' exact element={<CategoryBooks id="teacher-guides" />} />
            <Route path='/first-additional-language/fal-learners-phonics-workbooks' exact element={<CategoryBooks id="learners-phonics-workbooks" />} />
           
            <Route path='/english-phonics-readers/reader-sets' exact element={<SetGridView id="english-phonics-readers---sets" />} />
            <Route path='/english-phonics-readers/individual-readers-level-1' exact element={<CategoryBooks id="english-phonics-reader---level-1" />} />
            <Route path='/english-phonics-readers/individual-readers-level-2' exact element={<CategoryBooks id="english-phonics-reader---level-2" />} />
            <Route path='/english-phonics-readers/individual-readers-level-3' exact element={<CategoryBooks id="english-phonics-reader---level-3" />} />
            <Route path='/tiles-cards' exact element={<CategoryBooks id="cards-tiles" />} />
            <Route path='/charts' exact element={<CategoryBooks id="charts" />} />
            <Route path='/sing-a-long' exact element={<CategoryBooks id="sing-a-long" />} />
            <Route path='/interactive' exact element={<CategoryBooks id="interactive" />} />
            <Route path='/additional-books' exact element={<CategoryBooks id="additional-books" />} />
            <Route path='/on-promotion' exact element={<CategoryBooks id="on-promotion" />} />
            <Route path='/new-products' exact element={<CategoryBooks id="new-products" />} />
            <Route path='/book/:id' exact element={<BookDetail />} />
            <Route path='/set/:id' exact element={<SetDetail />} />

            <Route path='/what-is-thrass' exact element={<WhatIsThrass />} />
            <Route path='/using-thrass' exact element={<UsingThrass />} />
            <Route path='/teaching-thrass' exact element={<TeachingThrass />} />
            <Route path='/thrass-resources' exact element={<ThrassResources />} />
            <Route path='/thrass-in-south-africa' exact element={<ThrassInSa />} />
            <Route path='/how-to-order' exact element={<HowToOrder />} />
            <Route path='/how-thrass-works/thrass-gallery' exact element={<AboutUs />} />
            <Route path='/the-grapheme-chart' exact element={<TheGraphemeChart />} />
            <Route path='/focus-areas' exact element={<FocusAreas />} />
            <Route path='/definitions' exact element={<Definitions />} />
            <Route path='/the-key-stages' exact element={<TheKeyStages />} />
            <Route path='/free-guide' exact element={<FreeGuides />} />
            <Route path='/free-software' exact element={<FreeSoftware />} />
            <Route path='/the-english-phonics-chart' exact element={<TheEnglishPhonicsChart />} />
            <Route path='/teacher-s-guides' exact element={<TeachersGuides />} />
            <Route path='/terms-conditions-2' exact element={<TermsConditions />} />
            <Route path='/privacy-policy-2' exact element={<PrivacyPolicy />} />
            <Route path='/learner-s-phonics-workbooks' exact element={<LearnersPhonicsWorkbooks />} />
            <Route path='/contact-us' exact element={<ContactUs />} />
            <Route path='/home-language' exact element={<HomeLanguage />} />
            <Route path='/first-additional-language' exact element={<FirstAdditionalLanguage />} />
            <Route path='/english-phonics-readers' exact element={<EnglishPhonicsReaders />} />
            <Route path='/english-phonics-readers-2' exact element={<EnglishPhonicsReaders2 />} />

            <Route path='/accept/:id' exact element={<AccepGrouptInvitation />} />
            <Route path='/accept-direct-invitation/:id' exact element={<AccepDirecttInvitation />} />
            <Route path='/login' exact element={<Login />} />
            <Route path='/registration' exact element={<Registration />} />
            <Route path='/verify-email/:id' exact element={<VerifyEmail />} />
            <Route path='/reset-password' exact element={<ChangePassword />} />

            <Route element={<UserRoutes />}>
              <Route path='/addresses' exact element={<Addresses />} />
              <Route path='/dashboard' exact element={<Dashboard />} />
              <Route path='/profile' exact element={<MyProfile />} />
              <Route path='/subscriptions' exact element={<Subscriptions />} />
              <Route path='/orders' exact element={<Orders />} />
              <Route path='/view-subscription/:id' exact element={<ViewSubscription />} />
              <Route path='/view-order/:id' exact element={<ViewOrder />} />
            </Route>

            <Route path='/services' exact element={<Services />} />
            <Route path='/faq' exact element={<Faq />} />
            <Route path='/help-desk' exact element={<HelpDesk />} />
            <Route path='/pricing' exact element={<Pricing />} />
            <Route path='/books-list' exact element={<ShopList />} />
            <Route path='/books-grid-view-sidebar' exact element={<BooksGridViewSidebar />} />
            <Route path='/books-list-view-sidebar' exact element={<BooksListViewSidebar />} />
            <Route path='/shop-cart' exact element={<ShopCart />} />
            <Route path='/search/:urlKeyword/:urlTake/:urlSkip' exact element={<SearchResults />} />

            <Route path='/shop-checkout' exact element={<ShopCheckout />} />
          </Routes>
        </Main>
        <Footer footerChange="style-1" logoImage={logo} />
        <ScrollToTop />
        <ScrollToTop2 className="styles_scroll-to-top__2A70v  fas fa-arrow-up scroltop" smooth />
      </div>
    </BrowserRouter>
  )
}
export default Index;