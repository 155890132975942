import Index from './pages/Index';	
import "./assets/css/style.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import { AuthProvider } from './context/AuthProvider';
import { WishlistProvider } from './context/WishlistProvider';
import { ShoppingCartProvider } from './context/ShoppingCartContext';
import { LocationDetailProvider } from './context/LocationContext';

function App() {
	return (
		<div className="App">
      <LocationDetailProvider>
        <ShoppingCartProvider>
          <AuthProvider>
            <WishlistProvider>
              <Index /> 
            </WishlistProvider>
          </AuthProvider>
        </ShoppingCartProvider>
      </LocationDetailProvider>
		</div>	
	);
}

export default App;
