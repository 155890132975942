import React from 'react';
import {Link} from 'react-router-dom';
//Components 
import PageTitle from './../layouts/PageTitle';
import NewsLetter from '../components/NewsLetter';

const missionBlog = [
    {iconClass:'flaticon-open-book-1', title:'Best Bookstore' },
    {iconClass:'flaticon-exclusive', title:'Trusted Seller' },
    {iconClass:'flaticon-store', title:'Expand Store' },
];

function ThrassInSa(){
    return(
        <>
            <div className="page-content bg-white">
               <PageTitle  parentPage="Home" childPage="THRASS in South Africa" />
               <section className="content-inner overlay-white-middle">
                    <div className="container">
                        <div className="row about-style1 align-items-center">
                            <div className="col-lg-12 m-b30 aos-item">
                                <div className="about-content px-lg-4">
                                    <div className="section-head style-1">
                                        <h6 className="title">
                                            THRASSAfrica is operated by Next Level Learning (Pty) Ltd, and is the only company authorised 
                                            to sell THRASS products in South Africa, Swaziland, Lesotho, Botswana and Namibia.
                                        </h6>
                                    </div>
                                    <p>
                                        Next Level Learning (Pty) Ltd: c/o 6 Pencil Park, Croxley Close, Heriotdale X15, 2094.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <NewsLetter />             
            </div>
        </>
    )
}
export default ThrassInSa;