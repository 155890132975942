import axios from "axios";

export default class HttpService {

 baseUrl = process.env.REACT_APP_API_PATH;

 
  
 // baseUrl: string = "https://www.thrassafrica.co.za/ThrassAfricaApi/";
  urlPrefix: string = this.baseUrl + "api/";
  constructor(url: string) {
    this.urlPrefix += url;
  }

  async delete(url: string) {
    return await axios.delete(`${this.urlPrefix}/${url}`);
  }

  async get(url: string) {
    return await axios.get(`${this.urlPrefix}/${url}`);
  }

  async patch(url: string, body: any) {
    return await axios.patch(`${this.urlPrefix}/${url}`, body);
  }

  async post(url: string, body: any) {
    return await axios.post(`${this.urlPrefix}/${url}`, body);
  }

  async put(url: string, body: any) {
    return await axios.put(`${this.urlPrefix}/${url}`, body);
  }
}
