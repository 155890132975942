import PageTitle from '../../layouts/PageTitle';
import rs from "../../assets/images/Reader-Sets_295x295.jpg";
import l1 from "../../assets/images/EPR-Level-1_295x295.jpg";
import l2 from "../../assets/images/EPR-Level-2_295x295.jpg";
import l3 from "../../assets/images/EPR-Level-3_295x295_295x295.jpg";

function EnglishPhonicsReaders() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="English Phonics Readers" />
        <section className="content-inner overlay-white-middle">
          <div className="container">
            <div className="row about-style1 align-items-center">
              <div className="col-lg-12 m-b30 aos-item">
                <div className="about-content px-lg-4">
                  <h6 className="title">
                  English Phonics Readers
                  </h6>
                  <div className='row'>
                  <div className='col-md-2'>
                      <a href="/english-phonics-readers/reader-sets" className='link-to-category'>
                        <img src={rs} alt="English Phonics Readers - Sets" className="center" />
                        English Phonics Readers - Sets
                      </a>
                    </div>
                    <div className='col-md-2'>
                      <a href="/english-phonics-readers/individual-readers-level-1" className='link-to-category'>
                        <img src={l1} alt="English Phonics Reader - Level 1" className="center" />
                        English Phonics Reader - Level 1
                      </a>
                    </div>
                    <div className='col-md-2'>
                      <a href="/english-phonics-readers/individual-readers-level-2" className='link-to-category'>
                        <img src={l2} alt="English Phonics Reader - Level 2" className="center" />
                        English Phonics Reader - Level 2
                      </a>
                    </div>
                    <div className='col-md-2'>
                      <a href="/english-phonics-readers/individual-readers-level-3" className='link-to-category'>
                        <img src={l3} alt="English Phonics Reader Level 3" className="center" />
                        English Phonics Reader Level 3
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default EnglishPhonicsReaders;