function BackToCategory({category}) {
  return ( 
    <>
      {(category) 
        ? <div className="dz-header">
            <a href={`/${category.url}`}>
              <p className="title">Back to {category.name}</p>
            </a>
          </div>
        : null
      }
    </>
  );
}

export default BackToCategory;