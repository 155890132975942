import PageTitle from '../../layouts/PageTitle';
import NewsLetter from '../../components/NewsLetter';
import thrassimage1 from '../../assets/images/thrassimage1.jpg'
import thrassimage2 from '../../assets/images/thrassimage2.jpg'

function FocusAreas() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="THRASS Focus Areas" />
        <section className="content-inner overlay-white-middle">
          <div className="container">
            <div className="row about-style1 align-items-center">
              <div className="col-lg-12 m-b30 aos-item">
                <div className="about-content px-lg-4">
                  <h6 className="title">
                    THRASS Focus Areas
                  </h6>

                  <img src={thrassimage1} alt="Thrass Chart" className="center" />

                  <p>
                    THRASS is divided into three main skills areas: handwriting, reading and spelling. The skills are inter-related and
                    build on each other to increase understanding.
                  </p>

                  <p>
                    THRASS focuses on:
                    <ul className="list-check primary">
                      <li> Knowledge of the alphabet and the naming of the lower case letters and their capitals. </li>
                      <li>Using the correct terms from the beginning. </li>
                      <li>The alphabet as a resource, from which letters are selected to represent the phonemes of spoken English. </li>
                      <li>Handwriting skills – the correct formation of lower case and capital letters. </li>
                      <li> An awareness of the 44 phonemes (speech sounds) of spoken English.</li>
                      <li> An awareness of graphs, digraphs, trigraphs and quadgraphs (spelling choices). </li>
                    </ul>
                  </p>

                  <img src={thrassimage2} alt="Thrass Chart" className="center" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <NewsLetter />
      </div>
    </>
  )
}
export default FocusAreas;