import { useState } from 'react';
import BookPdfPreview from './BookPdfPreview';
import BookVideoPreview from './BookVideoPreview';

function BookVideoPdfPreviewSelector({ subscriptionPlans, book, file, id }) {
  const [option, setOption] = useState('')

  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Book Preview</h4>
        <p>Please click an option below to select a preview of the book to show:</p>
        <div className='row mb-3'>
          <div className='col-md-6'>
            <div className='shipping-addresses'>
              <div onClick={() => setOption('pdf')}>
                <input type="radio" id="pdf_option" name="option" />
                <label htmlFor='pdf_option'>
                  <h5>Hard Copy Preview </h5>
                  <p>Click here to show  preview of the book.</p>
                </label>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='shipping-addresses'>
              <div onClick={() => setOption('video')}>
                <input type="radio" id="video_option" name="option" />
                <label htmlFor='video_option'>
                  <h5>Digital Copy Preview</h5>
                  <p>Click here to show a preview of the book.</p>
                </label>
              </div>
            </div>
          </div>
        </div>
        {(option === 'pdf') && <BookPdfPreview file={file} book={book} id={id} />}
        {(option === 'video') && <BookVideoPreview subscriptionPlans={subscriptionPlans} book={book} id={id} /> }
      </div>
    </>
  );
}

export default BookVideoPdfPreviewSelector;