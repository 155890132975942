import React from 'react';
import {Link} from 'react-router-dom';
//Components 
import PageTitle from './../layouts/PageTitle';
import NewsLetter from '../components/NewsLetter';

const missionBlog = [
    {iconClass:'flaticon-open-book-1', title:'Best Bookstore' },
    {iconClass:'flaticon-exclusive', title:'Trusted Seller' },
    {iconClass:'flaticon-store', title:'Expand Store' },
];

function ThrassResources(){
    return(
        <>
            <div className="page-content bg-white">
               <PageTitle  parentPage="Home" childPage="THRASS Resources" />
               <section className="content-inner overlay-white-middle">
                    <div className="container">
                        <div className="row about-style1 align-items-center">
                            <div className="col-lg-12 m-b30 aos-item">
                                <div className="about-content px-lg-4">
                                    <div className="section-head style-1">
                                        <h4 className="title">
                                            Our extensive range of resources can be used to assist in the successful implementation of
                                            THRASS methodology in the learning environment.
                                        </h4>
                                        <h6 className="title">
                                            These printed and digital resources offer ‘teaching tools’ that include charts, books, jigsaw 
                                            puzzles, flashcards and more.
                                        </h6>
                                    </div>
                                    <p>
                                        Designed to be consistent with the THRASS words, pictures, concepts and terminology, they are not 
                                        limited to use with any particular age or learning level.
                                    </p>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <NewsLetter />             
            </div>
        </>
    )
}
export default ThrassResources;