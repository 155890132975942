import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './../assets/images/logo.png';
import Collapse from 'react-bootstrap/Collapse';
import { MenuListArray2 } from './MenuListArray2';
import { CategoryService } from '../services/CategoryService';
import { UserContext } from '../context/UserContext';
import { useShoppingCart } from '../context/ShoppingCartContext';
import TopNavCart from '../pages/cart/TopNavCart';
import TopNavUser from '../pages/user/TopNavUser';
import { useLocation } from '../context/LocationContext';
import Search from './Search';

const categoryService = new CategoryService();

function Header() {
  const [headerFix, setheaderFix] = React.useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [active, setActive] = useState('Home')
  const [categories, setCategories] = useState([])
  const { cartQuantity } = useShoppingCart()
  const { auth } = React.useContext(UserContext)
  const { getLocationDetails } = useLocation()

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    var mainMenu = document.getElementById('OpenMenu');
    if (mainMenu) {
      if (sidebarOpen) {
        mainMenu.classList.add('show');
      } else {
        mainMenu.classList.remove('show');
      }
    }
  });

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    getLocationDetails()
  }, [getLocationDetails])

  const showSidebar = () => setSidebarOpen(!sidebarOpen);

  const handleMenuActive = status => {
    setActive(status)
    if (active === status) {
      setActive('');
    }
  }

  const getCategories = async () => {
    const response = await categoryService.getAll();
    setCategories(response.data)
  }

  return (
    <header className="site-header mo-left header style-1">
      <div className="header-info-bar">
        <div className="container clearfix">
          <div className="logo-header logo-dark">
            <Link to={""}><img src={logo} alt="logo" /></Link>
          </div>

          <div className="extra-nav">
            <div className="extra-cell">
              <ul className="navbar-nav header-right">
             
                {cartQuantity > 0 && (<TopNavCart />) }
              
              </ul>
            </div>

            <div className="extra-cell">
              <ul className="navbar-nav header-right">
              {auth?.user && (<TopNavUser />)}
                {
                  !auth?.user && (
                    <li className="nav-item">
                      <Link to={"/login"} className="nav-link">
                        Login/Register
                      </Link>
                    </li>
                  )
                }
              
              </ul>
            </div>
          </div>

          {/* <!-- header search nav --> */}
          <Search categories={categories} />
        </div>
      </div>
      <div className={`sticky-header main-bar-wraper navbar-expand-lg ${headerFix ? "is-fixed" : ""}`}>
        <div className="main-bar clearfix">
          <div className="container clearfix">
            {/* <!-- Website Logo --> */}
            <div className="logo-header logo-dark">
              <Link to={""}><img src={logo} alt="logo" /></Link>
            </div>
            
            

            {/* <!-- Nav Toggle Button --> */}
            <button className={`navbar-toggler collapsed navicon justify-content-end ${sidebarOpen ? 'open' : ''}`} onClick={showSidebar} >
              <span></span>
              <span></span>
              <span></span>
            </button>

            {/* <!-- EXTRA NAV --> */}
            {/* <div className="extra-nav">
							<div className="extra-cell">
								<Link to={"contact-us"} className="btn btn-primary btnhover">Get In Touch</Link>	
							</div>
						</div> */}

            {/* <!-- Main Nav --> */}
            <div className={`header-nav navbar-collapse collapse justify-content-start ${sidebarOpen ? 'show' : ''}`} id="navbarNavDropdown">
              {/* <div className="logo-header logo-dark">
								<Link to={""}><img src={logo} alt="" /></Link>
							</div>
							<form className="search-input">
								<div className="input-group">
									<input type="text" className="form-control" aria-label="Text input with dropdown button" placeholder="Search Books Here" />
									<button className="btn" type="button"><i className ="flaticon-loupe"></i></button>
								</div>
							</form> */}
              <ul className="nav navbar-nav">
                {
                  MenuListArray2.map((data, index) => {
                    return (
                      <li key={index}
                        className={`${(active === data.title) && (data.content) ? 'sub-menu-down open' : data.content ? 'sub-menu-down' : ''} `}
                      >
                        <Link to={data.content ? "#" : data.to}
                          onClick={() => handleMenuActive(data.title)}
                        >
                          <span>{data.title}</span>
                        </Link>
                        {data.content &&
                          <Collapse in={active === data.title ? true : false}>
                            <ul className="sub-menu">
                              {data.content && data.content.map((data, index) => {
                                return (
                                  <li key={index}><Link to={data.to}>{data.title}</Link></li>
                                )
                              })}
                            </ul>
                          </Collapse>
                        }
                      </li>
                    )
                  })
                }
              </ul>





            </div>
            <div className="logo-header logo-headerProfile ">
           
           <ul className="navbar-nav header-right">
           {auth?.user && (<TopNavUser />)}
             {
               !auth?.user && (
                 <li className="nav-item">
                   <Link to={"/login"} className="nav-link">
                     Login/Register
                   </Link>
                 </li>
               )
             }
           
           </ul>
        
         </div>
         <div className="logo-header logo-headerSearch">
         <Search categories={categories} />
         </div>

         <div className="logo-header logo-headerContents">
         <ul className="navbar-nav header-right">
          
          {cartQuantity > 0 && (<TopNavCart />) }
        
        </ul>
         </div>


          </div>
        </div>
      </div>


      
    </header>
  )
}
export default Header;