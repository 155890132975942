import { Link } from "react-router-dom";

function SetDetailButton({ set }) {
  return (
    <Link to={`/set/${set.slug}`} className="btn btn-secondary btnhover btnhover2">
      <i className ="fa fa-book m-r10"></i>
      Product Details
    </Link>
  );
}

export default SetDetailButton;