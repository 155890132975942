import PageTitle from '../../layouts/PageTitle';
import NewsLetter from '../../components/NewsLetter';
function FreeGuides() {
	return (
		<>
			<div className="page-content bg-white">
				<PageTitle parentPage="Home" childPage="Free Guides" />
				<section className="content-inner overlay-white-middle">
					<div className="container">
						<div className="row about-style1 align-items-center">
							<div className="col-lg-12 m-b30 aos-item">
								<div className="about-content px-lg-4">
									<h6 className="title">
										Teacher's Guide
									</h6>
									<p>
										Resources and guidance notes for teaching the THRASS ‘I CAN… Skills’.
									</p>
									<p>
										The English Phonics Chart Programme is for learners of all ages and abilities, including young children
										and those learning English as an additional language. It teaches learners to read and
										spell words by sight, by first teaching them to blend the right sounds when they read,
										and visualise and name the right letters when they spell. It can be used for individuals,
										classes or whole schools.
									</p>
									<p>
										<a href="https://thrassafrica.co.za/images/downloads/EPCP-Teachers-Guide.pdf">
											Download the EPCP Teacher's Guide.
										</a>
									</p>
									<h6 className="title">
										Parent's Guide
									</h6>
									<p>
										The English Phonics Chart Programme uses a range of colourful resources to teach your child
										to blend the right sounds when they read and visualise and name the right letters when they spell.
									</p>
									<p>
										Suitable for children of all ages and abilities, including young children and those learning
										English as an additional language.
									</p>
									<p>
										<a href="https://thrassafrica.co.za/images/downloads/EPCP-Drill.pdf">
											Download the EPCP Parent's Guide.
										</a>
									</p>
									<h6 className="title">
										Skills Checklist
									</h6>
									<p>
										This checklist helps to locate and name the landmarks, letters, keyspellings and keywords for the 8 phrases listed on the English Phonics Chart Drill.
									</p>
									<p>
										<a href="https://thrassafrica.co.za/images/downloads/EPCP-Skill-Checklist.pdf">
											Download the EPCP Skills Checklist.
										</a>									</p>
									<h6 className="title">
										Class Checklist
									</h6>
									<p>
										The programme is for learners of all ages and abilities, including young children and those learning English as an additional language.
									</p>
									<p>
										The programme teaches learners to blend the right sounds when they read and to visualise and name the right letters when they spell. It can be used for individuals, groups, classes or schools. This Class Checklist can be printed as an A4 deskchart or A3 wallchart and the boxes ticked or shaded to record the progress made by a class of 30 learners.
									</p>
									<p>
										<a href="https://thrassafrica.co.za/images/downloads/EPCP-Class-Checklist.pdf">
											Download the EPCP Class Checklist.
										</a>									</p>
									<h6 className="title">
										Drill
									</h6>
									<p>
										Using an English Phonics Chart, practise the actions and wording in each phase until you can do all eight phases in one continuous solo/group performance – without any errors or prompting!
									</p>
									<p>
										How long will it take you and your learners to learn the whole drill?
									</p>
									<p>
										<a href="https://thrassafrica.co.za/images/downloads/EPCP-Drill.pdf">
											Download the EPCP Drill.
										</a>
									</p>

								</div>
							</div>
						</div>
					</div>
				</section>
				<NewsLetter />
			</div>
		</>
	)
}
export default FreeGuides;