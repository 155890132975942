import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PageTitle from '../../layouts/PageTitle';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import CartTableRow from './CartTableRow';
import OrderTotalTable from './OrderTotalTable';
import { UserContext } from '../../context/UserContext';

function ShopCart() {
  const { cartItems } = useShoppingCart()
  const { auth } = useContext(UserContext)
  const [ checkoutUrl, setCheckoutUrl ] = useState('/login')

  useEffect(() => {
    if (auth.user) setCheckoutUrl('/shop-checkout')
  }, [auth.user])

  return (
    <>
      <div className="page-content">
        <PageTitle parentPage="Shopping Shop" childPage="Cart" />
        <section className="content-inner shop-account">
          {/* <!-- Product --> */}
          <div className="container">
            <div className="row mb-5">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table className="table check-tbl">
                    <thead>
                      <tr>
                        <th>Book</th>
                        <th>Book Title</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                        <th className="text-end">Remove</th>
                      </tr>
                    </thead>
                    {cartItems.map((item, index) => (
                      <tbody key={index}>
                        <CartTableRow cartItem={item} />
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="widget">
                  <form className="shop-form">
                    <h4 className="widget-title">Apply School Code</h4>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="School Code" defaultValue={''} />
                    </div>
                    <div className="form-group">
                      <Link to={"#"} className="btn btn-primary btnhover" type="button">Apply School Code</Link>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="widget">
                  <OrderTotalTable />
                  <div className="form-group m-b25">
                    <Link to={checkoutUrl} className="btn btn-primary btnhover" type="button">Proceed to Checkout</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default ShopCart;