import PageTitle from '../../layouts/PageTitle';
import NewsLetter from '../../components/NewsLetter';

function TermsConditions() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="Terms & Conditions" />
        <section className="content-inner overlay-white-middle">
          <div className="container">
            <div className="row about-style1 align-items-center">
              <div className="col-lg-12 m-b30 aos-item">
                <div className="about-content px-lg-4">
                  <h6 className="title">
                    Terms & Conditions
                  </h6>

                  <h6 className="title">
                    Use of this Website
                  </h6>

                  <p>
                    No part of this website may be copied, reproduced, modified, distributed, republished, displayed, posted or
                    transmitted without the permission of THRASSAfrica. You may view or print individual pages only for your own
                    personal, non-commercial use provided all copyright and other proprietary notices remain intact</p>

                  <h6 className="title">
                    Content & Advertisements
                  </h6>

                  <p>
                    The content on this website is provided "as is" and without warranties of any kind, either express or implied.
                    Because of the nature of the Internet, THRASSAfrica cannot verify whether it is accurate or up to date. Errors
                    and omissions are excepted.
                  </p>

                  <h6 className="title">
                    Data Protection & Privacy
                  </h6>

                  <p>
                    THRASSAfrica may use the information you provide and the records of your visits to the website to monitor and
                    improve our service to you, as well as for marketing and promotional purposes. Any details which you provide to
                    us, and from which we can identify you, are held and processed in accordance with our <a
                      href="https://thrassafrica.co.za/index.php?option=com_content&view=article&id=23:privacy-policy&catid=8:about&Itemid=282">
                      Privacy Policy.</a></p>

                  <h6 className="title">
                    Limitation of Liability
                  </h6>

                  <p>
                    This website should only be used for information purposes. It is not advice, and you should not rely on it to
                    make any decision or take any action.
                    THRASSAfrica does not guarantee that the website will operate free of error or that it is free from computer
                    viruses or any other contaminating computer program. THRASSAfrica is not liable for any damage or losses
                    (including, but not limited to, corruption or destruction of data) resulting from your use of or inability to
                    use the website.
                    You will be responsible if you use this website in breach of these terms and will reimburse THRASSAfrica for any
                    loss that you cause to us as a result of your misuse.
                  </p>

                  <h6 className="title">
                    Agreement of Sale
                  </h6>

                  <p>
                    An agreement of sale only comes into effect if and when a completed order for one or more products is submitted
                    and payment is either authorised, or received by THRASSAfrica in its bank account.
                  </p>

                  <h6 className="title">
                    Changes to the Website or Terms
                  </h6>

                  <p>
                    THRASSAfrica may change the website or these terms at any time by posting notice of the changes on this website.
                    If you use the website after THRASSAfrica has posted the changes you will be bound by the new terms. You should
                    therefore ensure that you read the terms each time you use the website.
                  </p>


                  <h6 className="title">
                    Governing Law & Jurisdiction
                  </h6>

                  <p>
                    This website is hosted, controlled and operated from the Republic of South Africa and therefore governed by
                    South African law and, subject to the ‘disputes’ clause of these Terms and Conditions, you and THRASSAfrica
                    submit to the non-exclusive jurisdiction of the South African courts.
                  </p>

                  <h6 className="title">
                    Security
                  </h6>

                  <p>
                    Any person that delivers or attempts to deliver any damaging code to this website or attempts to gain
                    unauthorised access to any page on this website shall be prosecuted and civil damages shall be claimed in the
                    event that THRASSAfrica suffers any damage or loss.
                    You agree and warrant that your log-in name and password shall be used for your personal use only and never be
                    disclosed to any third party. Any losses incurred due to transactions occurring by anyone using your log-in
                    details shall be your responsibility only and THRASSAfrica shall bear no responsibility in any way.
                  </p>

                  <h6 className="title">
                    Disputes
                  </h6>

                  <p>
                    Save for urgent or interim relief which may be granted by a competent court, in the event of any dispute of any
                    nature whatsoever arising between you and THRASSAfrica on any matter provided for in, or arising out of these
                    Terms and Conditions, and not resolved through the customer relations department of THRASSAfrica, then such a
                    dispute shall be submitted to confidential arbitration in terms of the expedited rules of the Arbitration
                    Foundation of South Africa. Arbitration proceedings shall be conducted in Cape Town in English.

                  </p>

                  <h6 className="title">
                    Domicilium
                  </h6>

                  <p>
                    THRASSAfrica chooses as its domicilium citandi et executandi for all purposes under these Terms & Conditions,
                    whether in respect of court process, notice, or other documents or communication of whatsoever nature, the
                    following address: 6 Pencil Park, Croxley Close, Heriotdale X15, Johannesburg South Africa.
                  </p>

                  <h6 className="title">
                    Disclaimer
                  </h6>

                  <p>
                    THRASSAfrica shall in no event be responsible and disclaims all liability for any loss, liability, damage
                    (whether direct, indirect, special or consequential) or expense of any nature whatsoever, which may be suffered
                    as a result of or which may be attributable, directly or indirectly, to the use of, or reliance upon any
                    information, links or service provided through the website, or any actions and/or liability for consequential or
                    incidental damages. In such jurisdictions, our liability is limited to the greatest extent permitted by law.
                  </p>
                  <p>
                    THRASSAfrica will not be responsible for any interruption, delayed or failed transmission, loss of programs or
                    other data, storage or delivery of information, resulting from whatsoever cause.
                  </p>

                  <p>
                    All the information appearing on the website is provided without a representation or warranty whatsoever,
                    whether express or implied.
                  </p>
                  <p>
                    The information contained on the website provides general information on particular subjects and is not an
                    exhaustive treatment of such subjects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NewsLetter />
      </div>
    </>
  )
}
export default TermsConditions;