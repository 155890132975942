import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { UserContext } from '../../context/UserContext';
import { AddressService } from '../../services/AddressService';
import EditAddressModal from './EditAddressModal';
import LeftMenu from './LeftMenu';
import Loading from 'react-fullscreen-loading';

const addressesService = new AddressService();

function Addresses() {
  const { auth } = React.useContext(UserContext)
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [showLoader, setShowLoader] = useState(false);

  const getAddresses = useCallback(async () => {
    const response = await addressesService.getUserAddresses(auth.user.id);
    setAddresses(response.data);
  }, [auth.user.id])

  const handleEditSubmit = async (payload) => {
    try {
      setShowLoader(true);

      const response = await addressesService.update(payload)
      setOpen(false)
      getAddresses()
      setShowLoader(false);
      Swal.fire({
        title: 'Success',
        text: 'The delivery address has been updated',
        icon: 'success',
      });

    } catch (error) {
      setShowLoader(false);
      Swal.fire({
        title: 'Error',
        text: 'The address could not be updated. Please try again.',
        icon: 'error',
      });
    }
  }

  const onEditClick = (address) => {
    setAddress(address)
    setOpen(true)
  }

  useEffect(() => {
    getAddresses()
  }, [getAddresses])

  return (
    <>
      <div className="page-content bg-white">
        <div className="content-block">
          {showLoader? ( <Loading loading  className="loader" loaderColor="#3498db" />):(<></>)}           
          <section className="content-inner bg-white">
            <div className="container">
              <div className="row">
                <LeftMenu />
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="shop-bx shop-profile">
                    <div className="shop-bx-title clearfix">
                      <h5 className="text-uppercase">
                        Addresses
                      </h5>
                    </div>
                    <div className="row">
                      <div className='col-md-12'>
                        <div className="table-responsive">
                          <table className="table-bordered check-tbl m-b25">
                            <thead>
                              <tr>
                                <th>Full Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Company</th>
                                <th>Address</th>
                                <th>Postal Code</th>
                                <th>Country</th>
                                <th className="currency-view"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {addresses.map((address, i) => (
                                <tr key={i}>
                                  <td>{`${address.firstName} ${address.lastName}`}</td>
                                  <td>{address.phone}</td>
                                  <td>{address.email}</td>
                                  <td>{address.company}</td>
                                  <td>{`${address.apartment}, ${address.streetAddress}, ${address.city}, ${address.province}`}</td>
                                  <td>{address.postalCode}</td>
                                  <td>{address.country?.name}</td>
                                  <td>
                                    <button className='float-end btn btn-primary btnhover' onClick={() => onEditClick(address)}>
                                      Edit
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <EditAddressModal 
                            open={open} 
                            handleClose={handleClose} 
                            address={address}
                            handleEditSubmit={handleEditSubmit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
export default Addresses;