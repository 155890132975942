import PageTitle from '../../layouts/PageTitle';
import NewsLetter from '../../components/NewsLetter';
function UsingThrass(){
    return(
        <>
            <div className="page-content bg-white">
               <PageTitle  parentPage="Home" childPage="Using THRASS" />
               <section className="content-inner overlay-white-middle">
                    <div className="container">
                        <div className="row about-style1 align-items-center">
                            <div className="col-lg-12 m-b30 aos-item">
                                <div className="about-content px-lg-4">
                                    <div className="section-head style-1">
                                        <h4 className="title">
                                            Scientific research has shown that phonics is a crucial strategy in understanding how to spell.
                                        </h4>
                                        <h6 className="title">
                                            THRASS is designed to assist with the ‘word level’ component of literacy. The work that you do with 
                                            THRASS should be continually reinforced by regular reading sessions with a variety of content.
                                        </h6>
                                    </div>
                                    <p>
                                        Research has shown that students with a more comprehensive vocabulary and a greater understanding of the 
                                        meanings of words will more successfully apply words to memory for spelling and are more competent in 
                                        comprehension and writing.
                                    </p>
                                    <p>
                                        Spelling must be practiced – writing words in context to show meaning is a vital part of a child’s 
                                        spelling development. The spelling lists that children will work on at school will be compiled by the 
                                        classroom teacher and will contain words from all subjects focusing on relevant themes. The words will 
                                        be relevant to their everyday learning, not just an abstract list of words from a commercially produced 
                                        spelling book. The teacher will focus on word meaning, the phonetic structure of the word, links to other 
                                        words with similar structure and the grammatical use of the word – plurals and tenses.
                                    </p>
                                    <p>
                                        It is important that children learn the THRASSCHART, so that they are able to use the phonic information 
                                        to decode (read) and encode (spell) words. Having this knowledge will allow them to develop skills in 
                                        reading, spelling, writing and reading comprehension enabling them to reach their full potential in literacy 
                                        learning.
                                    </p>
                                    <h6 className="title">Parents can play an important role in supporting the work of the classroom teacher.</h6>
                                    <p>
                                        By learning and referring to the THRASS resources and terminology in the home, parents can consolidate 
                                        classroom learning and avoid confusing their children with conflicting information.
                                    </p>
                                    <p>
                                        Talking with your children about the meaning of words from the relevant classroom themes, using dictionaries 
                                        and looking at the grammatical changes in words is far more important than ‘rote’ learning a word for testing 
                                        at the end of the week. The more the learner can see the word in context, understand the meaning, make phonic
                                        links and practice writing words in a fun and meaningful way, the more competent and adventurous a speller 
                                        they will become. You will also be building vocabulary for future learning.
                                    </p>
                                    <p>
                                        Parents are also encouraged to purchase basic support materials for home use.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <NewsLetter />             
            </div>
        </>
    )
}
export default UsingThrass;