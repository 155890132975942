import PageTitle from '../../layouts/PageTitle';
import NewsLetter from '../../components/NewsLetter';
import thrassimage14 from '../../assets/images/thrassimage14.jpg'
import thrassimage15 from '../../assets/images/thrassimage15.jpg'
import thrassimage16 from '../../assets/images/thrassimage16.jpg'

function TheEnglishPhonicsChart() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="The English Phonics Chart" />
        <section className="content-inner overlay-white-middle">
          <div className="container">
            <div className="row about-style1 align-items-center">
              <div className="col-lg-12 m-b30 aos-item">
                <div className="about-content px-lg-4">
                  <h6 className="title">
                    The English Phonics Chart
                  </h6>

                  <p>
                    The focus of THRASS is a large, colourful ENGLISH PHONICS CHART, commonly known as the THRASS Chart. Here the 44 English sounds are clearly presented with 44 sound boxes and 120 pictures, keywords and keyspellings (spelling choices). The 24 consonants are shown in blue and the 20 vowels in red.
                  </p>

                  <img src={thrassimage14} alt="Thrass Chart" className="center" />

                  <p>
                    "b" Phoneme Box (Sound Box)
                  </p>

                  <img src={thrassimage15} alt="Thrass Chart" className="center" />

                  <p>
                    "c" Phoneme Box (Sound Box)</p>

                  <p>
                    The Phoneme Box
                  </p>

                  <img src={thrassimage16} alt="The Phoneme Box" className="center" />

                  <p>
                    Pictures
                  </p>

                  <p>
                    120 pictures are grouped together in phoneme boxes (sound houses), according to the phonemes/sounds they represent. Each picture has a related keyword and keyspelling/spelling choice.
                  </p>

                  <p>
                    Keywords
                  </p>

                  <p>
                    There are 120 keywords printed under the 120 matching pictures.
                  </p>

                  <p>
                    Keygraphemes
                  </p>

                  <p>
                    There are 120 keyspellings or spelling choices to match each picture.
                  </p>

                  <p>
                    Together with the teacher, the children explore the different sound boxes (houses) and learn about the pictures that are grouped together in the boxes as they have a common sound in their keywords. They learn about the different letters that make up these keyspellings and apply this knowledge to reading and spelling activities.
                  </p>

                  <p>
                    Through the different activities that have been carefully structured in the weekly lesson plans found in the Teacher’s Guides, the teacher introduces and consolidates the childrens’ learning experiences by using posters, phoneme songs, raps, picture cards, phonic readers, games and activities found in the classroom workbooks and homework books.
                  </p>

                  <p>
                    Through the different activities that have been carefully structured in the weekly lesson plans found in the Teacher’s Guides, the teacher introduces and consolidates the childrens’ learning experiences by using posters, phoneme songs, raps, picture cards, phonic readers, games and activities found in the classroom workbooks and homework books.
                  </p>

                  <p>
                    <a href="https://thrassafrica.co.za/english-phonics-readers">
                      Shop English Phonics Readers
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NewsLetter />
      </div>
    </>
  )
}
export default TheEnglishPhonicsChart;