export const MenuListArray2 = [
   
    {
        title: 'Home Language',
        to: '/home-language',
        classsChange: 'sub-menu-down',		
        content: [
            {
                title: "Teacher's Guides",
                to: '/home-language/hl-teachers-guides',
            },
            {
                title: "Learner's Phonics Workbooks",
                to: '/home-language/hl-learners-phonics-workbooks',
            },
            {
                title: "My Word Power Phonics Books",
                to: '/home-language/my-word-power-phonics-books',
            },
        ],
    },
    {
        title: 'First Additional Language',
        to: '/first-additional-language',
        classsChange: 'sub-menu-down',		
        content: [
            {
                title: "Teacher's Guides",
                to: '/first-additional-language/fal-teachers-guides',
            },
            {
                title: "Learner's Phonics Workbooks",
                to: '/first-additional-language/fal-learners-phonics-workbooks',
            },
        ],
    },
    {
        title: 'English Phonics Readers',
        to: '/english-phonics-readers',
        classsChange: 'sub-menu-down',		
        content: [
            {
                title: "Reader Sets",
                to: '/english-phonics-readers/reader-sets',
            },
            {
                title: "Individual Readers – Level 1",
                to: '/english-phonics-readers/individual-readers-level-1',
            },
            {
                title: "Individual Readers – Level 2",
                to: '/english-phonics-readers/individual-readers-level-2',
            },
            {
                title: "Individual Readers – Level 3",
                to: '/english-phonics-readers/individual-readers-level-3',
            },
        ],
    },
    {   
        title: 'Tiles & Cards',	
        to: '/tiles-cards',
    },
    {   
        title: 'Charts',	
        to: '/charts',
    },
    {   
        title: 'Sing-a-long',	
        to: '/sing-a-long',
    },
    {   
        title: 'Interactive',	
        to: '/interactive',
    },
    {   
        title: 'Gallery',	
        to: '/how-thrass-works/thrass-gallery',
    },
    {   
        title: 'Additional Books',	
        to: '/additional-books',
    },
    {
        title: 'About Us',
        classsChange: 'sub-menu-down',        
        content: [            
            {
                title: 'What is THRASS?',
                to: '/what-is-thrass',
            },
            {
                title: 'Using THRASS',
                to: '/using-thrass',
            },
            {
                title: "Teaching THRASS",
                to: '/teaching-thrass',
            },
            {
                title: 'THRASS Resources',
                to: '/thrass-resources',
            },
            {
                title: 'THRASS in South Africa',
                to: '/thrass-in-south-africa',
            },
            {
                title: 'How to Order',
                to: '/how-to-order',
            },
         
        ],
    },
    {
        title: 'Contact Us',	
        to: '/contact-us',
    },
]