import Backdrop from '@mui/material/Backdrop';
import { useEffect, useState } from 'react';
import { Player } from 'video-react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { BookService } from '../../../services/BookService';

const bookService = new BookService();

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function PlayVideoModal({open, handleClose, book}) {
  const [imageUrl, setImageUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    setImageUrl(bookService.getFrontImage(book));
    setVideoUrl(bookService.getVideo(book));
  }, [book])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Player
            playsInline
            poster={imageUrl}
            src={videoUrl}
          />
        </Box>
      </Fade>
    </Modal>
  );
}

export default PlayVideoModal;