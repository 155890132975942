import { BaseService } from "./BaseService";

export class CategoryService extends BaseService {
  constructor() {
    super('Categories')
  }

  async getCategoryBySlug(id, params) {
    return this.httpService.get(`GetBySlug/${id}?${params}`);
  }

  async getPaginationInfo(id, take) {
    return this.httpService.get(`GetPaginationInfo/${id}/${take}`);
  }
}