import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import RelatedBooksSlider from './RelatedBooksSlider';
import { formatCurrency } from '../../utilities/formatCurrency';
import { SetService } from '../../services/SetService';
import SubscriptionOption from './SubscriptionOption';
import CartItemQuantity from '../cart/CartItemQuantity';
import { useLocation } from '../../context/LocationContext';
import Loading from 'react-fullscreen-loading';
const setService = new SetService();

function SetDetail() {
  const [relatedBooks, setRelatedBooks] = useState([]);
  const [set, setSet] = useState();
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [frontImage, setFrontImage] = useState('');
  const { id } = useParams();
  const { getUrlParams } = useLocation()

  const getSet = useCallback(async () => {
    const response = await setService.getBySlug(id, getUrlParams());
    setSet(response.data);
    setFrontImage(`${setService.httpService.baseUrl}${response.data.frontImage}`);
  }, [getUrlParams, id])

  const getSetSubscriptionPlans = useCallback(async () => {
    const response = await setService.getSubscriptionPlans(id);
    setSubscriptionPlans(response.data);
  }, [id])

  const getRelatedBooks = useCallback(async () => {
    // var response = await setService.getRelatedBooks(id, 6);
    // setRelatedBooks(response.data);
    setRelatedBooks([]);
  }, [])

  useEffect(() => {
    getSet();
    getRelatedBooks();
    getSetSubscriptionPlans();
  }, [getSet, getRelatedBooks,getSetSubscriptionPlans, id]);

  return (
    <>
      {set == null
        ? <Loading loading  className="loader" loaderColor="#3498db" />
        : <div className="page-content bg-grey">
          <section className="content-inner-1">
            <div className="container">
              <div className="row book-grid-row style-4 m-b60">
                <div className="col">
                  <div className="dz-box">
                    <div className="dz-media">
                      <img src={frontImage ?? `${setService.httpService.baseUrl}${set.frontImage}`} alt={set.name} />
                    </div>
                    <div className="dz-content">
                      <div className="dz-body">
                        <div className="dz-header">
                          <h3 className="title">{set?.name}</h3>
                          <div className="shop-item-rating">
                            <div className="d-lg-flex d-sm-inline-flex d-flex align-items-center">
                              <h6 className="m-b3">{set?.code}</h6>
                            </div>
                          </div>
                        </div>
                        <div className="widget">
                          <div className='shipping-addresses'>
                            {subscriptionPlans.map((plan, i) => {
                              const data = { ...plan, frontImage: set?.frontImage } 
                              return (<SubscriptionOption key={i} plan={data} /> ) })}
                            <div>
                              <label htmlFor='control_01'>
                                <div className='row'>
                                  <div className="col-md-6">
                                    <div className="buy-info">
                                      <h5>{formatCurrency(set?.price * 1.15)}</h5>
                                      <p>Buy printed copies of the books in this set.</p>
                                    </div>
                                  </div>
                                  <div className='col-md-6'>
                                    <div className="buy-option">
                                      <CartItemQuantity id={id} type={2} book={set} />
                                    </div>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className="book-footer">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(relatedBooks.length > 0) && 
              <div className="container">
                <div className="section-head text-center">
                  <h2 className="title">Related Books</h2>
                  <RelatedBooksSlider books={relatedBooks} />
                </div>
              </div>
            }
          </section>
        </div>
      }
    </>
  )
}
export default SetDetail;