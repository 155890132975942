import { BaseService } from "./BaseService";

export class ContactFormService extends BaseService {
  constructor() {
    super('ContactForms')
  }
  
  async sendContactFormEmail(fullName, email, phone,location,organisation, message, params) {
    let payload = { fullName, email, phone,location,organisation, message }
    return await this.httpService.post(`SendContactFormEmail?${params}`, payload);
  }
}