import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { BookService } from '../../services/BookService';
import RelatedBooksSlider from './RelatedBooksSlider';
import { formatCurrency } from '../../utilities/formatCurrency';
import CartItemQuantity from '../cart/CartItemQuantity';
import BackToCategory from '../BackToCategory';
import { CategoryService } from '../../services/CategoryService';
import { useLocation } from '../../context/LocationContext';
import Loading from 'react-fullscreen-loading';
import BookPdfPreview from './BookPdfPreview';
import BookVideoPdfPreviewSelector from './BookVideoPdfPreviewSelector';
import BookVideoPreview from './BookVideoPreview';

const bookService = new BookService();
const categoryService = new CategoryService();

function BookDetail(props) {
  const [relatedBooks, setRelatedBooks] = useState([]);
  const [book, setBook] = useState();
  const [file, setFile] = useState();
  const [frontImage, setFrontImage] = useState('');
  const [category, setCategory] = useState();
  const { id } = useParams();
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const { getUrlParams } = useLocation()

  const getCategory = useCallback(async (categoryId) => {
    var response = await categoryService.get(categoryId);
    setCategory(response.data);
  }, []);

  
  const getBookSubscriptionPlans = useCallback(async () => {
    const response = await bookService.getSubscriptionPlans(id);
    setSubscriptionPlans(response.data);
  }, [id])

  const getBook = useCallback(async () => {
    const response = await bookService.getBySlug(id, getUrlParams());

    setBook(response.data);
    initFiles(response.data);
    getCategory(response.data.categoryId);

    if (response.data.hasVideo) getBookSubscriptionPlans()
  }, [id, getUrlParams, getCategory, getBookSubscriptionPlans])

  const getRelatedBooks = useCallback(async () => { 
    var response = await bookService.getRelatedBooks(id, 6);   
    setRelatedBooks(response.data);
  }, [id])

  useEffect(() => {
    getBook();
    getRelatedBooks();
  }, [getBook, getRelatedBooks, id]);

  const initFiles = (bk) => {
    setFrontImage(`${bookService.httpService.baseUrl}${bk.frontImage}`);
    const previewFile = bk.previewFile?.split(String.fromCharCode(92))?.join('/');   
    setFile(`${window.location.origin}/${previewFile}`);
         
   
    const viewer = document.getElementById('pdfContainer')?.ej2_instances[0];
    viewer?.load(previewFile, null);
  }

  return (
    <>
      {book == null
        ? <Loading loading  className="loader" loaderColor="#3498db" />
        : <div className="page-content bg-grey">
          <section className="content-inner-1">
            <div className="container">
              <div className="row book-grid-row style-4 m-b60">
                <div className="col">
                  <BackToCategory category={category}/>
                  <div className="dz-box">
                    <div className="dz-media">
                      <div className="dz-header">
                        <h3 className="title">{book?.title}</h3>
                        <div className="shop-item-rating">
                          <div className="d-lg-flex d-sm-inline-flex d-flex align-items-center">
                            <h6 className="m-b3">{book?.code}</h6>
                            <div className="bookmark-btn style-1 d-none d-sm-block">
                            <h6 className="m-b3 pl25">ISBN: {book?.isbn}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <img src={frontImage ?? `${bookService.httpService.baseUrl}${book.frontImage}`} alt={book.title} />
                      <div className="shoppingWidget mt-2 row">
                        <div className='product-item-img' >
                          <a href="#/" onClick={() => setFrontImage(`${bookService.httpService.baseUrl}${book.frontImage}`)}> 
                            <img src={`${bookService.httpService.baseUrl}${book.frontImage}`} alt={book.title} />
                          </a>
                        </div>
                        {(book.backImage) && 
                          <div className='product-item-img'>
                            <a href="#/" onClick={() => setFrontImage(`${bookService.httpService.baseUrl}${book.backImage}`)}>  
                              <img src={`${bookService.httpService.baseUrl}${book.backImage}`} alt={book.title} /> 
                            </a>
                          </div>
                        }
                        <div className="product-item-img">
                          <div className="price  mt-4">
                            <h5>{formatCurrency(book?.price * 1.15)}</h5>
                          </div>
                        </div>
                      </div>
                      {(!book.hasVideo) && 
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className="book-footer">
                              <div className="product-num">
                                <CartItemQuantity id={id} type={1} book={book} />
                              </div>
                            </div>
                          </div>
                        </div>
                      } 
                    </div>
                    <div className="dz-content">
                      <div className="dz-body">
                        {(file && book.previewFile && book.videoPreviewFile) 
                        ? <BookVideoPdfPreviewSelector subscriptionPlans={subscriptionPlans} book={book} file={file} id={id} />
                        : <>
                          {(file && book.previewFile) && <BookPdfPreview file={file} book={book} id={id} /> }
                          {(book.videoPreviewFile) && <BookVideoPreview subscriptionPlans={subscriptionPlans} book={book} id={id} /> }
                        </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(relatedBooks.length > 0) && 
              <div className="container">
                <div className="section-head text-center">
                  <h2 className="title">Related Books</h2>
                  <RelatedBooksSlider books={relatedBooks} />
                </div>
              </div>
            }
          </section>
        </div>
      }
    </>
  )
}
export default BookDetail;