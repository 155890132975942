import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function AddUserModal({open, handleOpen, handleClose, handleSubmit, setEmail, email, errMsg, successMsg}) {
  return (
    <>
      <a href='#' className='float-end btn btn-primary' onClick={handleOpen}>
        Add User
      </a>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit} className={`col-12`}>
              <h4 className="text-secondary">Add User</h4>
              <p className="font-weight-600">Please enter the email address of the user to add them to the subscription.</p>
              <p className={errMsg ? "errmsg" : "offscreen"}>{errMsg}</p>
              <p className={successMsg ? "succesmsg" : "offscreen"}>{successMsg}</p>
              <div className="mb-4">
                <label className="label-title">E-mail Address</label>
                <input className="form-control" placeholder="User's email address" type="email"
                  id="email" autoComplete="off" onChange={(e) => setEmail(e.target.value)} value={email} required />
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 mb-12 mt-3">
                  <button type="submit" className="btn btn-primary btnhover">Invite</button>
                </div>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}
export default AddUserModal;