import { useState, useEffect, useContext, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { UserContext } from '../../context/UserContext';
import useIsMounted from '../../hooks/useIsMounted';
import { AddressService } from '../../services/AddressService';
import { CountryService } from '../../services/CountryService';

const countryService = new CountryService();
const addressService = new AddressService();

function AddShippingAddress({getUserAddresses}) {
  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ phone, setPhone ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ company, setCompany ] = useState('');
  const [ streetAddress, setStreetAddress ] = useState('');
  const [ apartment, setApartment ] = useState('');
  const [ city, setCity ] = useState('');
  const [ province, setProvince ] = useState('');
  const [ postalCode, setPostalCode ] = useState('');
  const [ countries, setCountries ] = useState([]);
  const [ countryId, setCountryId ] = useState(111);
  const { auth } = useContext(UserContext)
  const isMounted = useIsMounted()
  const [disableAddressButton, setDisabledAddress] = useState(false);

  const getCountries = useCallback(async () => {
    const response = await countryService.getAll()
    isMounted() && setCountries(response.data)
  }, [isMounted])

  useEffect(() => {
    if(auth !=null){
    setFirstName(auth?.user?.firstName)
    setLastName(auth?.user?.lastName)
    setPhone(auth?.user?.phone )
    setEmail(auth?.user?.email)
    }
    getCountries()
  }, [auth, getCountries])

  const getSelectedCountry = (event) => 
   
   {
   
     event.preventDefault();
     var country =event.target.selectedOptions[0].text;
     var allowedCountries =process.env.REACT_APP_ALLOWED_COUNTRIES;
     setDisabledAddress(true);
     if( allowedCountries.indexOf(country)>=0){
       setDisabledAddress(false);
     }

     setCountryId(event.target.value)
   
   };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const payload = {
        firstName,
        lastName,
        phone,
        email,
        company,
        streetAddress,
        apartment,
        city,
        province,
        postalCode,
        countryId,
        userId: auth.user.id,
      }

      await addressService.create(payload)
      getUserAddresses()
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error?.response?.data,
        icon: 'error',
      });
    };

   
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="widget">
        <h4 className="widget-title">Add a new Address</h4>
        <p>Please enter your shipping address for this checkout.</p>
        <div className="row">
          <div className="form-group col-md-6">
            <input type="text" className="form-control" placeholder='First Name' required maxLength="50"
              value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
          </div>
          <div className="form-group col-md-6">
            <input type="text" className="form-control" placeholder='Last Name' required maxLength="50"
              value={lastName} onChange={(e) => setLastName(e.target.value)}/>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <input type="email" className="form-control" placeholder='Email Address' required maxLength="150"
              value={email} onChange={(e) => setEmail(e.target.value)}/>
          </div>
          <div className="form-group col-md-6">
            <input type="phone" className="form-control" placeholder='Phone Number (+2771000000)' required maxLength="20"
              value={phone} onChange={(e) => setPhone(e.target.value)}/>
          </div>
        </div>
        <div className="form-group col-md-12">
          <input type="text" className="form-control" placeholder='Company Name (optional)'  maxLength="100"
            value={company} onChange={(e) => setCompany(e.target.value)}/>
        </div>
        <div className="form-group col-md-12">
          <input type="text" className="form-control" placeholder='Street Address' required  maxLength="100"
            value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)}/>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <input type="text" className="form-control" placeholder='Apartment, suite, unit, etc.' required maxLength="50"
              value={apartment} onChange={(e) => setApartment(e.target.value)}/>
          </div>
          <div className="form-group col-md-6">
            <input type="text" className="form-control" placeholder='City/Town' required maxLength="50"
              value={city} onChange={(e) => setCity(e.target.value)}/>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <input type="text" className="form-control" placeholder='State/Province/County' required maxLength="20"
              value={province} onChange={(e) => setProvince(e.target.value)}/>
          </div>
          <div className="form-group col-md-6">
            <input type="text" className="form-control" placeholder='Postal/Zip Code' required maxLength="6"
              value={postalCode} onChange={(e) => setPostalCode(e.target.value)}/>
          </div>
        </div>
        <div className="form-group">
          <Form.Select value={countryId} onChange={getSelectedCountry}>
            {countries.map((country, index) => {return(<option value={country.id} key={index}>{country.name}</option>)})}
          </Form.Select>
        </div>
        {/* <div className='form-group'>
          <Form.Check 
            type="switch"
            id="custom-switch"
            label="Use this address as the billing address as well"
            checked={useSameAddress}
            readOnly
            onClick={() => {setUseSameAddress(!useSameAddress)}}
          />
        </div>
        <div className="form-group">
          <textarea className="form-control" placeholder="Notes about your order, e.g. special notes for delivery"
            value={deliveryNotes} onChange={(e) => setDeliveryNotes(e.target.value)} maxLength="500"></textarea>
        </div> */}
      { disableAddressButton && <div className='backgroundWarning' >
          <h6>Unable to deliver/subscribe</h6>
          <p>Only Allowed countries are : South Africa, Lesotho, Swaziland, Namibia and  Botswana </p>
        </div>
        }
        <button className="btn btn-primary btnhover mb-3" type="submit" data-bs-toggle="collapse" data-bs-target="#create-an-account" disabled={disableAddressButton}>
          Add This Address <i className ='fa fa-plus' aria-hidden="true"></i>
        </button>
      </div>
    </form>
  )
}

export default AddShippingAddress;