import OrderBookImageLink from "../orders/OrderBookImageLink";
import VideoPlayer from "../subscriptions/VideoPlayer";



function SetBooksTable({ books  }) {
 debugger
  const styleborderBottom = {
    
  
    borderBottom: '1px solid #202020',
    padding:'10px 0',
    borderTop: '1px solid #202020',
    
  };
  
  return (
    <div className="row mt-3"  >
      <div className="shop-bx-title clearfix"><h5 class="text-uppercase">Phonics</h5></div>

      {books.map((book, i) => ( 
        <div className='row ' style={styleborderBottom}  key={i}>
            <div className='col-md-3'>
            <OrderBookImageLink book={book} title={book.title} />
            {book.title}
             </div>
             <div className='col-md-8'>
             <VideoPlayer book={book} />
                   
             </div>

      </div>
            
      ))}
     
    </div>
  );
}

export default SetBooksTable;