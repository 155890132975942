import { useRef, useState, useEffect, useContext } from "react";
import PageTitle from '../../layouts/PageTitle';
import { AuthService } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import { withSwal } from 'react-sweetalert2';
import Swal from "sweetalert2";
import Loading from 'react-fullscreen-loading';
import { UserContext } from "../../context/UserContext";
import Constants from "../../Constants/Contants";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const authService = new AuthService()

function ChangePassword() {
  const errRef = useRef();
  const { auth } = useContext(UserContext)
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [matchPwd, setMatchPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isCaptchaTicked, setIsCaptchaTicked] = useState(null);
  
  const handleCaptchaChange = (value) => {
    setIsCaptchaTicked(value !== null);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setShowLoader(true);
      captchaRef.current.getValue();
      captchaRef.current.reset();
      
      await authService.changePassword(auth.user.id, pwd)
      setShowLoader(false);
      Swal.fire({
        title: 'Success',
        text: 'Password reset successful',
        icon: 'success',
      }).then(
        navigate(`/dashboard`)
        
      )
    } catch (error) {
      setShowLoader(false);
      Swal.fire({
        title: 'Error Password Change',
        text: '' + error?.response?.data.statusText,
        icon: 'error',
      });
      
  
      setErrMsg(error?.response?.data.statusText);
    }
  }

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
  }, [pwd])

  useEffect(() => {
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd])

  return (
    <>
      <div className="page-content">
        {showLoader? ( <Loading loading  className="loader" loaderColor="#3498db" />):(<></>)}           
        <PageTitle parentPage="Profile" childPage="Change Password" />
        <section className="content-inner shop-account">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="login-area">
                  <form onSubmit={handleSubmit}>
                    <h4 className="text-secondary">Change Password</h4>
                    <p className="font-weight-600">Type in the new password here.</p>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <div className="mb-4">
                      <label className="label-title">Password *</label>
                      <input className="form-control " placeholder="Type New Password" type="password"
                        id="password" onChange={(e) => setPwd(e.target.value)} value={pwd}
                        required onFocus={() => setPwdFocus(true)} onBlur={() => setPwdFocus(false)} />
                      <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                        8 to 24 characters.<br />
                        Must include uppercase and lowercase letters, a number and a special character.<br />
                        Allowed special characters:
                        <span aria-label="exclamation mark">!</span>
                        <span aria-label="at symbol">@</span>
                        <span aria-label="hashtag">#</span>
                        <span aria-label="dollar sign">$</span>
                        <span aria-label="percent">%</span>
                      </p>
                    </div>
                    <div className="mb-4">
                      <label className="label-title">Confirm Password *</label>
                      <input className="form-control " placeholder="Confirm New Password" type="password"
                        id="confirm_pwd" onChange={(e) => setMatchPwd(e.target.value)}
                        value={matchPwd} required onFocus={() => setMatchFocus(true)}
                        onBlur={() => setMatchFocus(false)} />
                      <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                        Must match the first password input field.
                      </p>
                    </div>
                    <div className='mb-3'>
                      <ReCAPTCHA
                        sitekey={Constants.ReCAPTCHA_SiteKey}
                        onChange={handleCaptchaChange}
                        ref={captchaRef}
                      />
                    </div>
                    <div className="text-left">
                      <button type="submit" className="btn btn-primary btnhover w-100 me-2" disabled={!isCaptchaTicked || !validMatch}>
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default ChangePassword;