import { Link } from "react-router-dom";
import { BookService } from '../../../services/BookService';

const bookService = new BookService();

function OrderBookImageLink({ book, title }) {
  return (
    
      <img src={bookService.getFrontImage(book)} alt={title}  style={{ width: '100%', height: 'auto' }} />
   
  );
}

export default OrderBookImageLink;