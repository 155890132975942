import { useLocalStorage } from "../hooks/useLocalStorage";
import { UserContext } from './UserContext'

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useLocalStorage('logged-in-user', {});

  return (
    <UserContext.Provider value={{ auth, setAuth }}>
      {children}
    </UserContext.Provider>
  )
}