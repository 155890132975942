import { createContext, ReactNode, useContext, useState } from "react"
import { ShoppingCart } from "../components/Shop/ShoppingCart"
import { useLocalStorage } from "../hooks/useLocalStorage"

type ShoppingCartProviderProps = {
  children: ReactNode
}

type CartItem = {
  id: string
  book?: any
  type: number
  quantity: number
}

type ShoppingCartContext = {
  openCart: () => void
  closeCart: () => void
  getItemTotal: (id: string) => number
  getItemQuantity: (id: string) => number
  getVatTotal: () => number
  getVoucherAmount: () => number
  increaseCartQuantity: (id: string, type: number, book?: any) => void
  decreaseCartQuantity: (id: string) => void
  removeFromCart: (id: string) => void
  clear: () => void
  cartTotal: number
  orderTotal: number
  vatPercentage: number
  voucherCode: string
  shippingAmount: number
  cartQuantity: number
  cartItems: CartItem[],
  getSubscribedItemQuantity: (id: string) => number
}

const ShoppingCartContext = createContext({} as ShoppingCartContext)

export function useShoppingCart() {
  return useContext(ShoppingCartContext)
}

export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
  const [cartItems, setCartItems] = useLocalStorage<CartItem[]>("shopping-cart",[])
  const [isOpen, setIsOpen] = useState(false)
  const openCart = () => setIsOpen(true)
  const closeCart = () => setIsOpen(false)

  const cartTotal = cartItems.reduce(
    (total, item) => (item.quantity * item.book?.price) + total,
    0
  )

  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  )
  
  const shippingAmount = cartItems.filter(x => x.type === 1 ||  x.type ===2 ).length  >0?105:0 ;

  const vatPercentage = 15;

  const voucherCode = '';

  const orderTotal = cartTotal + shippingAmount - getVoucherAmount() + getVatTotal()

  function clear() {
    setCartItems([])
  }

  function getItemTotal(id: string) {
    const item = cartItems.find(x => x.id === id);
    const quantity = item?.quantity || 0
    const price = item?.book?.price || 0

    return price * quantity;
  }

  function getItemQuantity(id: string) {
    return cartItems.find(item => item.id === id)?.quantity || 0
  }

  function getSubscribedItemQuantity(id: string) {
    return cartItems.find(item => item.id == id)?.quantity || 0
  }

  function getVatTotal() {
    return (cartTotal + shippingAmount - getVoucherAmount()) * vatPercentage / 100
  }

  function getVoucherAmount() {
    if (!voucherCode) return 0;

    return 0;
  }

  function increaseCartQuantity(id: string, type: number, book?: any) {
    setCartItems(currItems => {
      if (currItems.find(item => item.id === id) == null) {
        return [...currItems, { id: `${id}`, quantity: 1, book, type }]
      } else {
        return currItems.map(item => {
          if (item.id === `${id}` && item.type === type) {
            return { ...item, quantity: item.quantity + 1, book: item.book, type: item.type }
          } else {
            return item
          }
        })
      }
    })
  }

  function decreaseCartQuantity(id: string) {
    setCartItems(currItems => {
      if (currItems.find(item => item.id === id)?.quantity === 1) {
        return currItems.filter(item => item.id !== id)
      } else {
        return currItems.map(item => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity - 1 }
          } else {
            return item
          }
        })
      }
    })
  }

  function removeFromCart(id: string) {
    setCartItems(currItems => {
      return currItems.filter(item => item.id !== id)
    })
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        getItemTotal,
        getItemQuantity,
        increaseCartQuantity,
        decreaseCartQuantity,
        removeFromCart,
        openCart,
        closeCart,
        getVatTotal,
        getVoucherAmount,
        clear,
        orderTotal,
        vatPercentage,
        shippingAmount,
        voucherCode,
        cartItems,
        cartTotal,
        cartQuantity,
        getSubscribedItemQuantity
      }}
    >
      {children}
      <ShoppingCart isOpen={isOpen} />
    </ShoppingCartContext.Provider>
  )
}