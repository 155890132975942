import OrderBookImageLink from './OrderBookImageLink';

function OrderBookImage({ orderItem }) {
  return (
    <>
      {(orderItem.book != null) && <OrderBookImageLink book={orderItem.book} title={orderItem.book.title} />}
      {(orderItem.set != null) && <OrderBookImageLink book={orderItem.set} title={orderItem.set.name} />}
      {(orderItem.subscriptionPlan?.book != null) && <OrderBookImageLink book={orderItem.subscriptionPlan?.book} title={orderItem.subscriptionPlan?.book.title} />}
      {(orderItem.subscriptionPlan?.set != null) && <OrderBookImageLink book={orderItem.subscriptionPlan?.set} title={orderItem.subscriptionPlan?.set.name} />}
    </>
  );
}

export default OrderBookImage;