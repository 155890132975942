import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";
import SliderBook from './SliderBook';

export default function RelatedBooksSlider({ books }) {
  const [slidesPerView, setSlidesPerView] = useState(5);

  useEffect(() => {
    setSlidesPerView(books?.length ?? 0)
  }, [books])

  return (
    <>
      <Swiper className="swiper-container swiper-two"
        speed={1500}
        parallax= {true}
        slidesPerView={slidesPerView}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2500,
        }}
        modules={[Autoplay]}
        breakpoints={{
          1200: {
            slidesPerView: 5,
          },
          1024: {
            slidesPerView: 4,
          },
          991: {
            slidesPerView: 3,
          },
          767: {
            slidesPerView: 3,
            centeredSlides: true,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 15,
            centeredSlides: true,
          },
        }}
      >
        {books.map((book, i) => (
          <SwiperSlide key={i}>
            <SliderBook book={book} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}