import { BaseService } from "./BaseService";

export class OrderService extends BaseService {
  constructor() {
    super('Orders')
  }
  
  async getByUid(id) {
    return this.httpService.get(`GetByUid/${id}`);
  }
  
  async getOrderItemsByUid(id) {
    return this.httpService.get(`GetOrderItemsByUid/${id}`);
  }

  getPayFastUrl(order, email) {
    let url = this.httpService.baseUrl + `api/Payments/MakePayment?reference=${order.uid}&amount=${order.balance}&email=${email}`

    return url;
  }

  getStatus(order) {
    switch (order.status) {
      case 0:
        return 'New';
      case 1:
        return 'Paid';
      case 2:
        return 'Cancelled';
      case 3:
        return 'Received';
      case 4:
        return 'Courier';
      case 5:
        return 'Delivered';
      default:
        return 'Unknown';
    }
  }

  async getUserOrders(userId) {
    return await this.get(`GetUserOrders/${userId}`);
  }
}