import { useRef, useState, useEffect, useCallback } from "react";
import { Link, useParams } from 'react-router-dom';
import PageTitle from './../layouts/PageTitle';
import { AuthService } from "../services/AuthService";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import { withSwal } from 'react-sweetalert2';
import Swal from "sweetalert2";
import { Puff } from 'react-loader-spinner'
import { UserSubscriptionService } from "../services/UserSubscriptionService";
import Constants from "../Constants/Contants";

const EMAIL_REGEX = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const auth = new AuthService()
const userSubscriptionService = new UserSubscriptionService();

function AccepGrouptInvitation(props) {
  const errRef = useRef();
  const {id} = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const captchaRef = useRef(null);
  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [firstName, setFirstName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [lastName, setLastName] = useState('');
  const [invitation, setInvitation] = useState(null);

  const getInvitation = useCallback(async () => {
    var response = await userSubscriptionService.getInvitation(id);

    setInvitation(response.data)
    setEmail(response.data.emailAddress)
  }, [id]) 

  useEffect(() => {
    getInvitation();
  }, [getInvitation])

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email])

  const handleRecaptcha = (value) => {
    fetch('http://127.0.0.1:8000/recaptcha/', {
      method: 'POST',
      body: JSON.stringify({ 'captcha_value': value }),
      headers: { 'Content-Type': 'application/json' }
    })
      .then(res => res.json())
      .then(data => {
        //   setCaptchaResult(data.captcha.success)
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await auth.register(email, firstName, lastName, phoneNumber, '', pwd, id)
      Swal.fire({
        title: 'Success',
        text: 'You have successfully accepted the invitation. Please log in to access the resources.',
        icon: 'success',
      });
      navigate(`/login`)
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: '' + error?.response?.data,
        icon: 'error',
      });
      setErrMsg(error?.response?.data);
    }
  }

  return (
    <>
      {invitation && (
        <div className="page-content">
          <PageTitle parentPage="Shop" childPage="Accept Invitation" />
          <section className="content-inner shop-account">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 mb-4">
                  <div className="login-area">
                    <form onSubmit={handleSubmit}>
                      <h4 className="text-secondary">Accept Group Invitation</h4>
                      <p className="font-weight-600">
                        Please complete the form below to accept {invitation.userSubscription.ownerName}'s invitation and create your account.
                      </p>
                      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                      <div className="mb-4">
                        <label className="label-title">Email address *</label>
                        <input className="form-control" placeholder="Your Email address" type="email"
                          id="email" autoComplete="off" onChange={(e) => setEmail(e.target.value)}
                          value={email} required onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)} disabled />
                        <p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                          4 to 24 characters.<br />
                          Must begin with a letter.<br />
                          Letters, numbers, underscores, hyphens allowed.
                        </p>
                      </div>
                      <div className="mb-4">
                        <label className="label-title">First Name *</label>
                        <input className="form-control" placeholder="Your First Name" type="text"
                          id="firstName" onChange={(e) => setFirstName(e.target.value)} value={firstName}
                          required />
                      </div>
                      <div className="mb-4">
                        <label className="label-title">Surname *</label>
                        <input className="form-control" placeholder="Your Surname" type="text"
                          id="lastName" onChange={(e) => setLastName(e.target.value)} value={lastName}
                          required />
                      </div>
                      <div className="mb-4">
                        <label className="label-title">Phone Number *</label>
                        <input className="form-control" placeholder="Phone Number" type="number"
                          id="phoneNumber" onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber}
                          required />
                      </div>

                      <div className="mb-4">
                        <label className="label-title">Password *</label>
                        <input className="form-control " placeholder="Type Password" type="password"
                          id="password" onChange={(e) => setPwd(e.target.value)} value={pwd}
                          required onFocus={() => setPwdFocus(true)} onBlur={() => setPwdFocus(false)} />
                        <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                          8 to 24 characters.<br />
                          Must include uppercase and lowercase letters, a number and a special character.<br />
                          Allowed special characters:
                          <span aria-label="exclamation mark">!</span>
                          <span aria-label="at symbol">@</span>
                          <span aria-label="hashtag">#</span>
                          <span aria-label="dollar sign">$</span>
                          <span aria-label="percent">%</span>
                        </p>
                      </div>

                      <div className="mb-4">
                        <label className="label-title">Confirm Password *</label>
                        <input className="form-control " placeholder="Type Password" type="password"
                          id="confirm_pwd" onChange={(e) => setMatchPwd(e.target.value)}
                          value={matchPwd} required onFocus={() => setMatchFocus(true)}
                          onBlur={() => setMatchFocus(false)} />
                        <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                          Must match the first password input field.
                        </p>
                      </div>

                      <div className="mb-5">
                        <small>Your personal data will be used to support your experience throughout this website,
                          to manage access to your account, and for other purposes described in our
                          <Link to={"privacy-policy"}>privacy policy</Link>.
                        </small>
                        <ReCAPTCHA
                          sitekey={Constants.ReCAPTCHA_SiteKey}
                          ref={captchaRef}
                        />
                      </div>

                      <div className="text-left">
                        <button type="submit" className="btn btn-primary btnhover w-100 me-2">
                          Accept Invitation
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  )
}
export default AccepGrouptInvitation;