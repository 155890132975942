import { BaseService } from "./BaseService";

export class DashboardService extends BaseService {
  constructor() {
    super('Dashboard')
  }

  async getUserDashboardTiles(id) {
    return this.httpService.get(`GetUserDashboardTiles/${id}`);
  }
}