import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { UserContext } from '../../context/UserContext';
import { AuthService } from '../../services/AuthService';
import LeftMenu from './LeftMenu';

const authService = new AuthService()

function MyProfile() {
  const { auth } = React.useContext(UserContext)
  const { setAuth } = useContext(UserContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setBillingAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [schoolCode, setSchoolCode] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [userType, setUserType] = useState(0);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await authService.updateProfile(auth.user.id, firstName, lastName, phoneNumber, schoolName)
      const data = { user: response.data, accessToken: response.data.token }

      setAuth(data);

      Swal.fire({
        title: 'Success',
        text: 'Your profile has been updated',
        icon: 'success',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: '' + error?.response?.data,
        icon: 'error',
      });
    }
  }

  useEffect(() => {
    if (auth?.user) {
      setFirstName(auth?.user?.firstName ?? '')
      setLastName(auth?.user?.lastName ?? '')
      setPhoneNumber(auth?.user?.phoneNumber ?? '')
      setBillingAddress(auth?.user?.address ?? '')
      setPostalCode(auth?.user?.postalCode ?? '')
      setCountry(auth?.user?.country ?? '')
      setProvince(auth?.user?.province ?? '')
      setCity(auth?.user?.city ?? '')
      setSchoolCode(auth?.user?.schoolCode ?? '')
      setSchoolName(auth?.user?.schoolName ?? '')
      setUserType(auth?.user?.userType ?? 0)
    } else {
      navigate(`/login`)
    }
  }, [auth, navigate])

  return (
    <>
      <div className="page-content bg-white">
        <div className="content-block">
          <section className="content-inner bg-white">
            <div className="container">
              <div className="row">
                <LeftMenu />
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="shop-bx shop-profile">
                    <div className="shop-bx-title clearfix">
                      <h5 className="text-uppercase">Basic Information</h5>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="mb-3">
                            <label htmlFor="firstName" className="form-label">First Name:</label>
                            <input className="form-control" placeholder="Your First Name" type="text"
                              onChange={(e) => setFirstName(e.target.value)} value={firstName}
                              id="firstName" name="firstName" maxLength="50" required />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="mb-3">
                            <label htmlFor="lastName" className="form-label">Last Name:</label>
                            <input className="form-control" placeholder="Your Surname" type="text"
                              onChange={(e) => setLastName(e.target.value)} value={lastName}
                              id="lastName" name="lastName" required />
                          </div>
                        </div>
                      </div>
                      <div className="row m-b30">
                        <div className="col-lg-6 col-md-6">
                          <div className="mb-3">
                            <label htmlFor="phoneNumber" className="form-label">Phone Number:</label>
                            <input className="form-control" placeholder="Your Phone Number" type="text"
                              onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber}
                              id="phoneNumber" name="phoneNumber" required />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email Address:</label>
                            <input className="form-control" placeholder="Your Email Address" type="email"
                              value={auth?.user?.email ?? ''} id="email" name="email" disabled />
                          </div>
                        </div>
                      </div>
                      {(userType === 2) && 
                        <>
                          <div className="shop-bx-title clearfix">
                            <h5 className="text-uppercase">School Details</h5>
                          </div>
                          <div className="row m-b30">
                            <div className="col-lg-6 col-md-6">
                              <div className="mb-3">
                                <label htmlFor="schoolName" className="form-label">School Name:</label>
                                <input className="form-control" placeholder="Your School Name" type="text"
                                  onChange={(e) => setSchoolName(e.target.value)} value={schoolName}
                                  id="schoolName" name="schoolName" maxLength="50" />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="mb-3">
                                <label htmlFor="schoolCode" className="form-label">School Code:</label>
                                <input className="form-control" placeholder="Your School Code" type="text"
                                  onChange={(e) => setSchoolCode(e.target.value)} value={schoolCode}
                                  id="schoolCode" name="schoolCode" maxLength="6" disabled />
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      {/* <div className="shop-bx-title clearfix">
                        <h5 className="text-uppercase">Contact Information</h5>
                      </div>
                      <div className="row">
                        <div className="col-lg-8 col-md-8">
                          <div className="mb-3">
                            <label htmlFor="billingAddress" className="form-label">Billing Address:</label>
                            <input className="form-control" placeholder="Your Billing Address" type="text"
                              onChange={(e) => setBillingAddress(e.target.value)} value={address}
                              id="billingAddress" name="billingAddress" maxLength="50" required />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="mb-3">
                            <label htmlFor="postalCode" className="form-label">Postal Code:</label>
                            <input className="form-control" placeholder="Your Postal Code" type="text"
                              onChange={(e) => setPostalCode(e.target.value)} value={postalCode}
                              id="postalCode" name="postalCode" required />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <div className="mb-3">
                            <label htmlFor="country" className="form-label">Country:</label>
                            <input className="form-control" placeholder="Your Country" type="text"
                              onChange={(e) => setCountry(e.target.value)} value={country}
                              id="country" name="country" maxLength="50" required />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="mb-3">
                            <label htmlFor="province" className="form-label">Province/State:</label>
                            <input className="form-control" placeholder="Your Province/State" type="text"
                              onChange={(e) => setProvince(e.target.value)} value={province}
                              id="province" name="province" maxLength="50" required />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="mb-3">
                            <label htmlFor="city" className="form-label">City/Town:</label>
                            <input className="form-control" placeholder="Your City/Town" type="text"
                              onChange={(e) => setCity(e.target.value)} value={city}
                              id="city" name="city" required />
                          </div>
                        </div>
                      </div> */}
                      <button className="btn btn-primary btnhover mt-2">
                        Update Profile
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
export default MyProfile;