import React, { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import LeftMenu from './LeftMenu';
import { UserContext } from '../../context/UserContext';
import DashboardTiles from './DashboardTiles';

function Dashboard() {
  const { auth } = useContext(UserContext)
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth?.user) {
      navigate(`/login`)
    }
  }, [auth, navigate])
  return (
    <>
      <div className="page-content bg-white">
        <div className="content-block">
          <section className="content-inner bg-white">
            <div className="container">
              <div className="row">
                <LeftMenu />
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className='row m-b30'>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h5>Welcome back, {auth.user.firstName}</h5>
                    </div>
                  </div>
                  {auth.user && (
                    <div className='row'>
                      <DashboardTiles user={auth.user}/>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
export default Dashboard;