import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom'
import { UserContext } from '../context/UserContext';

const UserRoutes = () => {
  const { auth } = React.useContext(UserContext)

  return (
    auth.user ? <Outlet/> : <Navigate to='/login' />
  )
}

export default UserRoutes;