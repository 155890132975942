import { createContext } from 'react';

class User {}

// UserContextState {
//   user: User,
//   setUser: React.Dispatch<React.SetStateAction<User>>;
// }

const initialState = {
  user: new User(),
  setUser: () => { }
}

export const UserContext = createContext(initialState);