import PageTitle from '../../layouts/PageTitle';
import NewsLetter from '../../components/NewsLetter';
import thrassimage1 from '../../assets/images/stories/Keystage-1.jpg'
import thrassimage2 from '../../assets/images/stories/Keystage-2.jpg'
import thrassimage3 from '../../assets/images/stories/Keystage-3.jpg'
import thrassimage4 from '../../assets/images/stories/Keystage-4.jpg'
import thrassimage5 from '../../assets/images/stories/Keystage-5.jpg'
import thrassimage6 from '../../assets/images/stories/Keystage-6.jpg'
import thrassimage7 from '../../assets/images/stories/Keystage-7.jpg'
import thrassimage8 from '../../assets/images/stories/Keystage-8.jpg'
import thrassimage9 from '../../assets/images/stories/Keystage-9.jpg'
import thrassimage10 from '../../assets/images/stories/Keystage-10.jpg'

function TheKeyStages() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="The Key Stages" />
        <section className="content-inner overlay-white-middle">
        <div className="container">
						<div className="row about-style1 align-items-center">
							<div className="col-lg-12 m-b30 aos-item">
								<div className="about-content px-lg-4">
									<h6 className="title">
                  The Key Stages
									</h6>
                 <br/>
                  <h6 >THRASS has <strong>10 KEY STAGES</strong> that help learners in understanding the building blocks of the English language.</h6>
                <p><strong>1.  Picture Location</strong></p>
                <p><img  src={thrassimage1} alt="Keystage-1" /></p>
                <h6 >Locate and name the 120 outline pictures.<strong> <br /></strong></h6>
                <p ><strong>2.  Letter Location</strong></p>
                <p><strong><img src={thrassimage2} alt="Keystage-2" /></strong></p>
                <h6 >Locate and name the 26 lower case letters and their capitals.<strong> <br /></strong></h6>
                <p ><strong>3.  Letter Formation</strong></p>
                <p><strong><img src={thrassimage3} alt="Keystage-3" /></strong></p>
                <h6 >Name and correctly form the lower case letters and their capitals.<strong> <br /></strong></h6>
                <p ><strong>4.  Grapheme Location</strong></p>
                <p><strong><img src={thrassimage4} alt="Keystage-4" /></strong></p>
                <h6 >Locate and name consonant and vowel graphs, digraphs and trigraphs.<strong> <br /></strong></h6>
                <p ><strong>5.  Keyword Location</strong></p>
                <p><strong><img src={thrassimage5} alt="Keystage-5" /></strong></p>
                <h6 >Locate and name the 120 keywords.<strong> <br /></strong></h6>
                <p ><strong>6.  Phoneme Location</strong></p>
                <p><strong><img src={thrassimage6} alt="Keystage-6" /></strong></p>
                <h6 >Locate and articulate the 44 phonemes.<strong> <br /></strong></h6>
                <p ><strong>7.  Keyword Synthesis</strong></p>
                <p><strong><img src={thrassimage7} alt="Keystage-7" /></strong></p>
                <h6 >Blend, read and spell the 120 keywords simultaneously with the phoneme/grapheme soundtrack.<strong> <br /></strong></h6>
                <h6 ><strong>8.  Keygrapheme Recall</strong></h6>
                <p><strong><img src={thrassimage8} alt="Keystage-8" /></strong></p>
                <h6 >Visualise and spell the 120 keygraphemes and recall the associated keywords.<strong> <br /></strong></h6>
                <p ><strong>9.  Keyword Analysis</strong></p>
                <p><strong><img src={thrassimage9} alt="Keystage-9" /></strong></p>
                <h6 >Read, spell and analyse the 120 keywords.<strong> <br /></strong></h6>
                <p ><strong>10.  THRASS 500 Tests</strong></p>
                <p><strong><img src={thrassimage10} alt="Keystage-10" /></strong></p>
                <h6 >Read and spell the THRASS 500 word bank.</h6>
                
                  </div>
                  </div>
                  </div>
                  </div>



        </section>
        <NewsLetter />
      </div>
    </>
  )
}
export default TheKeyStages;