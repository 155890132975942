import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { formatCurrency } from '../../utilities/formatCurrency';
import { BookService } from '../../services/BookService';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
const bookService = new BookService();

function TopNavCart() {
  const { cartTotal, cartItems, removeFromCart, getItemTotal } = useShoppingCart()
  const [ checkoutUrl, setCheckoutUrl ] = useState('/login')
  const { auth } = useContext(UserContext)
  useEffect(() => {
    if (auth.user) setCheckoutUrl('/shop-checkout')
  }, [auth.user])

  return (
    <Dropdown as="li" className="nav-item">
      <Dropdown.Toggle as="button" type="button" className="nav-link box cart-btn i-false">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" /></svg>
        <span className="badge">{cartItems.length}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul" className="dropdown-menu cart-list">
        {
          cartItems.map(item => (
            <li className="cart-item" key={item.id}>
              <div className="media">
                <div className="media-left">
                  <Link to={`/book/${item.book?.slug}`}>
                    <img alt={item.book?.title ?? item.book?.name} className="media-object" src={bookService.getFrontImage(item.book)} />
                  </Link>
                </div>
                <div className="media-body">
                  <h6 className="dz-title">
                    <Link to={`/book/${item.book?.slug}`} className="media-heading">{item.book?.title ?? item.book?.name}</Link></h6>
                  <span className="dz-price">{formatCurrency(getItemTotal(item.id))}</span>
                  <span className="item-close" onClick={() => removeFromCart(item.id)}>&times;</span>
                </div>
              </div>
            </li>
          ))
        }
        <li className="cart-item text-center" key="total">
          <h6 className="text-secondary">Total = {formatCurrency(cartTotal)}</h6>
        </li>
        <li className="text-center d-flex" key="buttons">
          <Link to={"shop-cart"} className="btn btn-sm btn-primary me-2 btnhover w-100">View Cart</Link>
          <Link to={checkoutUrl} className="btn btn-sm btn-outline-primary btnhover w-100">Checkout</Link>
        </li>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default TopNavCart;