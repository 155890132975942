import PageTitle from '../../layouts/PageTitle';
import NewsLetter from '../../components/NewsLetter';
import thrassimage7 from '../../assets/images/thrassimage7.jpg'
import thrassimage8 from '../../assets/images/thrassimage8.jpg'
import thrassimage9 from '../../assets/images/thrassimage9.jpg'

function TheGraphemeChart() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="The Grapheme Chart" />
        <section className="content-inner overlay-white-middle">
          <div className="container">
            <div className="row about-style1 align-items-center">
              <div className="col-lg-12 m-b30 aos-item">
                <div className="about-content px-lg-4">
                  <h4 className="title">
                    The Grapheme Chart features letter-boxes, phoneme-boxes and graphemes. 
                  </h4>
                  <p>
                    The chart assists in understanding phonemes in words, practicing spelling
                    (changing phonemes to graphemes) and reading (changing graphemes to 
                    phonemes) and making comparisons between similar words.
                  </p>
                  <h4 className="title">
                    The Letter-boxes
                  </h4>
                  <img className="m-b30" src={thrassimage7} alt="THRASS Chart" />
                  <h4 className="title">
                    The Consonant Sound-boxes
                  </h4>
                  <img className="m-b30" src={thrassimage8} alt="THRASS Chart" />
                  <p>These show the graphemes (spelling choices) for the consonant sounds.</p>
                  <h4 className="title">
                    The Vowel Sound-boxes
                  </h4>
                  <img className="m-b30" src={thrassimage9} alt="THRASS Chart" />

                  <p>
                    These show the graphemes (spelling choices) for the vowel sounds.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NewsLetter />
      </div>
    </>
  )
}
export default TheGraphemeChart;