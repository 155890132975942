import React from 'react';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';


//Components 
import PageTitle from './../layouts/PageTitle';
import TestimonialSlider from './../components/Home/TestimonialSlider';
import ClientsSlider from './../components/Home/ClientsSlider';
import NewsLetter from '../components/NewsLetter';

//element
import CounterSection from './../elements/CounterSection';

//Imgaes
import about1 from './../assets/images/about/about1.jpg';
import about2 from './../assets/images/about/about2.jpg';
import about3 from './../assets/images/about/about-thrass.jpg';
import antbaby from './../assets/images/ant-baby.jpg'

const missionBlog = [
    {iconClass:'flaticon-open-book-1', title:'Best Bookstore' },
    {iconClass:'flaticon-exclusive', title:'Trusted Seller' },
    {iconClass:'flaticon-store', title:'Expand Store' },
];

function WhatIsThrass(){
    return(
        <>
            <div className="page-content bg-white">
               <PageTitle  parentPage="Home" childPage="What is THRASS?" />
               <section className="content-inner overlay-white-middle">
                    <div className="container">
                        <div className="row about-style1 align-items-center">
                            {/* <div className="col-lg-6 m-b30">
                                <div className="row sp10 about-thumb">
                                    <div className="col-sm-6 aos-item" >
                                        <div className="split-box">
                                            <div>
                                                <img className="m-b30" src={about1} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="split-box ">
                                            <div>
                                                <img className="m-b20 aos-item" src={about2} alt=""  />
                                            </div>
                                        </div>
                                        <div className="exp-bx aos-item" >
                                            <div className="exp-head">
                                                <div className="counter-num">
                                                    <h2><span className="counter"> <CountUp end={50} /></span><small>+</small></h2>
                                                </div>
                                                <h6 className="title">Years of Experience</h6>
                                            </div>
                                            <div className="exp-info">
                                                <ul className="list-check primary">
                                                    <li>Comics & Graphics</li>
                                                    <li>Biography</li>
                                                    <li>Literary Collections</li>
                                                    <li>Children Fiction</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-12 m-b30 aos-item">
                                <div className="about-content px-lg-4">
                                    <div className="section-head style-1">
                                        <h6 className="title">
                                            THRASS® is a phonics programme that has been specifically designed to teach children 
                                            (and adults) about the 44 speech sounds (phonemes) in spoken English and their related 120 keyspellings
                                            (spelling choices or graphemes) in written English.
                                        </h6>
                                    </div>
                                    <p>
                                        THRASS moves away from the traditional “One Letter Makes One Sound” method of teaching phonics and teaches
                                        children that letters can make different sounds depending on the ‘job’ they have to do in different words. 
                                        For example, the letter “a” makes a different sound in each of the following words: ant; baby; banana; zebra; 
                                        swan and ball.
                                    </p>
                                    <img className="m-b30" src={antbaby} alt="What is THRASS" />
                                    <p>
                                        Children are made aware of the association between sounds and letters from an early age, and are taught to 
                                        distinguish between consonant and vowel sounds and to articulate the sounds correctly.
                                    </p>
                                    <p>
                                        THRASS teaches that keyspellings (also known as graphemes or spelling choices) are letters that have been 
                                        grouped together to make one sound in words. A grapheme can be a graph (1 letter spelling choice, eg. “c” in cat), 
                                        a digraph (2 letter spelling choice, eg. “ch”" in cheese), a trigraph (3 letter spelling choice, eg. “tch” in watch) 
                                        or a quadgraph (4 letter spelling choice, eg. “eigh” in eight).
                                    </p>
                                    <p>
                                        These “building blocks” of letters, speech sounds and spelling choices are further reinforced through the inter-related 
                                        skills of handwriting (correct letter formation), reading (changing graphemes to phonemes) and spelling (changing phonemes 
                                        to graphemes).
                                    </p>
                                    <p>
                                        Using the THRASS methodology, children’s reading, spelling, auditory, visual, sequential and spatial perceptual skills are 
                                        all improved.
                                    </p>
                                    <p>
                                        The focus of THRASS in the classroom is a large, colourful ENGLISH PHONICS CHART, commonly known as the THRASS® Chart, 
                                        with the 44 sound boxes and 120 pictures, keywords and keyspellings (spelling choices). Together with the teacher, the 
                                        children explore the different sound boxes (houses) and learn about the pictures that are grouped together in the boxes 
                                        as they have a common sound in their keywords. They Iearn about the different letters that make up these keyspellings and 
                                        apply this knowledge to reading and spelling activities. Through the different activities that have been carefully structured 
                                        in the weekly lesson plans found in the Teacher’s Guides, the teacher introduces and consolidates the children's learning 
                                        experiences by using posters, Sing-A-Long songs, raps, picture cards, phonic readers, games and activities found in the 
                                        classroom workbooks and homework books.
                                    </p>
                                    <p>
                                        This multi-sensory approach to teaching phonics caters for all learning styles in children whether it is visual, 
                                        auditory or kinaesthetic, thus making it an ideal teaching tool for the classroom, remedial purposes and home schooling 
                                        for all ages.
                                    </p>
                                    <p>
                                        The Teacher’s Guides Learner Phonics Workbooks and My Word Power Phonics Books were designed for the teaching of 
                                        phonics to children in a systematic and progressive way allowing for the development and acquisition of the phonic 
                                        skills needed for each level. Through fun and exciting activities these skills are developed and consolidated. 
                                        They are ideally suited to the South African education system and are CAPS compliant.
                                    </p>
                                    <p>
                                        These resources are designed for children where English is their home language as well as for those where English 
                                        is an additional language. Although they are essentially meant for children in Grades 1, 2 and 3, they are versatile 
                                        and can be used for a child at any level.
                                    </p>
                                    <p>
                                        Additional resources which complement the programme include colourful charts of various sizes; Sing-A-Long CDs, 
                                        posters and books; the Raps and Sequences CD, Phoneme-Grapheme Cards,  Picture Cards and Readers that explore the 
                                        sounds in engaging stories.
                                    </p>
                                    <Link to={"/contact-us"} className="btn btn-primary btnhover shadow-primary">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="content-inner-1 bg-light">
                    <div className="container">
                        <div className="section-head text-center">
                            <h2 className="title">Our Mission</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                        </div>
                        <div className="row">
                            {missionBlog.map((data, i)=>(
                                <div className="col-lg-4 col-md-6" key={i}>
                                    <div className="icon-bx-wraper style-3 m-b30">
                                        <div className="icon-lg m-b20">
                                            <i className ={`icon-cell ${data.iconClass}`}></i>
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="title">{data.title}</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                                            <Link to={"about-us"}>Learn More <i className ="fa-solid fa-angles-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section> */}
                {/* <section className="content-inner-1 testimonial-wrapper">
					<TestimonialSlider />	
				</section>	
                <section className="content-inner bg-light">
                    <div className="container">
				        <div className="row sp15">
                            <CounterSection /> 
                         </div>
                    </div>        
                </section>
                <div className="py-5">
			        <div className="container">
                         <ClientsSlider />       
                    </div>
                </div>     */}
                <NewsLetter />             
            </div>
        </>
    )
}
export default WhatIsThrass;