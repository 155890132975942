import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { format } from 'date-fns'

export default function UserSubscriptionPreview({ subscription }) {
  const [subHeader, setSubHeader] = useState('')

  useEffect(() => {
    const value = `${subscription.subscriptionPlan?.name}`
    setSubHeader(value)
  }, [subscription])

  const getTypeDescription = (type) => {
    switch (type) {
      case 1:
        return 'Individual'
      case 2:
        return 'Group'
      case 3:
        return 'Free Subscription'
      case 4:
        return 'Direct Invite'
      default:
        return 'Unknown'
    }
  };
  const checkExpiryDate =(endDate) => {
debugger;
    if (endDate >= Date.now()) {
      return 'Active'
    }
    else {
      return 'Expired'
    }

  }

  return (
    <Link to={`/view-subscription/${subscription.uid}`}>
      <Card sx={{ maxWidth: 345 }}>
        <CardHeader
          title={subscription.ownerName}
          subheader={subHeader}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            <b>Start Date: </b>{format(new Date(subscription.startDate), 'dd MMM yyyy')} <br />
            <b>Expiry Date: </b>{format(new Date(subscription.endDate), 'dd MMM yyyy')} <br />
            <b>Type: </b>{getTypeDescription(subscription.type)} <br />
            <h6><b>Status: </b>{checkExpiryDate(new Date(subscription.endDate), 'dd MMM yyyy')} </h6><br />
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}
