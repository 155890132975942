import { useState, useEffect, useContext, useCallback } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import useIsMounted from '../../hooks/useIsMounted';
import { AddressService } from '../../services/AddressService';
import { CountryService } from '../../services/CountryService';
import { Form } from 'react-bootstrap';

const countryService = new CountryService();
const addressService = new AddressService();

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function EditAddressModal({open, handleClose, address, handleEditSubmit}) {
  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ phone, setPhone ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ company, setCompany ] = useState('');
  const [ streetAddress, setStreetAddress ] = useState('');
  const [ apartment, setApartment ] = useState('');
  const [ city, setCity ] = useState('');
  const [ province, setProvince ] = useState('');
  const [ postalCode, setPostalCode ] = useState('');
  const [ countries, setCountries ] = useState([]);
  const [ countryId, setCountryId ] = useState(111);
  const isMounted = useIsMounted()
  const [disableAddressButton, setDisabledAddress] = useState(false);
  const getCountries = useCallback(async () => {
    const response = await countryService.getAll()
    isMounted() && setCountries(response.data)
  }, [isMounted])

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      const payload = {
        id: address.id,
        firstName,
        lastName,
        phone,
        email,
        company,
        streetAddress,
        apartment,
        city,
        province,
        postalCode,
        countryId,
        userId: address.userId,
      }

      handleEditSubmit(payload)
    } catch (error) {
    }
  }

  const getSelectedCountry = (event) => 
   
   {
   
     event.preventDefault();
     var country =event.target.selectedOptions[0].text;
     var allowedCountries =process.env.REACT_APP_ALLOWED_COUNTRIES;
     setDisabledAddress(true);
     if( allowedCountries.indexOf(country)>=0){
       setDisabledAddress(false);
     }

     setCountryId(event.target.value)
   
   };
  useEffect(() => {
    setFirstName(address.firstName)
    setLastName(address.lastName)
    setPhone(address.phone ?? '')
    setEmail(address.email)
    setCompany(address.company)
    setStreetAddress(address.streetAddress)
    setApartment(address.apartment)
    setCity(address.city)
    setProvince(address.province)
    setPostalCode(address.postalCode)
    setCountryId(address.countryId)
  }, [address])

  useEffect(() => {
    getCountries()
  }, [getCountries])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Address
          </Typography>
          <form onSubmit={handleSubmit}>
            <div className="row mb-2 mt-2">
              <div className="form-group col-md-6">
                <input type="text" className="form-control" placeholder='First Name' required maxLength="50"
                  value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
              </div>
              <div className="form-group col-md-6">
                <input type="text" className="form-control" placeholder='Last Name' required maxLength="50"
                  value={lastName} onChange={(e) => setLastName(e.target.value)}/>
              </div>
            </div>
            <div className="row mb-2">
              <div className="form-group col-md-6">
                <input type="email" className="form-control" placeholder='Email Address' required maxLength="150"
                  value={email} onChange={(e) => setEmail(e.target.value)}/>
              </div>
              <div className="form-group col-md-6">
                <input type="phone" className="form-control" placeholder='Phone Number (+2771000000)' required maxLength="20"
                  value={phone} onChange={(e) => setPhone(e.target.value)}/>
              </div>
            </div>
            <div className="form-group col-md-12 mb-4">
              <input type="text" className="form-control" placeholder='Company Name (optional)'  maxLength="100"
                value={company} onChange={(e) => setCompany(e.target.value)}/>
            </div>
            <div className="form-group col-md-12 mb-2">
              <input type="text" className="form-control" placeholder='Street Address' required  maxLength="100"
                value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)}/>
            </div>
            <div className="row mb-2">
              <div className="form-group col-md-6">
                <input type="text" className="form-control" placeholder='Apartment, suite, unit, etc.' required maxLength="50"
                  value={apartment} onChange={(e) => setApartment(e.target.value)}/>
              </div>
              <div className="form-group col-md-6">
                <input type="text" className="form-control" placeholder='City/Town' required maxLength="50"
                  value={city} onChange={(e) => setCity(e.target.value)}/>
              </div>
            </div>
            <div className="row mb-2">
              <div className="form-group col-md-6">
                <input type="text" className="form-control" placeholder='State/Province/County' required maxLength="20"
                  value={province} onChange={(e) => setProvince(e.target.value)}/>
              </div>
              <div className="form-group col-md-6">
                <input type="text" className="form-control" placeholder='Postal/Zip Code' required maxLength="6"
                  value={postalCode} onChange={(e) => setPostalCode(e.target.value)}/>
              </div>
            </div>
            <div className="form-group mb-2">
              <Form.Select value={countryId} onChange={getSelectedCountry}>
                {countries.map((country, index) => {return(<option value={country.id} key={index}>{country.name}</option>)})}
              </Form.Select>
            </div>
            { disableAddressButton && <div className='backgroundWarning' >
          <h6>Unable to deliver/subscribe</h6>
          <p>Only Allowed countries are : {process.env.REACT_APP_ALLOWED_COUNTRIES}  </p>
        </div>
        }
            <button className="btn btn-primary btnhover mt-2" type="submit" disabled={disableAddressButton}>
              Update Address
            </button>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
}

export default EditAddressModal;