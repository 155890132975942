import { formatCurrency } from "../../utilities/formatCurrency";
import CartItemQuantity from "../cart/CartItemQuantity";

function BookBuyOption({ book, id, type }) {
  return (
    <div className="widget">
      <div className='shipping-addresses'>
        <div>
          <label htmlFor='control_01'>
            <div className='row'>
              <div className="col-md-6">
                <div className="buy-info">
                  <h5>{formatCurrency(book?.price * 1.15)}</h5>
                  <p>Buy printed copies of the books in this book.</p>
                </div>
              </div>
              <div className='col-md-6'>
                <div className="buy-option">
                  <CartItemQuantity id={id} type={type} book={book} />
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
}

export default BookBuyOption;