import { createContext, ReactNode, useContext } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import axios from "axios";

type LocationDetails = {
  ip: string;
  hostname: string;
  city: string;
  region: string;
  country: string;
  loc: string;
  org: string;
  postal: string;
  timezone: string;
};

type LocationContext = {
  getLocationDetails: () => any;
  getUrlParams: () => string;
  locationDetails: LocationDetails | null;
}

type LocationDetailsProviderProps = {
  children: ReactNode;
};

const LocationContext = createContext({} as LocationContext);
const ENDPOINT = 'https://ipinfo.io/json?token=0e2fc3c00c140b'

export function useLocation() {
  return useContext(LocationContext);
}

export function LocationDetailProvider({ children }: LocationDetailsProviderProps) {
  const [location, setLocation] = useLocalStorage<LocationDetails | null>("location-details", null)

  function getLocationDetails() {
    if (location?.city) {
      return location;
    } else {
      axios.get(ENDPOINT)
        .then((response: any) => {
          setLocation(response.data);
          return response.data;
        })
    }
  }

  function getUrlParams(): string {
    return `city=${location?.city}&` +
      `ip=${location?.ip}&` +
      `hostname=${location?.hostname}&` +
      `region=${location?.region}&` +
      `country=${location?.country}&` +
      `loc=${location?.loc}&` +
      `org=${location?.org}&` +
      `postal=${location?.postal}&` +
      `timezone=${location?.timezone}`;
  }

  const locationDetails = location
  
  return (
    <LocationContext.Provider
      value={{
        locationDetails,
        getLocationDetails,
        getUrlParams,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}