import PageTitle from '../../layouts/PageTitle';
import NewsLetter from '../../components/NewsLetter';
import thrassimage17 from '../../assets/images/thrassimage17.jpg'
import thrassimage18 from '../../assets/images/thrassimage18.jpg'
import thrassimage19 from '../../assets/images/thrassimage19.jpg'
import thrassimage20 from '../../assets/images/thrassimage20.jpg'
import thrassimage21 from '../../assets/images/thrassimage21.jpg'
import thrassimage22 from '../../assets/images/thrassimage22.jpg'
import thrassimage23 from '../../assets/images/thrassimage23.jpg'
import thrassimage24 from '../../assets/images/thrassimage24.jpg'
import thrassimage25 from '../../assets/images/thrassimage25.jpg'

function TeachersGuides() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="Teacher's Guides" />
        <section className="content-inner overlay-white-middle">
          <div className="container">
            <div className="row about-style1 align-items-center">
              <div className="col-lg-12 m-b30 aos-item">
                <div className="about-content px-lg-4">
                  <h4 className="title">
                    Teacher's Guides
                  </h4>
                  <p>
                    Teacher's Guides are available for the different grades for both Home Language and First Additional Language.
                  </p>
                  <img src={thrassimage17} alt="Thrass Chart" className="center" />
                  <img src={thrassimage18} alt="Thrass Chart" className="center" />
                  <img src={thrassimage19} alt="Thrass Chart" className="center" />
                  <img src={thrassimage20} alt="Thrass Chart" className="center" />
                  <img src={thrassimage21} alt="Thrass Chart" className="center" />
                  <img src={thrassimage22} alt="Thrass Chart" className="center" />
                  <img src={thrassimage23} alt="Thrass Chart" className="center" />
                  <img src={thrassimage24} alt="Thrass Chart" className="center" />
                  <p>
                    The Teacher's Guides have a picture from the Sing-A-Long Book, and are colour-coded for each grade for ease-of-reference. The Teacher's Guide and the Learner’s Phonics Workbook (classroom workbook) have the same colour and cover picture while the My Word Power Phonics Book (homework book) has the same colour but a different picture so that the children can easily differentiate between the two workbooks.
                  </p>
                  <img src={thrassimage25} alt="Thrass Chart" className="center" />
                  <p> Teacher’s Guides include: </p>
                  <ul className="list-check primary">
                    <li> An overview of THRASS® and Teacher’s Notes </li>
                    <li> A Year Plan </li>
                    <li> CAPS and Assessment</li>
                    <li> Weekly Lesson Plans and detailed daily activities, games and instructions to develop phonics, language and writing skills </li>
                    <li> Teacher’s Resource Pack with photocopiable activities, games and worksheets </li>
                    <li> Spelling Lists, sight words and dictation </li>
                  </ul>

                  <p>
                    <a href="https://thrassafrica.co.za/index.php/home-language/hl-teachers-guides">
                      Shop Home Language Teacher's Guides
                    </a>
                  </p>

                  <p>
                    <a href="https://thrassafrica.co.za/index.php/first-additional-language/fal-teachers-guides">
                      Shop First Additional Language Teacher's Guides
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NewsLetter />
      </div>
    </>
  )
}
export default TeachersGuides;