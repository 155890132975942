import PageTitle from '../../layouts/PageTitle';
import NewsLetter from '../../components/NewsLetter';
import thrassimage4 from '../../assets/images/thrassimage4.jpg'
import thrassimage5 from '../../assets/images/thrassimage5.jpg'
import thrassimage6 from '../../assets/images/thrassimage6.jpg'

function Definitions() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="THRASS Definitions" />
        <section className="content-inner overlay-white-middle">
          <div className="container">
            <div className="row about-style1 align-items-center">
              <div className="col-lg-12 m-b30 aos-item">
                <div className="about-content px-lg-4">
                  <h6 className="title">
                    THRASS Definitions
                  </h6>
                  <p>
                    Phonemes are speech sounds – there are 44 phonemes in spoken English.
                  </p>
                  <p>
                    Graphemes are spelling choices, and may have one, two, three or four letters.
                  </p>
                  <img className="m-b30" src={thrassimage4} alt="THRASS Chart" />

                  <h6 className="title">
                    Graph
                  </h6>
                  <p>
                    A graph is where one letter represents one phoneme (speech sound).
                  </p>
                  <p>
                    In c-a-t, c, a and t are all graphs as there is only one letter being used to represent the ‘c’, ‘a’ and ‘t’ phoneme/sound.
                  </p>
                  <p>
                    In d-u-ck, d and u are both graphs.
                  </p>
                  <img className="m-b30" src={thrassimage5} alt="THRASS Chart" />

                  <h6 className="title">
                    Digraph
                  </h6>
                  <p>
                    A digraph is where two letters represent one phoneme (speech sound).
                  </p>
                  <p>
                    In t-r-ee, ee is a digraph as there are two letters representing the ‘ee’ phoneme/sound.
                  </p>
                  <p>
                    In b-ea-ch, ea and ch are both digraphs.
                  </p>
                  <img className="m-b30" src={thrassimage6} alt="THRASS Chart" />

                  <h6 className="title">
                    Quadgraph
                  </h6>
                  <p>
                    A quadgraph is where four letters represent one phoneme (speech sound).
                  </p>
                  <p>
                    In eigh-t, eigh is a quadgraph as there are four letters representing the ‘eigh’ phoneme/sound.
                  </p>
                  <p>
                    In b-ough, ough is a quadgraph.
                  </p>
                  <p>
                    <a href="https://thrassafrica.co.za/images/downloads/EPCP-Drill.pdf">
                      Download the EPCP Drill.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NewsLetter />
      </div>
    </>
  )
}
export default Definitions;