import PageTitle from '../../layouts/PageTitle';
import NewsLetter from '../../components/NewsLetter';
import Gallery from '../../components/ThrassGallery'

function AboutUs() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="Thrass in Action" />
        <div className="row about-style1 align-items-center">
        </div>
        <div id="Gallery">
          <Gallery />
        </div>
        <div>
        </div>
        <NewsLetter />
      </div>
    </>
  )
}


export default AboutUs;