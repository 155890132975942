import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormattedDate from '../../../components/FormattedDate';
import { UserContext } from '../../../context/UserContext';
import { OrderService } from '../../../services/OrderService';
import { formatCurrency } from '../../../utilities/formatCurrency';
import LeftMenu from '../LeftMenu';

const orderService = new OrderService();

function Orders() {
  const { auth } = React.useContext(UserContext)
  const [orders, setOrders] = useState([]);
  const [disableAddressButton, setDisabledAddress] = useState(true);
  const getOrders = useCallback(async () => {
    const response = await orderService.getUserOrders(auth.user.id);
    setOrders(response.data);
    if(response.data.length > 0) {
        getSelectedCountry(response.data[0]);
    
    }else{
      setDisabledAddress(false);
    }
  }, [auth.user.id])

  useEffect(() => {
    getOrders()
  }, [getOrders])
  function getSelectedCountry(order)
   
  {

    var country = order.deliveryAddress.country.name;
    var allowedCountries =process.env.REACT_APP_ALLOWED_COUNTRIES;
    setDisabledAddress(true);
    if( allowedCountries.indexOf(country)>=0){
      setDisabledAddress(false);
    }

  
  
  };

  return (
    <>
      <div className="page-content bg-white">
        <div className="content-block">
          <section className="content-inner bg-white">
            <div className="container">
              <div className="row">
                <LeftMenu />
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="shop-bx shop-profile">
                    <div className="shop-bx-title clearfix">
                      <h5 className="text-uppercase">
                        Orders
                      </h5>
                    </div>
                    <div className="row">
                      <div className='col-md-12'>
                      { disableAddressButton && <div className='backgroundWarning' >
                      <h6>Unable to deliver/subscribe</h6>
                      <p>Only Allowed countries are : {process.env.REACT_APP_ALLOWED_COUNTRIES} </p>
                    </div>
                    }
                        {(orders.length > 0) 
                        ? (
                          <div className="table-responsive">
                            <table className="table-bordered check-tbl m-b25">
                              <thead>
                                <tr>
                                  <th>Reference</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th className="currency-view">VAT</th>
                                  <th className="currency-view">Total</th>
                                  <th className="currency-view">Balance</th>
                                  <th className="currency-view"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders.map((order, i) => (
                                  <tr key={i}>
                                    <td><b><Link to={`/view-order/${order.uid}`} >{order.reference}</Link></b></td>
                                    <td><FormattedDate date={order.orderDate} /></td>
                                    <td>{orderService.getStatus(order)}</td>
                                    <td className="currency-view">{formatCurrency(order.vat)}</td>
                                    <td className="currency-view">{formatCurrency(order.total)}</td>
                                    <td className="currency-view">{formatCurrency(order.balance)}</td>
                                    <td className="currency-view">
                                      {(order.balance > 0 && order.status === 0) 
                                      ? <a href={orderService.getPayFastUrl(order, auth.user.email)} target='_blank' rel='noreferrer' hidden={disableAddressButton}  >
                                          <i className ='fa fa-credit-card' aria-hidden='true'></i>
                                        </a>
                                      : null}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )
                        : <h4>No orders yet</h4>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
export default Orders;