import { Link } from "react-router-dom";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
function CartItemQuantity({ id, type, book }) {
  const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart ,getSubscribedItemQuantity} = useShoppingCart();
  const { cartQuantity } = useShoppingCart()
  const [ checkoutUrl, setCheckoutUrl ] = useState('/login')
  const { auth } = useContext(UserContext)
  useEffect(() => {
    if (auth.user) setCheckoutUrl('/shop-checkout')
  }, [auth.user])

  return (
    <>
      {getItemQuantity(id) > 0
        ? <>
          <div className="row">
            <div className="col-md-6">
              <div className="quantity btn-quantity style-1 me-3">
                <button className="btn btn-plus" type="button" onClick={() => increaseCartQuantity(id, type)}>
                  <i className ="ti-plus"></i>
                </button>
                <input className="quantity-input" type="text" value={getItemQuantity(id)} name="demo_vertical2" readOnly />
                <button className="btn btn-minus " type="button" onClick={() => decreaseCartQuantity(id)}>
                  <i className ="ti-minus"></i>
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <button className="btn btn-danger btnhover btnhover2 float-end"
                onClick={() => removeFromCart(id)}>
                <span>&times;</span>
                <span className="pl">Remove</span>
              </button>
            </div>
            {cartQuantity > 0 && (
              <div className="col-md-12 mt-3">
                <Link to={checkoutUrl} className={'btn btn-sm btn-outline-primary btnhover w-100'}>
                  <span className="pl">Checkout</span>
                </Link>
              </div>
            )}
          </div>
        </>
        : <>
            <div className="row">
              <div className="col-md-12 mb-3">
                <button className={type === 3 || getSubscribedItemQuantity(id) > 0 ? 'btn btn-danger btnhover btnhover2 w-100' : 'btn btn-primary btnhover btnhover2 w-100'}
                  onClick={() => increaseCartQuantity(id, type, book)}>
                  <i className ="flaticon-shopping-cart-1"></i>
                  <span className="pl">{type === 3 ? getSubscribedItemQuantity(id)>0? ' Subscribed':' Subscribe'  : 'Purchase'}</span>
                </button>
              </div>
              {cartQuantity > 0 && (
                <div className="col-md-12">
                  <Link to={checkoutUrl} className={'btn btn-sm btn-outline-primary btnhover w-100'}>
                    <span className="pl">Checkout</span>
                  </Link>
                </div>
              )}
            </div>
          </>
      }
    </>
  );
}

export default CartItemQuantity;