import React,{ useRef } from 'react';
import emailjs from '@emailjs/browser';

import bg1 from './../assets/images/background/bg1.jpg';

function NewsLetter({subscribeChange}){
    const form = useRef();
	const sendEmail = (e) => {
		e.preventDefault();
		//emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
		emailjs.sendForm('gmail', 'YOUR_TEMPLATE_ID', e.target, 'd9b2e0f5fc72cb94792110e8ff2028f3-us16')
		  .then((result) => {
		  }, (error) => {
		  });
		  e.target.reset()
	};	
    return(
        <>
          <div></div>
        </>
    )
}
export default NewsLetter;