import PageTitle from "../../layouts/PageTitle";
import thrassimage10 from "../../assets/images/thrassimage10.jpg";
import thrassimage12 from "../../assets/images/thrassimage12.jpg";
import thrassimage13 from "../../assets/images/thrassimage13.jpg";

function EnglishPhonicsReaders2() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="English Phonics Readers" />
        <section className="content-inner overlay-white-middle">
          <div className="container">
            <div className="row about-style1 align-items-center">
              <div className="col-lg-12 m-b30 aos-item">
                <div className="about-content px-lg-4">
                  <h6 className="title">English Phonics Readers</h6>
                  <p>
                    The bright and colourful English Phonics Readers sets
                    explore the different spelling choices for each of the 44
                    sounds in English through delightful and enchanting stories
                    for children.
                  </p>
                  <img src={thrassimage10} alt="Thrass Chart" className="center" />

                  <p>
                    Through classroom teaching, children have been made aware of these
                    different spelling options, but have only experienced them in lists of
                    words and sentences. Now they will be exposed to as many words as possible
                    through engaging stories.
                  </p>

                  <p>
                    Each sound has its own special story to allow children to experience the
                    different spelling choices in a storybook context. Certain to enhance any
                    phonics lesson, the Readers are ideal for shared reading at home or at
                    school. The sets comprise 44 books (one for each sound) – with the
                    consonant sounds and their spellings in blue and the vowel sounds and
                    their spellings in red. The Readers have been designed to work as a set,
                    but can be purchased individually.
                  </p>

                  <img src={thrassimage12} alt="Thrass Chart" className="center" />

                  <p>
                    Each book ends with 5 comprehension questions that are designed to develop
                    children's spelling, comprehension and critical thinking skills.
                  </p>

                  <img src={thrassimage13} alt="Thrass Chart" className="center" />

                  <p>
                    Each book includes a Word List for introducing and discussing the
                    vocabulary used in the story.
                  </p>

                  <p>
                    The English Phonics Readers are available in 3 Levels. The Reader spines
                    have different shading to differentiate between the 3 levels, and font
                    sizes and story complexity have been adapted for the different levels.
                  </p>

                  <p>
                    <a href="https://thrassafrica.co.za/index.php/english-phonics-readers">
                      Shop English Phonics Readers
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default EnglishPhonicsReaders2;
