function PaymentMethod({checkout}) {
  return (
    <>
      <h4 className="widget-title">Accepted Payment Methods</h4>
      <p>We accept all major credit cards and EFTs. Click on the "Checkout" button below to place and pay for your order.</p>

      <div className="form-group">
        <button className="btn btn-primary btnhover" type="button" onClick={() => checkout()}>
          Checkout
        </button>
      </div>
    </>
  )
}

export default PaymentMethod;