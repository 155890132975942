import React from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
const images = [
    {
      src: "https://www.thrassafrica.co.za/images/com_osgallery/gal-1/thumbnail/bbpl5994E7C85E49-E223-F4D7-DE7D-E711C13A3E57.jpeg",
   
    },
    {
      src: "https://www.thrassafrica.co.za/images/com_osgallery/gal-1/thumbnail/cnmt4193731E1ED6-35DA-3413-1DF3-5373658CD036.jpeg",
      width: 10,
      height: 10
    },
    {
      src: "https://www.thrassafrica.co.za/images/com_osgallery/gal-1/thumbnail/csmx87005711474F-ADE2-F323-9BD3-395B17EBC32D.jpeg",
      width: 3,
      height: 4
    },
    {
      src: "https://www.thrassafrica.co.za/images/com_osgallery/gal-1/thumbnail/fcne1185DFBA8516-AF3B-4DC1-DFC1-4355B84474C7.jpeg",
 
      width: 3,
      height: 4
    },
    {
      src: "https://www.thrassafrica.co.za/images/com_osgallery/gal-1/thumbnail/fjcp8672E946663B-49C3-30A5-FBBC-409F2E672BDB.jpeg",
    
   
      width: 10,
      height: 10
    },
    {
      src: "https://www.thrassafrica.co.za/images/com_osgallery/gal-1/thumbnail/utpi7699F748F0ED-71DA-AFD5-820A-AE495243803A.jpeg",
     
      width: 4,
      height: 3
    },
    {
      src: "https://www.thrassafrica.co.za/images/com_osgallery/gal-1/thumbnail/jzfu63464FF8E835-0C35-D78F-2C69-8CF88E4DAFAE.jpeg",
  
      width: 3,
      height: 4
    },
    {
      src: "https://www.thrassafrica.co.za/images/com_osgallery/gal-1/thumbnail/sefg3275FC1DD787-05A5-F1C6-6E42-ADE23C8146D0.jpeg",
     
      width: 4,
      height: 3
    },
    {
      src: "https://www.thrassafrica.co.za/images/com_osgallery/gal-1/thumbnail/sxbi99947CA9E810-7161-26D8-A6CB-BBC92F0DF520.jpeg",
     
      width: 6,
      height: 6
    }
  ];
 
  
  class ThrassGallery extends React.Component {
    render() {
      return <Gallery photos={images} />;
    }
  }

export default ThrassGallery;