import PageTitle from '../../layouts/PageTitle';
import tg from "../../assets/images/Teacher-Guides_295x295.jpg";
import wb from "../../assets/images/Workbooks4_295x295.jpg";
import mw from "../../assets/images/My-Word-Power_295x295.jpg";

function HomeLanguage() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="Home Language" />
        <section className="content-inner overlay-white-middle">
          <div className="container">
            <div className="row about-style1 align-items-center">
              <div className="col-lg-12 m-b30 aos-item">
                <div className="about-content px-lg-4">
                  <h6 className="title">
                    Home Language
                  </h6>
                  <div className='row'>
                    <div className='col-md-2'>
                      <a href="/home-language/hl-teachers-guides" className='link-to-category'>
                        <img src={tg} alt="Teacher’s Guides" className="center" />
                        Teacher’s Guides
                      </a>
                    </div>
                    <div className='col-md-2'>
                      <a href="/home-language/hl-teachers-guides" className='link-to-category'>
                        <img src={wb} alt="Learner Phonics Workbooks" className="center" />
                        Learner Phonics Workbooks
                      </a>
                    </div>
                    <div className='col-md-2'>
                      <a href="/home-language/hl-teachers-guides" className='link-to-category'>
                        <img src={mw} alt="My Word Power Phonics Books" className="center" />
                        My Word Power Phonics Books
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </>
  )
}
export default HomeLanguage;