import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CategoryService } from '../../services/CategoryService';
import { BookService } from '../../services/BookService';
import { formatCurrency } from '../../utilities/formatCurrency';
import BookDetailButton from './BookDetailsButton';
import BackToMainPage from '../BackToMainPage';
import { Dropdown } from 'react-bootstrap';
import { useLocation } from '../../context/LocationContext';

const bookService = new BookService();
const categoryService = new CategoryService();

function CategoryBooks(props) {
  const [category, setCategory] = useState();
  const [books, setBooks] = useState([]);
  const [numBooks, setNumBooks] = useState(0);
  const [pages, setPages] = useState(0);
  const [take, setTake] = useState(20);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const { getUrlParams } = useLocation()

  const onChangeTake = (t) => {
    setTake(t)
    handleNavigateToPage(1)
  }

  const getBooks = useCallback(async () => {
    var response = await bookService.getByCategory(props.id, take, skip);
    setBooks(response.data);
  }, [props.id, take, skip]);

  const getCategory = useCallback(async () => {
    const response = await categoryService.getCategoryBySlug(props.id, getUrlParams());
    setCategory(response.data);
  }, [getUrlParams, props.id]);

  const getPaginationInfo = useCallback(async () => {
    var response = await categoryService.getPaginationInfo(props.id, take);
    setPages(response.data.pages);
    setNumBooks(response.data.totalItems);
  }, [props.id, take]);

  const handleNavigateToPage = (toPage) => {
    if (toPage < 1) toPage = 1
    if (toPage > pages) toPage = pages

    let newSkip = 0

    if (toPage === 1) 
      newSkip = 0
    else 
      newSkip = ((toPage - 1) * take)

    setPage(toPage)
    setSkip(newSkip)
  }

  useEffect(() => {
    getBooks();
    getCategory();
    getPaginationInfo();
  }, [getBooks, getCategory, getPaginationInfo, props.id]);

  return (
    <div className="page-content bg-grey">
      <section className="content-inner-1 border-bottom padding-top-20">
        <div className="container">
          <BackToMainPage />
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="title">{category?.name}</h4>
          </div>
          <div className="filter-area m-b30">
            <div className="grid-area">
              <div className="shop-tab">
                <ul className="nav text-center product-filter justify-content-end" role="tablist">
                  <li className="nav-item">
                    <Link to={"#"} className="nav-link">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 11H10C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10V3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V10C2 10.2652 2.10536 10.5196 2.29289 10.7071C2.48043 10.8946 2.73478 11 3 11ZM4 4H9V9H4V4Z" fill="#AAAAAA"></path>
                        <path d="M14 11H21C21.2652 11 21.5196 10.8946 21.7071 10.7071C21.8946 10.5196 22 10.2652 22 10V3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2H14C13.7348 2 13.4804 2.10536 13.2929 2.29289C13.1054 2.48043 13 2.73478 13 3V10C13 10.2652 13.1054 10.5196 13.2929 10.7071C13.4804 10.8946 13.7348 11 14 11ZM15 4H20V9H15V4Z" fill="#AAAAAA"></path>
                        <path d="M3 22H10C10.2652 22 10.5196 21.8946 10.7071 21.7071C10.8946 21.5196 11 21.2652 11 21V14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13H3C2.73478 13 2.48043 13.1054 2.29289 13.2929C2.10536 13.4804 2 13.7348 2 14V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22ZM4 15H9V20H4V15Z" fill="#AAAAAA"></path>
                        <path d="M14 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V14C22 13.7348 21.8946 13.4804 21.7071 13.2929C21.5196 13.1054 21.2652 13 21 13H14C13.7348 13 13.4804 13.1054 13.2929 13.2929C13.1054 13.4804 13 13.7348 13 14V21C13 21.2652 13.1054 21.5196 13.2929 21.7071C13.4804 21.8946 13.7348 22 14 22ZM15 15H20V20H15V15Z" fill="#AAAAAA"></path>
                      </svg>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="category">
              <div className="form-group">
                <i className ="fas fa-sort-amount-down me-2 text-secondary"></i>
                <Dropdown>
                  <Dropdown.Toggle className="i-false">{take} <i className ="ms-4 font-14 fa-solid fa-caret-down" /></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onChangeTake(1)}>1</Dropdown.Item>
                    <Dropdown.Item onClick={() => onChangeTake(2)}>2</Dropdown.Item>
                    <Dropdown.Item onClick={() => onChangeTake(5)}>5</Dropdown.Item>
                    <Dropdown.Item onClick={() => onChangeTake(10)}>10</Dropdown.Item>
                    <Dropdown.Item onClick={() => onChangeTake(20)}>20</Dropdown.Item>
                    <Dropdown.Item onClick={() => onChangeTake(30)}>30</Dropdown.Item>
                    <Dropdown.Item onClick={() => onChangeTake(50)}>50</Dropdown.Item>
                    <Dropdown.Item onClick={() => onChangeTake(100)}>100</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {books.length === 0
            ? <></>
            : <div className="row book-grid-row">
              {books.map((book, i) => (
                <div className="col-book style-1" key={i}>
                  <div className="dz-shop-card style-1">
                    <div className="dz-media">
                      <Link to={`/book/${book.slug}`}>
                        <img src={`${bookService.httpService.baseUrl}${book.frontImage}`} alt={book.title} />
                      </Link>
                    </div>
                    <div className="bookmark-btn style-2">
                      
                    </div>
                    <div className="dz-content">
                      <h5 className="title">
                        <Link to={`/book/${book.slug}`}>
                          {book.title}
                        </Link>
                      </h5>
                      <ul className="dz-tags">
                        <li className='boldFont'><Link to={"#"}>{book?.code}</Link></li>
                        {(book?.isbn) && <li className='boldFont'><Link to={"#"}>ISBN: {book?.isbn}</Link></li>}
                      </ul>
                      <h6 className="title">
                        <span className="price-num">{formatCurrency(book.price * 1.15)}</span>
                      </h6>
                      <BookDetailButton book={book} />
                    </div>
                  </div>
                </div>
              ))}
            </div>}
          <div className="row page mt-0">
            <div className="col-md-6">
              <p className="page-text">Showing {books.length} of {numBooks} book(s)</p>
            </div>
            <div className="col-md-6">
              <nav aria-label="Blog Pagination">
                <ul className="pagination style-1 p-t20">
                  <li className="page-item"><Link to={"#"} onClick={() => handleNavigateToPage(1)} className="page-link prev">Start</Link></li>
                  {[...Array(pages)].map((x, i) => (
                    <li className="page-item" key={i} onClick={() => handleNavigateToPage(i+1)}>
                      <Link to={"#"} className={(page === (i+1)) ? 'page-link active' : 'page-link'}>{i+1}</Link>
                      </li>
                  ))}
                  <li className="page-item"><Link to={"#"} onClick={() => handleNavigateToPage(pages)} className="page-link next">End</Link></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default CategoryBooks;