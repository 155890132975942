import { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { AuthService } from "../../services/AuthService";
import PageTitle from '../../layouts/PageTitle';

const auth = new AuthService()

function VerifyEmail(props) {
  const otpRef = useRef();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const params = useParams();

  useEffect(() => {
    otpRef.current.focus();
  }, [])

  useEffect(() => {
    setEmail(params.id);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await auth.confirmEmail(email, otp)
      navigate(`/login/?msg=Your%20email%20address%20has%20been%20verified%20successfully.%20You%20can%20log%20in.`)
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'OTP confirmation failed. Please check the OTP you entered and try again.',
        icon: 'error',
      });
    }
  }

  return (
    <>
      <div className="page-content">
        <PageTitle parentPage="Shop" childPage="Verify Your Email" />
        <section className="content-inner shop-account">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="login-area">
                  <form onSubmit={handleSubmit}>
                    <h4 className="text-secondary">Verify Your Email</h4>
                    <p className="font-weight-600">
                      To verify your email address, please enter the OTP sent to the email address:
                    </p>
                    <p>{email}</p>
                    <div className="mb-4">
                      <label className="label-title">One Time Password *</label>
                      <input className="form-control" placeholder="Enter the OTP" type="number"
                        id="otp" autoComplete="off" onChange={(e) => setOtp(e.target.value)}
                        ref={otpRef} value={otp} required />
                    </div>
                    <div className="text-left">
                      <button type="submit" className="btn btn-primary btnhover w-100 me-2"
                        disabled={!otp ? true : false}>
                        Verify Email
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default VerifyEmail;