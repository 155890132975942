import PageTitle from '../../layouts/PageTitle';
import NewsLetter from '../../components/NewsLetter';
function FreeSoftware() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="Phoneme Machine software" />
        <section className="content-inner overlay-white-middle">
          <div className="container">
            <div className="row about-style1 align-items-center">
              <div className="col-lg-12 m-b30 aos-item">
                <div className="about-content px-lg-4">
                  <h6 className="title">
                     Free software
                  </h6>
                  <p>
                    Suitable for learners of all ages and abilities, the Phoneme Machine software uses moving human lips to demonstrate the pronunciation of the (sounds) phonemes in 100s of frequently used English words.
                  </p>
                  <p>
                    It develops a good understanding of the 120 key spellings of English and their 44 sounds – this understanding is largely achieved by ‘blending all the sounds through a word’.
                  </p>
                  <p>
                    There are three sections:
                  </p>
                  <ul>
                    <li> Calendar Word Grid (WG) </li>
                    <li> Word Grid (WG)</li>
                    <li> Phoneme Grid (PG) </li>
                  </ul>

                  <p>
                    There is a Cued Speech option for deaf learners and their teachers and parents (turned on and off in the Phoneme Grid). The shape, position and movement of the hands are displayed alongside the lips. These sound-based units give deaf learners access to spoken and written English. To install the Cued Speech video clips, download the zip file using the download address supplied.
                  </p>

                  <p>
                    Phoneme Machine software is available on request at no charge.
                  </p>
                  <p>
                    <a href="https://thrassafrica.co.za/index.php?option=com_content&view=article&id=44&Itemid=347">
                      Request Phoneme Machine software.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NewsLetter />
      </div>
    </>
  )
}
export default FreeSoftware;