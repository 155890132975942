import { useShoppingCart } from "../../context/ShoppingCartContext";
import { formatCurrency } from "../../utilities/formatCurrency";

function OrderTotalTable() {
  const { cartTotal, shippingAmount, getVoucherAmount, vatPercentage, getVatTotal, orderTotal } = useShoppingCart()

  return (
    <>
      <h4 className="widget-title">Order Total</h4>
      <table className="table-bordered check-tbl m-b25">
        <tbody>
          <tr>
            <td>Order Subtotal</td>
            <td className="currency-view">{formatCurrency(cartTotal)}</td>
          </tr>
          <tr>
            <td>Shipping</td>
            <td className="currency-view">{formatCurrency(shippingAmount)}</td>
          </tr>
          <tr>
            <td>Coupon</td>
            <td className="currency-view">- {formatCurrency(getVoucherAmount())}</td>
          </tr>
          <tr>
            <td>VAT @ {formatCurrency(vatPercentage)}%</td>
            <td className="currency-view">{formatCurrency(getVatTotal())}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td className="currency-view">{formatCurrency(orderTotal)}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default OrderTotalTable;