import { useRef, useState, useEffect, useContext, useCallback } from "react";
import { Link } from 'react-router-dom';
import PageTitle from '../../layouts/PageTitle';
import { AuthService } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import GoogleLogin from "react-google-login";
import { UserContext } from "../../context/UserContext";
import { useShoppingCart } from "../../context/ShoppingCartContext";

const authService = new AuthService()


const googleResponse = (response) => {
  if (!response.tokenId) {
    console.error("Unable to get tokenId from Google", response)
    return;
  }

  const tokenBlob = new Blob([JSON.stringify({ tokenId: response.tokenId }, null, 2)], { type: 'application/json' });
  const options = {
    method: 'POST',
    body: tokenBlob,
    mode: 'cors',
    cache: 'default'
  };
  fetch("https://voucherservice.azurewebsites.net/api/Auth/google", options)
    .then(r => {
      r.json().then(user => {
        const token = user.token;
        this.props.login(token);
      });
    })
};

function Login() {
  const emailRef = useRef();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [forgotPass, setForgotPass] = useState();
  const { setAuth, auth } = useContext(UserContext);
  const { cartQuantity } = useShoppingCart()
  const [ redirectUrl, setRedirectUrl ] = useState('/dashboard')
  const [userCountry, setCountry] = useState('');
  const [disabledAddress, setDisabledAddress] = useState(true);
  const logout = useCallback(async () => {
    if (!auth?.user?.email) return
    await authService.logout(auth.user.email)
  }, [auth?.user?.email])

  useEffect(() => {
		const getLocation = async () => {
		try {
		
			const response = await fetch('https://ipapi.co/json/');
			const data = await response.json();
      
      setCountry("Curent Country: " + 
      data.country_name + '. Only allowed countries are : ' + process.env.REACT_APP_ALLOWED_COUNTRIES   )
      var allowedCountries =process.env.REACT_APP_ALLOWED_COUNTRIES;
      setDisabledAddress(true);
      if( allowedCountries.indexOf(data.country_name)>=0){
        setDisabledAddress(false);
      }
  

		} catch (error) {
			console.error('Error retrieving location:', error);
		}
		};

		getLocation();
	}, []);


  useEffect(() => {
    emailRef.current.focus();
  }, [])

  useEffect(() => {
    if (cartQuantity > 0) setRedirectUrl('/shop-checkout')
  }, [cartQuantity])

  useEffect(() => {
    setAuth({})
  }, [setAuth])

  useEffect(() => {
    if (auth?.user) logout()
  }, [auth, logout])

  const emailReset = async (e) => {
    e.preventDefault();
    try {
      const response = await authService.resetPassword(email);
      Swal.fire({
        title: 'Success',
        text: 'We have sent you an email with the password reset instructions',
        icon: 'success',
      }).then(
        navigate(`/login`)
      );
    } catch (error) {
      Swal.fire({
        title: 'Error Resetting Password',
        text: 'Email  not found please register',
        icon: 'error',
      });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await authService.login(email, pwd)
      const data = { user: response.data, accessToken: response.data.token }
      setAuth(data);
      navigate(redirectUrl)
    } catch (error) {
      const meesage = error?.response.status===400?"Invalid username and/or password":"You can only log into one computer/browser at a time"
      setErrMsg(meesage);
      Swal.fire({
        title: 'Error Logging In',
        text: '' + meesage,
        icon: 'error',
      });
    }
  }

  return (
    <>
      <div className="page-content">
        <PageTitle parentPage="Shop" childPage="Login" />
        <section className="content-inner shop-account">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="login-area">
                  <div className="tab-content">
                    <h4>NEW CUSTOMER</h4>
                    <p>By creating an account with our store, you will be able to move through the checkout process faster, store multiple shipping addresses, view and track your
                      orders in your account and more.</p>
                     { disabledAddress && <p className='backgroundWarning'>{userCountry}</p>}

                    <div className="row">
                    { !disabledAddress &&
                      <div className="col-lg-12 col-md-6 mb-12">
                        <Link to={"/registration"} className="btn btn-primary btnhover m-r5 button-lg radius-no">CREATE AN ACCOUNT</Link>
                      </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="login-area">
                  <div className="tab-content nav">
                    <form onSubmit={handleSubmit} className={` col-12 ${forgotPass ? 'd-none' : ''}`}>
                      <h4 className="text-secondary">LOGIN</h4>
                      <p className="font-weight-600">If you have an account with us, please log in.</p>
                      <p className={errMsg ? "errmsg" : "offscreen"}>{errMsg}</p>
                      <div className="mb-4">
                        <label className="label-title">E-MAIL *</label>
                        <input className="form-control" placeholder="Your Email address" type="email"
                          id="email" autoComplete="off" onChange={(e) => setEmail(e.target.value)}
                          ref={emailRef} value={email} required />
                      </div>
                      <div className="mb-4">
                        <label className="label-title">PASSWORD *</label>
                        <input className="form-control " placeholder="Type Password" type="password"
                          id="password" onChange={(e) => setPwd(e.target.value)} value={pwd} required />
                      </div>
                      <div className="text-left" >
                        <button type="submit" className="btn btn-primary btnhover me-2" >Login</button>
                        <Link to={"#"} className="m-l5" onClick={() => setForgotPass(!forgotPass)}>
                          <i className ="fas fa-unlock-alt"></i> Forgot Password
                        </Link>
                      </div>
                    
                    </form>
                    <form onSubmit={emailReset} className={`  col-12 ${forgotPass ? '' : 'd-none'}`} >
                      <h4 className="text-secondary">FORGET PASSWORD ?</h4>
                      <p className="font-weight-600">We will send you an email to reset your password. </p>
                      <div className="mb-3">
                        <label className="label-title">E-MAIL *</label>
                        <input name="dzName" onChange={(e) => setEmail(e.target.value)}
                          ref={emailRef} value={email} required className="form-control" placeholder="Your Email Id" type="email" />
                      </div>
                      <div className="text-left">
                        <Link to={"#"} className="btn btn-outline-secondary btnhover m-r10 active"
                          onClick={() => setForgotPass(!forgotPass)}
                        >Back</Link>
                        <button type="submit" className="btn btn-primary btnhover">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default Login;