import AllPagesPDFViewer from "../../components/all-pages";
import BookBuyOption from "./BookBuyOption";

function BookPdfPreview({ book, file, id }) {
  return (
    <>
      <div className='divPdf mb-3'>
        <AllPagesPDFViewer pdf={file} />
      </div>
      <BookBuyOption book={book} id={id} type={1} />
    </>
  );
}

export default BookPdfPreview;