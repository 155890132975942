import React, { useState } from "react";
import { Link } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import Carousel from 'react-bootstrap/Carousel';

//Images
import bgwave from './../../assets/images/background/waveelement.png';
import partner1 from './../../assets/images/partner/partner-1.png';
import partner2 from './../../assets/images/partner/partner-2.png';
import partner3 from './../../assets/images/partner/partner-3.png';
import group from './../../assets/images/Group.png';
import media1 from './../../assets/images/banner/THRASS-1.jpg';
import media2 from './../../assets/images/banner/THRASS-in-SA.jpg';
import media3 from './../../assets/images/banner/THRASS-3.jpg';
import book16 from './../../assets/images/books/book16.png';
import book9 from './../../assets/images/books/grid/book9.jpg';
import SwiperCore, { EffectFade, Autoplay, FreeMode, Parallax, Thumbs, Pagination } from 'swiper';
SwiperCore.use([Parallax, Thumbs, FreeMode, Autoplay, Pagination, EffectFade]);


const homeData1 = [
  { image: media1, title: 'handwritting, reading and spelling skills', datatitle: 'THRASS is a phonics program for teaching', price: '9.50', mainprice: '12.0', offer: '25%' },
  { image: media2, title: 'reading, spelling, auditory, visual, sequential & spatial perceptual skills', datatitle: 'THRASS improves childrens', price: '10.40', mainprice: '15.25', offer: '33%' },

];

const homeData2 = [
  { image: book16, title: 'Pushing Clouds', price: '9.50', },
  { image: book9, title: 'Think and Grow Rich', price: '10.40' },
];

export default function HomeMainSlider() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const paginationRef = React.useRef(null)
  return (
    <>

      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={media1}
            alt="First slide"
          />
          <Carousel.Caption>
            <h2 className="h2WhiteColor">
              THRASS IS A PHONICS PROGRAMME FOR TEACHING HANDWRITING, READING & SPELLING SKILLS.</h2>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={media2}
            alt="Second slide"
          />

          <Carousel.Caption>
            <h2 className="h2WhiteColor">
              THRASS IMPROVES CHILDREN'S READING SPELLING, AUDITORY, VISUAL, SEQUENTIAL & SPATIAL PERCEPTUAL SKILLS.</h2>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={media3}
            alt="Third slide"
          />

          <Carousel.Caption>

            <h2 className="h2WhiteColor">
              AN EXTENSIVE RANGE OF RESOURCES ASSISTS IN THE SUCCESSFUL IMPLEMENTATION OF THRASS METHODOLOGY IN THE LEARNING ENVIRONMENT.
            </h2>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>




    </>
  )
}