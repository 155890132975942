import { BaseService } from "./BaseService";
import { browserName, browserVersion } from "react-device-detect";

export class AuthService extends BaseService {
  constructor() {
    super('Auth')
  }

  async confirmEmail(email, otp) {
    return this.httpService.post(`confirmotp/${email}/${otp}`, null);
  }

  async changePassword(userId, password) {
    var payload = { userId, password }
    return this.httpService.post(`changepassword`, payload);
  }

  async googleReg() {
    this.httpService.get('google-signup');
  }

  async login(email, password) {
    const payload = { email, password, browserName, browserVersion }
    return this.httpService.post('login', payload);
  }

  async logout(email) {
    return this.httpService.post(`logout/${email}`, null);
  }

  async register(email, firstName, lastName, phoneNumber, captchaToken, password, userType, schoolName, promoCode,params) {
    const payload = { email, firstName, lastName, phoneNumber, captchaToken, password, userType, schoolName,promoCode, uid: '' }
    return this.httpService.post(`register?${params}`, payload);
  }

  async resetPassword(email) {
    return this.httpService.post(`resetpassword/${email}`, null);
  }

  async updateProfile(userId, firstName, lastName, phoneNumber, schoolName) {
    const payload = {userId, firstName, lastName, phoneNumber, schoolName}
    return this.httpService.post('UpdateProfile', payload)
  }
}