import { BaseService } from "./BaseService";

export class UserSubscriptionService extends BaseService {
    constructor() {
        super('UserSubscriptions')
    }

    async getByUid(id) {
        return this.httpService.get(`GetByUid/${id}`);
    }

    async getByUserId(id) {
        return this.httpService.get(`UserSubscriptions/${id}`);
    }

    async getInvitation(uid) {
        return this.httpService.get(`GetMemberInvitation/${uid}`);
    }

    async getUsersByUid(uid) {
        return this.httpService.get(`GetSubscriptionUsersByUid/${uid}`);
    }

    async invite(uid, email) {
        const payload = { uid, email }
        return this.httpService.post(`InviteMember`, payload);
    }
    async redeemPromoCode(userId, PromoCode) {
        const payload = { userId, PromoCode }
        return this.httpService.post(`RedeemPromoCode`, payload);
    }
    async getPromoBooks(uid) {
        return this.httpService.get(`GetPromoBooks/${uid}`);
    }
}