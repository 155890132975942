import { Link } from "react-router-dom";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { BookService } from "../../services/BookService";
import { formatCurrency } from "../../utilities/formatCurrency";

const bookService = new BookService()

function CartTableRow({cartItem}) {
  const { removeFromCart, getItemTotal, getItemQuantity, increaseCartQuantity, decreaseCartQuantity } = useShoppingCart()

  return (
    <tr>
      <td className="product-item-img"><img src={bookService.getFrontImage(cartItem?.book)} alt={cartItem?.book?.title ?? cartItem?.book?.name} /></td>
      <td className="product-item-name">{cartItem?.book?.title ?? cartItem?.book?.name}</td>
      <td className="product-item-price">{formatCurrency(cartItem?.book?.price)}</td>
      <td className="product-item-quantity">
        <div className="quantity btn-quantity style-1 me-3">
          <button className="btn btn-plus" type="button"
            onClick={() => { increaseCartQuantity(cartItem?.id, cartItem?.type) }}>
            <i className ="ti-plus"></i>
          </button>
          <input type="text" className="quantity-input" value={getItemQuantity(cartItem?.id)} readOnly />
          <button className="btn btn-minus " type="button"
            onClick={() => { decreaseCartQuantity(cartItem?.id) }}>
            <i className ="ti-minus"></i>
          </button>
        </div>
      </td>
      <td className="product-item-totle">{formatCurrency(getItemTotal(cartItem?.id))}</td>
      <td className="product-item-close">
        <Link to={"#"} className="ti-close" onClick={() => removeFromCart(cartItem?.id)}></Link>
      </td>
    </tr>
  )
}

export default CartTableRow;