import React  from 'react';
import { useEffect, useState } from "react";
import HomeMainSlider from '../components/Home/HomeMainSlider';
import ClientsSlider from './../components/Home/ClientsSlider';
import RecomendedSlider from './../components/Home/RecomendedSlider';
import BookSaleSlider from './../components/Home/BookSaleSlider';
import FeaturedSlider from './../components/Home/FeaturedSlider';
import OfferSlider from './../components/Home/OfferSlider';
import TestimonialSlider from './../components/Home/TestimonialSlider';
import LatestNewsSlider from './../components/Home/LatestNewsSlider';
import Motivation from './../components/Motivation';
import antbaby from './../assets/images/ant-baby.jpg';
import readers from './../assets/images/Readers.jpg';


export default function Index1() {
const iconBlog = [
    {title:'Quick Delivery', iconClass:'flaticon-power'}, 
    {title:'Secure Payment', iconClass:'flaticon-shield '}, 
    {title:'Best Quality', iconClass:'flaticon-like'}, 
    {title:'Return Guarantee', iconClass:'flaticon-star'}, 
];



	return(
		<>
			<div className="page-content bg-white">		
				<div className="main-slider style-1"> 
					<HomeMainSlider />
				</div>
				{/* <div className="bg-white py-5">
					<div className="container">
						<ClientsSlider />
					</div>
				</div>	 */}
				
				<section className="content-inner-1 bg-grey reccomend">
					<div className="container">
						<div className="section-head text-center">
							<h2 className="title">What is THRASS?</h2>
						</div>
						<p>THRASS® is a phonics programme that has been specifically designed to teach children (and adults) 
							about the 44 speech sounds (phonemes) in spoken English and their related 120 keyspellings 
							(graphemes) in written English.</p>
						<p>THRASS moves away from the traditional “One Letter Makes One Sound” method of teaching phonics and 
							teaches children that letters can make different sounds depending on the ‘job’ they have to do in 
							different words. For example, the letter “a” makes a different sound in each of the following words: 
							ant; baby; banana; zebra; swan and ball.</p>
						<img className="m-b30" src={antbaby} alt="What is THRASS" />
						<p>Children are made aware of the association between sounds and letters from an early age, and are 
							taught to distinguish between consonant and vowel sounds and to articulate the sounds correctly.</p>
						<div className="section-head text-center">
							<h2 className="title">Phonics wrapped up in stories!</h2>
						</div>
						<p>The bright and colourful English Phonics Readers sets explore the different spelling choices 
							for each of the 44 sounds in English through delightful and enchanting stories for children.</p>
						<p>Through classroom teaching, children have been made aware of these different spelling options, 
							but have only experienced them in lists of words and sentences. Now they will be exposed to 
							as many words as possible through engaging stories.</p>
						<img className="m-b30" src={readers} alt="Phonics wrapped up in stories!" />
						<p className="m-b30">Each sound has its own special story to allow children to experience the 
							different spelling choices in a storybook context. Certain to enhance any phonics lesson, the Readers 
							are ideal for shared reading at home or at school. The sets comprise 44 books (one for each sound) – 
							with the consonant sounds and their spellings in blue and the vowel sounds and their spellings in red.</p>
						<RecomendedSlider />
					</div>
				</section>		

				{/* <section className="content-inner-2">
					<div className="container">
						<div className="row">
							{iconBlog.map((data, ind)=>(
                                <div className="col-lg-3 col-sm-6" key={ind}>
                                    <div className="icon-bx-wraper style-1 m-b30 text-center">
                                        <div className="icon-bx-sm m-b10">
                                            <i className ={`icon-cell R {data.iconClass}`} />
                                        </div>
                                        <div className="icon-content">
                                            <h5 className="dz-title m-b10">{data.title}</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
						</div>
					</div>
				</section> */}
				{/* <section className="content-inner-1">
					<div className="container">
						<BookSaleSlider />
					</div>
				</section> */}
				{/* <section className="content-inner-1 bg-grey reccomend">
					<div className="container">
						<div className="section-head text-center">
							<div className="circle style-1"></div>
							<h2 className="title">Featured Product</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris </p>
						</div>
					</div>
					<div className="container">		
						<FeaturedSlider />
					</div>
				</section>
				<section className="content-inner-2">
					<div className="container">
						<OfferSlider />
					</div>
				</section>	
				<section className="content-inner-2 testimonial-wrapper">
					<TestimonialSlider />	
				</section>	 */}
				{/* <section className="content-inner-2">
					<div className="container">
						<div className="section-head text-center">
							<h2 className="title">Latest News</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
						</div>
						<LatestNewsSlider />	
					</div>
				</section>	
				<section className="content-inner">
					<div className="container">
						<div className="row sp15">
							<CounterSection />
						</div>
					</div>
				</section>	 */}
				<Motivation />
			</div>
		</>
	)
} 
