import { BaseService } from "./BaseService";

export class BookService extends BaseService {
  constructor() {
    super('Books')
  }

  async getByCategory(id, take, skip) {
    const url = `GetByCategoryId/${id}?take=${take}&skip=${skip}`;
    return this.httpService.get(url);
  }

  async getById(id) {
    return this.httpService.get(`${id}`);
  }
  async getSubscriptionBooksById(id) {
    return this.httpService.get(`GetSubscriptionBooksById/${id}`);
  }
  

  async getBySlug(id, params) {
    return this.httpService.get(`GetBySlug/${id}?${params}`);
  }

  getFrontImage = (book) => {
    if (book == null) return ''   
    return `${this.httpService.baseUrl}${book?.frontImage}`;
  }

  async getPaginationInfo(keyword, take) {
    if (!keyword || !take) return;
    return this.httpService.get(`GetPaginationInfo/${keyword}/${take}`);
  }

  async getRandomBooks(count) {
    // return this.httpService.get(`GetRandomBooks/${count}`);
    return this.httpService.get(``);
  }

  async getRelatedBooks(slug, count) {
    return this.httpService.get(`GetRelatedBooks/${slug}/${count}`);
  }

  async getSubscriptionPlans(id) {
    return this.httpService.get(`GetSubscriptionPlans/${id}`);
  }

  getPreviewVideo = (book) => {
    return `${this.httpService.baseUrl}${book?.videoPreviewFile}`;
  }

  getVideo = (book) => {
    return `${this.httpService.baseUrl}${book?.videoFile}`;
  }

  search = async (keyword, take, skip) => {
    if (!keyword || !take || skip < 0) return;
    const url = `Search?search=${keyword}&take=${take}&skip=${skip}`
    return await this.get(url);
  }
}