import React from 'react';
import { Link } from 'react-router-dom';
import placeholder from '../../assets/images/profile-placeholder.png';
import { NavLink } from "react-router-dom";
import { UserContext } from '../../context/UserContext';

const menus = [
  { to: '/dashboard', icons: 'fa fa-home', name: 'Dashboard' },
  { to: '/profile', icons: 'far fa-user', name: 'My Profile' },
  { to: '/subscriptions', icons: 'fa fa-book', name: 'My Subscriptions' },
  { to: '/orders', icons: 'fa fa-truck', name: 'My Orders' },
  { to: '/addresses', icons: 'fa fa-address-card', name: 'My Addresses' },
  { to: '/reset-password', icons: 'far fa-user', name: 'Change Password' },
  { to: '/login', icons: 'fas fa-sign-out-alt', name: 'Log Out' },
];

function LeftMenu() {
  return (
    <>
      <div className="col-xl-3 col-lg-4 m-b30">
        <div className="sticky-top">
          <div className="shop-account">
            <nav className="user-menu">
              {menus.map((menu, index) => (
                <NavLink key={index} to={menu.to}>
                  <i className ={menu.icons}></i>
                  <span>{menu.name}</span>
                </NavLink>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}
export default LeftMenu;