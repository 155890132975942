import React, { Component } from "react";
// import { Doughnut } from "react-chartjs-2";
// import {Chart, ArcElement} from 'chart.js'

// Chart.register(ArcElement);

class DonutChart2 extends Component {
 render() {
  

    
    return (
		<div className="donught-chart" >
			{/* <Doughnut data={data} options={options} height={165} width={165} /> */}
		</div>
    )
  }
}

export default DonutChart2;
