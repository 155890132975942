import BookSubscriptionPlans from './BookSubscriptionPlans';
import { useEffect, useState } from 'react';
import { Player } from 'video-react';
import { BookService } from '../../services/BookService';

const bookService = new BookService();

function BookVideoPreview({ subscriptionPlans, book, id }) {
  const [imageUrl, setImageUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    setImageUrl(bookService.getFrontImage(book));
    setVideoUrl(bookService.getPreviewVideo(book));
  }, [book])

  return (
    <>
      <div className='row mb-3'>
        <div className='col-md-12'>
          <Player playsInline poster={imageUrl} src={videoUrl} />
        </div>
      </div>
      <BookSubscriptionPlans subscriptionPlans={subscriptionPlans} book={book} id={id} />
    </>
  );
}

export default BookVideoPreview;