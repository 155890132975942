import React from 'react';
//Components 
import PageTitle from './../layouts/PageTitle';
import NewsLetter from '../components/NewsLetter';

const missionBlog = [
    {iconClass:'flaticon-open-book-1', title:'Best Bookstore' },
    {iconClass:'flaticon-exclusive', title:'Trusted Seller' },
    {iconClass:'flaticon-store', title:'Expand Store' },
];

function TeachingThrass(){
    return(
        <>
            <div className="page-content bg-white">
               <PageTitle  parentPage="Home" childPage="Teaching THRASS" />
               <section className="content-inner overlay-white-middle">
                    <div className="container">
                        <div className="row about-style1 align-items-center">
                            <div className="col-lg-12 m-b30 aos-item">
                                <div className="about-content px-lg-4">
                                    <div className="section-head style-1">
                                        <h4 className="title">
                                            THRASS has been designed to provide teachers with a multisensory, phonographic methodology to 
                                            deliver positive learning outcomes in the literacy skills that are critical in mastering the 
                                            other cornerstones of basic education.
                                        </h4>
                                        <h6 className="title">
                                            THRASS builds both content and pedagogical knowledge – and is the only comprehensive phonetics 
                                            teaching process designed specifically for the teaching of speaking, reading and writing skills 
                                            of English, as a first or other language.
                                        </h6>
                                    </div>
                                    <p>
                                        THRASS is an explicit phonographic approach to teaching reading and spelling skills to learners of 
                                        any age. It is dependent on the competence, commitment and understanding of the teacher using the 
                                        pedagogy – and because of its specific learning technique should not be combined with other more 
                                        widely used and often outdated, letter-to-sound programs.
                                    </p>
                                    <p>
                                        THRASS does not depend on specific intervention with individual learners but rather focuses on 
                                        instructing teachers in the technique for whole-class implementation. Rather than being an additional 
                                        task for an already too-busy teacher to work into a cluttered curriculum, THRASS is designed to form a 
                                        cornerstone around which the teacher can build their classroom reading and writing activities spanning 
                                        all areas of the curriculum.
                                    </p>
                                    <p>
                                        THRASS does not subscribe to a ‘workbook’ model that provides ready-made written lesson plans or 
                                        workbooks for teachers, but requires genuine commitment and understanding by the teacher.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <NewsLetter />             
            </div>
        </>
    )
}
export default TeachingThrass;