import React, { useEffect,useState } from 'react';

import { Link } from 'react-router-dom';
import footerImage from './../assets/images/SSC_sm.png';

function Footer({ footerChange, logoImage }) {
  const [country, setCounty] = useState('');

  useEffect(() => {
		const getLocation = async () => {
		try {
			
			const response = await fetch('https://ipapi.co/json/');
			const data = await response.json();
			setCounty("Browsing  Location: " + data.country_name);
		} catch (error) {
			console.error('Error retrieving location:', error);
		}
		};

		getLocation();
	}, []);

  //const [open, setOpen] = useState(false);
  return (
    <>
      <footer className={`site-footer ${footerChange}`}>
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <div className="widget widget_about">
                  <div className="footer-logo logo-white">
                    <Link to={""}><img src={logoImage} alt="" /></Link>
                  </div>
                  <p className="text">The THRASS phonics programme teaches speech sounds (phonemes) in spoken English and the related keyspellings (graphemes) in written English as a home or first additional language.</p>
                  <div className="dz-social-icon style-1">
                    <ul>
                      <li><a href="https://www.facebook.com/AfricaThrass" target="_blank" rel="noreferrer"><i className ="fa-brands fa-facebook-f"></i></a></li>
                      <li><a href="https://mobile.twitter.com/africathrass" target="_blank" rel="noreferrer"><i className ="fa-brands fa-twitter"></i></a></li>
                      <li><a href="https://linkedin.com/company/thrass-africa" target="_blank" rel="noreferrer"><i className ="fa-brands fa-linkedin"></i></a></li>
                    </ul>
                  </div>
                  <p  className="text mt-3" >{country}</p>
                </div>
              </div>

              <div className='col-xl-7'>
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="widget widget_services">
                      <h5 className="footer-title">About</h5>
                      <ul>
                        <li><Link to={"what-is-thrass"}>What is THRASS?</Link></li>
                        <li><Link to={"using-thrass"}>Using THRASS</Link></li>
                        <li><Link to={"teaching-thrass"}>Teaching THRASS</Link></li>
                        <li><Link to={"thrass-resources"}>THRASS Resources</Link></li>
                        <li><Link to={"thrass-in-south-africa"}>THRASS in South Africa</Link></li>
                        <li><Link to={"how-to-order"}>How to Order</Link></li>
                        <li><Link to={"how-thrass-works/thrass-gallery"}>THRASS Gallery</Link></li>
                        <li><Link to={"contact-us"}>Contact</Link></li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 wow fadeInUp" data-wow-delay="0.4s">
                    <div className="widget widget_services">
                      <h5 className="footer-title">Getting to Know THRASS</h5>
                      <ul>
                        <li><Link to={"focus-areas"}>Focus Areas</Link></li>
                        <li><Link to={"definitions"}>Definitions</Link></li>
                        <li><Link to={"the-key-stages"}>The Key Stages</Link></li>
                        <li><Link to={"free-guide"}>Free Guides</Link></li>
                        <li><Link to={"free-software"}>Free Software</Link></li>

                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 wow fadeInUp" data-wow-delay="0.4s">
                    <div className="widget widget_services">
                      <h5 className="footer-title">Inside THRASS</h5>
                      <ul>
                        <li><Link to={"the-english-phonics-chart"}>The English Phonics Chart</Link></li>
                        <li><Link to={"the-grapheme-chart"}>The Grapheme Chart</Link></li>
                        <li><Link to={"teacher-s-guides"}>Teacher's Guides</Link></li>
                        <li><Link to={"learner-s-phonics-workbooks"}>Learner's Phonics Workbooks</Link></li>
                        <li><Link to={"my-word-power-phonics-books"}>My Word Power Phonics Books</Link></li>
                        <li><Link to={"english-phonics-readers-2"}>English Phonics Readers</Link></li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 wow fadeInUp" data-wow-delay="0.2s">
                    <div className="widget widget_services">
                      <h5 className="footer-title">Shop</h5>
                      <ul>
                        <li><Link to={"new-products"}>New Products</Link></li>
                        <li><Link to={"on-promotion"}>On Promotion</Link></li>
                        <li><Link to={"home-language"}>Home Language</Link></li>
                        <li><Link to={"first-additional-language"}>First Additional Language</Link></li>
                        <li><Link to={"english-phonics-readers"}>English Phonics Readers</Link></li>
                        <li><Link to={"charts"}>Charts</Link></li>
                        <li><Link to={"tiles-cards"}>Tiles & Cards</Link></li>
                        <li><Link to={"sing-a-long"}>Sing-a-Long</Link></li>
                        <li><Link to={"interactive"}>Interactive</Link></li>
                        <li><Link to={"additional-books"}>Additional Books</Link></li>

                      </ul>
                    </div>
                  </div>

                </div>
              </div>

              <div className="col-xl-2 col-lg-3 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                <div className="widget widget_getintuch">
                  <h5 className="footer-title">Get in Touch With Us</h5>
                  <ul>
                    <li>
                      <i className ="flaticon-placeholder"></i>
                      <span>6 Pencil Park, Croxley Close, Heriotdale X15 2094 Johannesburg, South Africa</span>
                    </li>
                    <li>
                      <i className ="flaticon-phone"></i>
                      <span>+27 (0)81 870 8369
                        </span>
                    </li>
                    <li>
                      <i className ="flaticon-email"></i>
                      <span>info@thrass-sa.co.za</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container text-center">
          <img src={footerImage} alt="THRASS Footer Image" />
            <p><Link to={"/terms-conditions-2"}>Terms & Conditions</Link> | <Link to={"/privacy-policy-2"}>Privacy Policy</Link></p>
            <p>© 2017 THRASSAfrica, under licence to Next Level Learning (Pty) Ltd. All rights reserved.</p>
           
          </div>
        </div>
      </footer>
    </>
  )
}
export default Footer;