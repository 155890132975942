import { Link } from "react-router-dom";
import { BookService } from "../../services/BookService";
import BookDetailButton from "./BookDetailsButton";

const bookService = new BookService();

function SliderBook({book}) {
  return (
    <div className="books-card style-1 wow fadeInUp" data-wow-delay="0.1s">     
      <div className="dz-media">
        <Link to={`/book/${book.slug}`}>
          <img src={bookService.getFrontImage(book)} alt={book.title} className="related-books" />
        </Link>
      </div>
      <div className="dz-content">
        <Link to={`/book/${book.slug}`}>
          <h4 className="title">{book.title}</h4>
        </Link>
        <span className="price">R {book.price}</span>
        <BookDetailButton book={book} />
      </div>
    </div>
  )
}

export default SliderBook;