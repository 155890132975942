import { useEffect, useState, useCallback } from 'react';
import { UserSubscriptionService } from '../../../services/UserSubscriptionService';
import LeftMenu from '../LeftMenu';
import SubscriptionDetails from './SubscriptionDetails';
import SubscriptionUsers from './SubscriptionUsers';
import AddUserModal from './AddUserModal';
import { useParams } from 'react-router-dom';
import PlayVideoModal from './PlayVideoModal';
import { SetService } from '../../../services/SetService';
import SetBooksTable from './SetBooksTable';
import SetBookTable from './SetBookTable';
import { DirectInviteService } from "../../../services/DirectInviteService";
import { BookService } from '../../../services/BookService';
const setService = new SetService();
const userSubscriptionService = new UserSubscriptionService();
const directInvitationService = new DirectInviteService();
const bookService = new BookService();
function ViewSubscription(props) {
  const [userSubscription, setUserSubscription] = useState(null);
  const [videoBook, setVideoBook] = useState(null);
  const [subscriptionUsers, setSubscriptionUsers] = useState([]);
  const [setBooks, setSetBooks] = useState([]);
  const [inviteBooks, setInviteBooks] = useState([]);
  const [promoBooks, setPromoBooks] = useState([]);
  const [successMsg, setSuccessMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [open, setOpen] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [canAddUsers, setCanAddUsers] = useState(false);
  const [email, setEmail] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenVideo = () => setOpenVideo(true);
  const handleCloseVideo = () => setOpenVideo(false);
  const [expiredSubscription, setExpiredSubscription] = useState([]);
  const {id} = useParams();

  const getInviteBooks = useCallback(async (slug) => {
    const response = await directInvitationService.getBooks(slug)

    if (response.data) {
      setInviteBooks(response.data);
    } else {
      setInviteBooks([]);
    }
  }, [])


  const getPromoBooks = useCallback(async (slug) => {
    const response = await userSubscriptionService.getPromoBooks(slug)

    if (response.data) {
      setPromoBooks(response.data);
    } else {
      setPromoBooks([]);
    }
  }, [])


  const getSetBooks = useCallback(async (slug) => {
    const response = await setService.getSetBooks(slug)   
    if (response.data) {
      setSetBooks(response.data);
    } else {
      setSetBooks([]);
    }
  }, [])

  const getBook = useCallback(async (id) => {
    const response =await bookService.getSubscriptionBooksById(id);   
    debugger
    if (response.data) {
      setSetBooks(response.data);
    } else {
      setSetBooks([]);
    }
  }, [])


  const getSubscriptionUsers = useCallback(async () => {
    const response = await userSubscriptionService.getUsersByUid(id);

    setSubscriptionUsers(response.data);  
  }, [id])

  const getUserSubscription = useCallback(async () => {
    var response = await userSubscriptionService.getByUid(id);  
    if(response.data) {
      setExpiredSubscription(0);
    setUserSubscription(response.data);
    setCanAddUsers(response.data.type !== 3)
    if (response.data.type === 4) { //Direct Invitation
      getInviteBooks(id)
    }
    else if (response.data.type === 5) //Promo Invitation
    {
      getPromoBooks(id);
    }
    else {
      if (response.data.subscriptionPlan?.maximumUsers > 1) {
        const response = await userSubscriptionService.getUsersByUid(id);
  
        setSubscriptionUsers(response.data);  
      }
      
      if (response.data.subscriptionPlan?.set) getSubscriptionUsers()
     
     debugger
      if (response.data.subscriptionPlan.set) {
        getSetBooks(response.data.subscriptionPlan.set.slug)
      }
      else if (response.data.subscriptionPlan.book){         
        getBook(response.data.subscriptionPlan.book.id )

      }
    }
    }
    else{
     

      setExpiredSubscription(1);
    }
  }, [getSetBooks, getSubscriptionUsers, getInviteBooks,getPromoBooks,getBook, id])

  window.oncontextmenu = (e) => {
    e.preventDefault()   
  }

  useEffect(() => {
    getUserSubscription();
  }, [getUserSubscription])

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrMsg('');
    setSuccessMsg('');

    try {
      const response = await userSubscriptionService.invite(id, email)

      setSuccessMsg('The user has been invited via email. You can invite another');
      setEmail('')
    } catch (error) {
      setErrMsg(error?.response?.data);
    }
  }

  return (
    <>
      <div className="page-content bg-white">
        <div className="content-block">
          <section className="content-inner bg-white">
            <div className="container">
              <div className="row">
                <LeftMenu />
                {userSubscription && (
                  <div className="col-xl-9 col-lg-8 m-b30">
                    <div className="shop-bx shop-profile">
                      <div className="shop-bx-title clearfix">
                        <h5 className="text-uppercase">
                          {userSubscription.subscriptionPlan?.name}
                          {userSubscription.balance > 0 && (
                            <a href={userSubscription.authorizationUrl} rel="noopener noreferrer" className='float-end btn btn-primary btnhover btn-sm'>
                              Pay R {userSubscription.balance} 
                            </a>
                          )}
                      
                         
                        </h5>
                      </div>
                      <div className='row'>
                        <SubscriptionDetails 
                          addedUsers={subscriptionUsers}
                          subscription={userSubscription} 
                          baseUrl={userSubscriptionService.httpService.baseUrl}/>
                      </div>
                    </div>
                    {(userSubscription.subscriptionPlan?.maximumUsers > 1) && (
                      <div className="shop-bx shop-profile">
                        <div className="shop-bx-title clearfix">
                          <h5 className="text-uppercase">
                            Subscription Users
                            {(subscriptionUsers.length < userSubscription.subscriptionPlan?.maximumUsers && canAddUsers) && (
                              <AddUserModal open={open} handleOpen={handleOpen} handleClose={handleClose} handleSubmit={handleSubmit}
                                setEmail={setEmail} email={email} errMsg={errMsg} successMsg={successMsg} />
                            )}
                          </h5>
                        </div>

                        <SubscriptionUsers 
                          uid={id} 
                          subscriptionUsers={subscriptionUsers}
                          canAddUsers={canAddUsers} />
                      </div>
                    )}
                 
                   
                    {(setBooks.length > 0) && ( 
                      <SetBooksTable 
                        books={setBooks} 
                        setOpenVideo={setOpenVideo} 
                        setVideoBook={setVideoBook} />
                    )}
                    {(inviteBooks.length > 0) && ( 
                      <SetBooksTable 
                        books={inviteBooks} 
                        setOpenVideo={setOpenVideo} 
                        setVideoBook={setVideoBook} />
                    )}
                    {(promoBooks.length > 0) && ( 
                      <SetBooksTable 
                        books={promoBooks} 
                        setOpenVideo={setOpenVideo} 
                        setVideoBook={setVideoBook} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
export default ViewSubscription;