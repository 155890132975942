import { useRef } from "react";
import { Link, useParams } from 'react-router-dom';
import PageTitle from './../layouts/PageTitle';
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from "sweetalert2";
import Constants from "../Constants/Contants";
import { DirectInviteService } from "../services/DirectInviteService";

const directInvitationService = new DirectInviteService();

function AccepDirecttInvitation(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const captchaRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await directInvitationService.accept(id)
      Swal.fire({
        title: 'Success',
        text: 'You have successfully accepted the invitation. Please log in to access the resources.',
        icon: 'success',
      });
      navigate(`/login`)
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: '' + error?.response?.data,
        icon: 'error',
      });
    }
  }

  return (
    <div className="page-content">
      <PageTitle parentPage="Shop" childPage="Accept Invitation" />
      <section className="content-inner shop-account">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 mb-4">
              <div className="login-area">
                <form onSubmit={handleSubmit}>
                  <h4 className="text-secondary">Accept Direct Invitation</h4>
                  <p className="font-weight-600">
                    Please click the button below to accept the invitation.
                  </p>
                  <div className="mb-5">
                    <small>Your personal data will be used to support your experience throughout this website,
                      to manage access to your account, and for other purposes described in our
                      <Link to={"privacy-policy"}>privacy policy</Link>.
                    </small>
                    <ReCAPTCHA
                      sitekey={Constants.ReCAPTCHA_SiteKey}
                      ref={captchaRef}
                    />
                  </div>

                  <div className="text-left">
                    <button type="submit" className="btn btn-primary btnhover w-100 me-2">
                      Accept Invitation
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default AccepDirecttInvitation;